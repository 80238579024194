
import FormMixins from './FormMixins'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'

interface InnerRule {
  required?: boolean;
  validator?: Function;
  message?: string;
}

@Component
export default class FormDate extends Mixins(FormMixins) {
  @Ref()
  formItemElement!: { clearValidate: Function }

  @Prop()
  ms_number!: number

  @Watch('value')
  onValue (val: string) {
    this.setInnerDate(val)
  }

  @Watch('innerDate')
  onInnerDate (val: any) {
    if (val && this.millisecond) {
      this.$emit('input', `${val}.${this.millisecond}`)
    } else if (!val && !this.millisecond) {
      this.$emit('input', '')
    }
    this.formItemElement.clearValidate()
  }

  @Watch('millisecond')
  onMillisecond (val: any) {
    if (this.innerDate && val) {
      this.$emit('input', `${this.innerDate}.${val}`)
    } else if (!this.innerDate && !val) {
      this.$emit('input', '')
    }
    this.formItemElement.clearValidate()
  }

  private valueFormat = 'yyyy-MM-dd HH:mm:ss'
  private millisecond = ''
  private innerDate = ''
  private innerRules: InnerRule[] = [{ validator: this.validateMillisecond }, { validator: this.validateFormat }]

  private setInnerDate (val: string) {
    const values = val.split('.')
    this.innerDate = values[0] || ''
    this.millisecond = values[1] || ''
  }

  private validateRequired (rule: any, value: number, callback: Function) {
    if (!this.innerDate) {
      callback(new Error(`请输入${this.field.label}`))
    } else if (!this.millisecond) {
      callback(new Error('请输入毫秒'))
    } else {
      callback()
    }
  }

  private validateMillisecond (rule: any, value: number, callback: Function) {
    if (this.millisecond && (this.millisecond.toString().length !== this.ms_number || !Number(this.millisecond))) {
      callback(new Error('毫秒格式不正确'))
    } else {
      callback()
    }
  }

  private validateFormat (rule: any, value: number, callback: Function) {
    if (!this.innerDate && !this.millisecond) {
      callback()
    } else {
      if (!this.innerDate) {
        callback(new Error(`请输入${this.field.label}`))
      } else if (!this.millisecond) {
        callback(new Error('请输入毫秒'))
      } else {
        callback()
      }
    }
  }

  created () {
    this.setInnerDate(this.value)
    const isRequired = (this.field.rule || []).find((res) => res.required)
    if (isRequired) {
      this.innerRules = this.innerRules.concat([{ required: true, message: `请输入${this.field.label}` }]).concat([{ validator: this.validateRequired }])
    }
    this.field.rule = this.innerRules
  }
}
