
import MallProductService from '@/biz/service/Mall/MallProductService'
import RouterService from '@/service/RouterService'
import { Component, Ref, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import cartesian from 'cartesian'
import ValidateService from '@/service/ValidateService'
import DialogService from '@/service/DialogService/DialogService'
import MallClassifySpecOptionService from '@/biz/service/Mall/MallClassifySpecOptionService'
import MallClassifySpecService from '@/biz/service/Mall/MallClassifySpecService'

interface SpecOption {
  id: number;
  display_name: string;
}

interface SkuItem {
  id: number;
  display_name: string;
  selectedIds: number[];
  mall_classify_spec_option: SpecOption[];
}

@Component
export default class MallProductSpecForm extends Vue {
  @Ref()
  formElements!: any[]

  @Ref()
  specElement!: any

  private specs = [] as SkuItem[]
  private MallProductService = MallProductService

  get innserSku () {
    return this.form.skus.filter((res) => {
      if (this.form.sku_mode === MallProductService.getOptionsValue('sku_mode', 1, '普通模式')) {
        return !res.spec_paths
      } else {
        return !!res.spec_paths
      }
    })
  }

  private form = {
    mall_product_id: RouterService.query('mall_product_id'),
    mall_classify_id: 0,
    enterprise_id: 0,
    skus: [] as any[],
    sku_mode: 1
  }

  private formFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '规格'
    },
    original_price: {
      prop: 'original_price',
      label: '原价',
      rule: [ValidateService.price]
    },
    current_price: {
      prop: 'current_price',
      label: '售价',
      rule: [ValidateService.required, ValidateService.price]
    },
    factory_price: {
      prop: 'factory_price',
      label: '进价',
      rule: [ValidateService.required, ValidateService.price]
    },
    profit_price: {
      prop: 'profit_price',
      label: '利润',
      rule: [ValidateService.required, ValidateService.price]
    },
    quantity: {
      prop: 'quantity',
      label: '库存',
      rule: [ValidateService.requiredNumber, ValidateService.minNum(0)],
      min: 0
    },
    rebate_ratio: {
      prop: 'rebate_ratio',
      label: '返点',
      placeholder: '可输入百分比：10%(返还利润的10%) 或 整数：100(固定返还100赛币)',
      rule: [ValidateService.ratio]
    },
    sku_mode: {
      prop: 'sku_mode',
      label: '模式',
      rule: [ValidateService.requiredNumber]
    }
  })

  private handleLoad () {
    return MallProductService.show(this.form.mall_product_id)
      .then((res) => {
        this.form.mall_classify_id = res.data.mall_classify_id
        this.form.enterprise_id = res.data.enterprise_id
        this.form.sku_mode = res.data.sku_mode
      })
  }

  private handleGetSku () {
    return MallProductService.getSku({ mall_product_id: this.form.mall_product_id })
      .then((res) => {
        this.form.skus = res.data || []
      })
  }

  private handleGetSpec () {
    return MallProductService.getSpec({ mall_classify_id: this.form.mall_classify_id, enterprise_id: this.form.enterprise_id, mall_product_id: this.form.mall_product_id })
      .then((res) => {
        this.specs = res.data.map((item: any) => {
          item.selectedIds = []
          return item
        })
      })
  }

  private handleCreate () {
    const specCollect: SpecOption[][] = cartesian(this.specs
      .map((res) => {
        return res.mall_classify_spec_option.filter((item) => res.selectedIds.includes(item.id))
      })
      .filter((res) => res.length > 0))
    if (specCollect.length === 0) {
      this.$message.warning('请选择规格')
      return false
    }
    specCollect.map((specs) => { // specs为mall_classify_spec_option
      const obj = {
        display_name: specs.map((spec) => spec.display_name).join('+'),
        spec_paths: `|${specs.map((spec) => spec.id).sort((a, b) => a - b).join('|')}|`,
        mall_classify_id: this.form.mall_classify_id,
        mall_product_id: this.form.mall_product_id,
        original_price: '',
        current_price: '',
        factory_price: '',
        profit_price: '',
        quantity: '',
        rebate_ratio: ''
      }
      if (process.env.NODE_ENV === 'development') {
        Object.assign(obj, {
          original_price: 100,
          current_price: 80,
          factory_price: 40,
          profit_price: 40,
          quantity: 10,
          rebate_ratio: '10%'
        })
      }
      if (!this.form.skus.some((res) => res.spec_paths === obj.spec_paths)) {
        this.form.skus.push(obj)
      }
    })
    this.specs.forEach((res) => {
      res.selectedIds = []
    })
  }

  private handleAddSku () {
    this.form.skus.push({
      display_name: '',
      spec_paths: '',
      mall_classify_id: this.form.mall_classify_id,
      mall_product_id: this.form.mall_product_id,
      original_price: '',
      current_price: '',
      factory_price: '',
      profit_price: '',
      quantity: '',
      rebate_ratio: ''
    })
  }

  private handleSubmit () {
    return (this.formElements || []).reduce((acc, form) => {
      return acc.then(() => form.validate())
    }, Promise.resolve())
      .then(() => MallProductService.storeSku(this.form))
      .then((res: any) => {
        RouterService.go()
        return res
      })
  }

  private handleInputCurrentPrice (value: string, form: any) {
    if (value && form.factory_price) {
      form.profit_price = (((parseFloat(value) * 10) - (parseFloat(form.factory_price) * 10)) / 10).toString()
    }
  }

  private handleInputFactoryPrice (value: string, form: any) {
    if (value && form.current_price) {
      form.profit_price = (((parseFloat(form.current_price) * 10) - (parseFloat(value) * 10)) / 10).toString()
    }
  }

  private handlePrev () {
    this.$emit('prev')
  }

  private handleAddSpec () {
    return DialogService.show(require('../../MallClassifySpec/Form.vue').default, {
      top: '20vh',
      width: '400px',
      mall_classify_id: this.form.mall_classify_id,
      enterprise_id: this.form.enterprise_id,
      mall_product_id: this.form.mall_product_id
    })
      .then(() => {
        this.specElement.reload()
      })
  }

  private handleAddSpecOption (v: { id: number }) {
    return DialogService.show(require('../../MallClassifySpecOption/Form.vue').default, {
      top: '20vh',
      width: '400px',
      mall_classify_spec_id: v.id
    })
      .then(() => {
        this.specElement.reload()
      })
  }

  private handleDeleteSku (form: { id: number }, index: number) {
    return this.$confirm('您真的要删除吗？')
      .then(() => {
        if (form.id) {
          return MallProductService.destroySku(form.id)
        }
      })
      .then(() => {
        this.form.skus.splice(index, 1)
        return Promise.resolve({ message: '删除成功' })
      })
      .catch(() => {
        //
      })
  }

  private handleDeleteSpecOption (id: number, key: number, v: any) {
    return this.$confirm('您真的要删除吗？')
      .then(() => MallClassifySpecOptionService.destroy(id))
      .then((res) => {
        v.mall_classify_spec_option.splice(key, 1)
        this.$message({
          showClose: true,
          message: res.message,
          duration: 5000,
          type: 'success'
        })
      })
      .catch((err) => {
        if (err && err.message) {
          this.$message({
            showClose: true,
            message: err.message,
            duration: 5000,
            type: 'error'
          })
        }
      })
  }

  private handleDeleteSpec (id: number, index: number) {
    return this.$confirm('您真的要删除吗？')
      .then(() => MallClassifySpecService.destroy(id))
      .then((res) => {
        this.specs.splice(index, 1)
        return res
      })
  }
}
