
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IPagination } from '@/interface/common'

@Component
export default class TablePagination extends Vue {
  @Prop()
  pagination!: IPagination

  private pageSizes = [] as number[]

  private changePage (page: number) {
    this.pagination.page = page
    this.$emit('changePage')
  }

  private changePageSize (pageSize: number) {
    this.pagination.limit = pageSize
    this.changePage(1)
  }

  created () {
    this.pageSizes = Array(5).fill(0).map((res, key) => {
      return (key + 1) * this.pagination.limit
    })
  }
}
