import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class EnterpriseEmployeeService extends BaseAbstract {
  displayName = '员工'
  name = 'EnterpriseEmployee'

  index () {
    return IOService.get('enterprise_employee', {
      enterprise_id: RouterService.query('enterprise_id')
    })
  }

  store (params: object) {
    return IOService.post('enterprise_employee', {
      ...params,
      enterprise_id: RouterService.query('enterprise_id')
    })
  }

  show (id: number) {
    return IOService.get(`enterprise_employee/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`enterprise_employee/${params.id}`, params)
  }

  destroy (id: number) {
    return IOService.delete(`enterprise_employee/${id}`)
  }
}

export default new EnterpriseEmployeeService()
