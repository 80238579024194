
import { Component, Vue } from 'vue-property-decorator'
import ParamConfigService from '@/biz/service/System/ParamConfigService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewSystemParamConfigForm extends Vue {
  private Service = ParamConfigService
  private form = {
    id: RouterService.query('id'),
    name: '',
    display_name: '',
    value: '',
    guard_name: 'config'
  }

  private formFields: IFormFields = {
    name: ValidateService.genRule({
      prop: 'name',
      label: '标识',
      disabled: !!this.form.id,
      rule: [ValidateService.required, ValidateService.max(60)]
    }),
    display_name: ValidateService.genRule({
      prop: 'display_name',
      label: '名称',
      rule: [ValidateService.required, ValidateService.max(60)]
    }),
    value: ValidateService.genRule({
      prop: 'value',
      label: '值',
      rule: [ValidateService.required, ValidateService.max(120)]
    })
  }
}
