
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ComponentCommentIndex extends Vue {
  @Prop()
  params!: { id: number; CommentService: any }

  handleLoad (params: any) {
    return this.params.CommentService.index({
      id: this.params.id,
      ...params
    })
  }
}
