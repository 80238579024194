
import { Component, Vue } from 'vue-property-decorator'
import OptionConfigService from '@/biz/service/System/OptionConfigService'
import { ISearchFields } from '@/interface/common'
import RouterService from '@/service/RouterService'

@Component
export default class ViewSystemOptionConfigIndex extends Vue {
  private Service = OptionConfigService
  private RouterService = RouterService

  private searchFields: ISearchFields[] = [
    {
      name: 'name',
      display_name: '标识',
      valueType: 'string'
    },
    {
      name: 'display_name',
      display_name: '名称',
      valueType: 'string'
    }
  ]
}
