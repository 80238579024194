
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import CheckForm from './Check/Form.vue'
import CheckedForm from './Checked/Form.vue'
import VideoForm from './Video/Form.vue'

@Component({
  components: {
    CheckForm,
    CheckedForm,
    VideoForm
  }
})
export default class RacingDynamicForm extends Vue {
  private active = RouterService.getSearchQuery('active') || 'Check'
  private current = this.active + 'Form'

  private handleTabClick (res: any) {
    RouterService.replace('/racing/racing-dynamic', { _search: RouterService.getSearchQueryString({ active: res.name }) })
  }
}
