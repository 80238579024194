import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class Service extends BaseAbstract {
  displayName = '店铺'
  name = 'MallStore'
  cacheDatas = [] as any[]

  index (params: { page: number }) {
    return IOService.get('mall_store', params)
  }

  indexAll () {
    return IOService.get('mall_store/indexAll')
      .then((res) => {
        this.cacheDatas = res.data
        return res
      })
  }

  store (params: object) {
    return IOService.post('mall_store', params)
  }

  show (id: number) {
    return IOService.get(`mall_store/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`mall_store/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`mall_store/${id}`)
  }

  getList () {
    return Promise.resolve()
      .then(() => {
        if (this.cacheDatas.length === 0) {
          return this.indexAll()
        }
      })
      .then(() => this.cacheDatas)
  }
}

export default new Service()
