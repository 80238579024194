
import { Component, Vue } from 'vue-property-decorator'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'
import GeneralPrize from './components/GeneralPrize.vue'
import RallyPrize from './components/RallyPrize.vue'
import T3Prize from './components/T3Prize.vue'

@Component({
  components: {
    GeneralPrize,
    RallyPrize,
    T3Prize
  }
})
export default class ViewRacingPrintPrize extends Vue {
  private current = ''

  created () {
    if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'Rally') {
      this.current = 'RallyPrize'
    } else if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'T3') {
      this.current = 'T3Prize'
    } else {
      this.current = 'GeneralPrize'
    }
  }
}
