
import DialogService from '@/service/DialogService/DialogService'
import { Component, Vue } from 'vue-property-decorator'
import RacingNoticeService from '@/biz/service/Racing/RacingNoticeService'
import RacingNoticeCommentService from '@/biz/service/Comment/RacingNoticeCommentService'

@Component
export default class RacingNoticeIndex extends Vue {
  private Service = RacingNoticeService

  private handleComment (id: number) {
    return DialogService.show(require('@/biz/components/components/Comments.vue').default, { id, CommentService: RacingNoticeCommentService })
  }
}
