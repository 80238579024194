
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'
import MallClassifySpecService from '@/biz/service/Mall/MallClassifySpecService'
import EnterpriseService from '@/biz/service/Enterprise/EnterpriseService'

@Component
export default class MallClassifySpecForm extends Vue {
  @Prop()
  params!: { mall_classify_id: number; enterprise_id: number; id: number; mall_product_id: number }

  private MallClassifySpecService = MallClassifySpecService
  private form = {
    id: this.params ? this.params.id : RouterService.query('id'),
    display_name: '',
    enterprise_id: this.params ? this.params.enterprise_id : '',
    mall_product_id: this.params ? this.params.mall_product_id : '',
    mall_classify_id: this.params ? this.params.mall_classify_id : RouterService.query('mall_classify_id')
  }

  private formFields: IFormFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '规格名',
      rule: [ValidateService.required, ValidateService.max(60)]
    },
    enterprise_id: {
      prop: 'enterprise_id',
      label: '所属企业',
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      },
      tips: '为空则应用于所有企业'
    }
  })

  private handleBeforeLoad () {
    return Promise.resolve()
      .then(() => {
        if (this._isAdminPlatform) {
          return EnterpriseService.getList()
            .then((data) => {
              this.formFields.enterprise_id.options = data
            })
        }
      })
  }
}
