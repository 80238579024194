
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RacingRoundManyForm extends Vue {
  @Prop()
  form!: any

  @Prop()
  formFields!: any

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form.display_name = '回合'
      this.form.volley_number = 4
      this.form.promoted_number = 10
    }
  }
}
