import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingGroupService extends BaseAbstract {
  displayName = '组别'
  name = 'RacingGroup'
  cacheDatas = {} as { [key: number]: any[] }
  currentData = {
    racing_id: 0,
    display_name: '',
    amount: '',
    ms_number: 2,
    max_time: 5,
    is_open: 1,
    is_wechat_pay: 1,
    is_coin_pay: 1
  }

  index () {
    const racing_id = Number(RouterService.query('racing_id'))
    return IOService.get('racing_group', { racing_id })
      .then((res) => {
        this.cacheDatas[racing_id] = res.data
        return res
      })
  }

  store (params: object) {
    return IOService.post('racing_group', {
      ...params,
      racing_id: RouterService.query('racing_id')
    })
  }

  show (id: number) {
    return IOService.get(`racing_group/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing_group/${params.id}`, {
      racing_id: RouterService.query('racing_id'),
      ...params
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_group/${id}`)
  }

  get (racing_id?: number, racing_group_id?: number) {
    return this.getList(racing_id)
      .then((racingGroupList) => {
        if (racing_group_id) {
          racing_group_id = Number(racing_group_id)
          const data = racingGroupList.find((res: any) => res.id === racing_group_id)
          if (data) {
            return data
          } else {
            return this.show(racing_group_id)
              .then((res) => res.data)
          }
        } else {
          racing_group_id = Number(RouterService.query('racing_group_id'))
          if (racing_group_id) {
            const data = racingGroupList.find((res: any) => res.id === racing_group_id)
            if (data) {
              Object.assign(this.currentData, data)
            } else {
              return this.show(racing_group_id)
                .then((res) => {
                  Object.assign(this.currentData, res.data)
                })
            }
          }
        }
      })
  }

  getList (racing_id?: number) {
    racing_id = racing_id ? Number(racing_id) : Number(RouterService.query('racing_id'))
    return Promise.resolve()
      .then(() => {
        if (racing_id) {
          if (this.cacheDatas[racing_id] && this.cacheDatas[racing_id].length > 0) {
            return this.cacheDatas[racing_id]
          } else {
            return this.index().then((res) => res.data)
          }
        }
      })
  }
}

export default new RacingGroupService()
