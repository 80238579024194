export default [
  {
    path: '/admin/user',
    component: require('@/biz/admin/views/admin/user/index.vue').default
  },
  {
    path: '/admin/user/form',
    component: require('@/biz/admin/views/admin/user/form.vue').default
  },
  // 职位
  {
    path: '/admin/role',
    component: require('@/biz/admin/views/admin/role/index.vue').default
  },
  {
    path: '/admin/role/form',
    component: require('@/biz/admin/views/admin/role/form.vue').default
  }
]
