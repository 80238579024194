
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'
import MallClassifySpecOptionService from '@/biz/service/Mall/MallClassifySpecOptionService'

@Component
export default class MallClassifySpecOptionForm extends Vue {
  @Prop()
  params!: { mall_classify_spec_id: number; id: number }

  private MallClassifySpecOptionService = MallClassifySpecOptionService
  private form = {
    id: this.params ? this.params.id : RouterService.query('id'),
    display_name: '',
    mall_classify_spec_id: this.params ? this.params.mall_classify_spec_id : RouterService.query('mall_classify_spec_id')
  }

  private formFields: IFormFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '参数值',
      rule: [ValidateService.required, ValidateService.max(60)]
    }
  })
}
