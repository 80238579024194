
import { Component, Prop, Vue } from 'vue-property-decorator'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingService from '@/biz/service/Racing/RacingService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'
import ConstService from '@/service/ConstService'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintRallyApply extends Vue {
  @Prop()
  params!: { row: any }

  private RacingService = RacingService
  private ConstService = ConstService
  private racingGroupList = [] as any[]
  private racingGroupProps = {
    label: 'display_name',
    value: 'id'
  }

  private handleLoad () {
    return RacingGroupService.getList()
      .then((racingGroupList: any[]) => {
        this.racingGroupList = racingGroupList
      })
  }

  private handleTableLoad () {
    return Promise.resolve([this.params.row])
  }
}
