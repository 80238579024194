
import FormMixins from './FormMixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class FormInput extends Mixins(FormMixins) {
  @Prop({ default: false })
  autosize!: boolean

  private maxLength = null

  created () {
    const rule = (this.field.rule || []).find((res) => res.max || res.len)
    if (rule) {
      this.maxLength = rule.max || rule.len
    }
    this.innerValue = typeof this.innerValue === 'string' ? this.innerValue : JSON.stringify(this.innerValue)
  }

  private handleInput (value: string) {
    this.$emit('input', value)
  }
}
