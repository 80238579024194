
import { Component, Ref, Vue } from 'vue-property-decorator'
import Ab from './components/Index/Ab.vue'
import Many from './components/Index/Many.vue'
import Rally from './components/Index/Rally.vue'
import T3 from './components/Index/T3.vue'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'
import RouterService from '@/service/RouterService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import DialogService from '@/service/DialogService/DialogService'

@Component({
  components: {
    Ab,
    Many,
    Rally,
    T3
  }
})
export default class ViewRacingRoundIndex extends Vue {
  @Ref()
  tableElement!: any

  private current = ''
  private racing_group_id = RouterService.query('racing_group_id')
  private Service = RacingRoundService

  private handleCoinRewardRule (row: any) {
    return DialogService.show(require('@/biz/components/Racing/components/CoinRewardRule.vue').default, {
      id: row.id,
      coin_reward_rule: row.coin_reward_rule,
      width: '400px'
    })
      .then(() => {
        this.tableElement.reload()
      })
  }

  created () {
    this.current = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
  }
}
