
import { Component, Vue, Watch } from 'vue-property-decorator'
import RacingResultService from '@/biz/service/Racing/RacingResultService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import FormInputResult from '@/components/Form/FormInputResult.vue'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import { getDiffTime } from '@/service/ToolsService'
import FormResultPunish from '@/components/Form/FormResultPunish.vue'

@Component({
  components: {
    FormInputResult,
    FormResultPunish
  }
})
export default class ViewRacingResultManyForm extends Vue {
  @Watch('form.sprint_time')
  onSprintTime() {
    this.setTrackTime()
  }

  @Watch('form.start_time')
  onStartTime() {
    this.setTrackTime()
  }

  private Service = RacingResultService
  private RacingGroupService = RacingGroupService
  private form = {
    id: RouterService.query('id'),
    racing_number: '',
    real_name: '',
    nationality: '',
    pilot_real_name: '',
    pilot_nationality: '',
    team_name: '',
    car_type: '',
    displacement: '',
    car_color: '',
    car_weight: '',
    group_sort: '',
    sort: '',
    check_time: '',
    start_time: '',
    sprint_time: '',
    track_time: '',
    track_f: '',
    result: '',
    result_total: '',
    ranking_total: '',
    result_sort: '',
    punish_record: [],
    _ms_number: RacingGroupService.currentData.ms_number
  }

  private formFields = ValidateService.genRules({
    real_name: {
      prop: 'real_name',
      label: '车手',
      rule: [ValidateService.required]
    },
    nationality: {
      prop: 'nationality',
      label: '车手国籍',
      rule: [ValidateService.required]
    },
    pilot_real_name: {
      prop: 'pilot_real_name',
      label: '领航员',
      rule: [ValidateService.required]
    },
    pilot_nationality: {
      prop: 'pilot_nationality',
      label: '领航员国籍',
      rule: [ValidateService.required]
    },
    racing_number: {
      prop: 'racing_number',
      label: '赛号',
      rule: [ValidateService.required]
    },
    team_name: {
      prop: 'team_name',
      label: '车队',
      rule: [ValidateService.required, ValidateService.max(20)]
    },
    car_type: {
      prop: 'car_type',
      label: '赛车车型',
      rule: [ValidateService.required, ValidateService.max(20)]
    },
    displacement: {
      prop: 'displacement',
      label: '赛车排量',
      rule: [ValidateService.required, ValidateService.max(10)]
    },
    car_color: {
      prop: 'car_color',
      label: '赛车颜色',
      rule: [ValidateService.max(10)]
    },
    car_weight: {
      prop: 'car_weight',
      label: '赛车重量',
      precision: 1,
      tips: '(KG)'
    },
    group_sort: {
      prop: 'group_sort',
      label: '组别顺序',
      rule: [ValidateService.requiredNumber]
    },
    sort: {
      prop: 'sort',
      label: '发车顺序',
      rule: [ValidateService.requiredNumber]
    },
    check_time: {
      prop: 'check_time',
      label: '检录时间'
    },
    start_time: {
      prop: 'start_time',
      label: '发车时间'
    },
    sprint_time: {
      prop: 'sprint_time',
      label: '冲刺时间'
    },
    track_time: {
      prop: 'track_time',
      label: '赛道用时',
      rule: [RacingGroupService.currentData.ms_number === 2 ? ValidateService.result2 : ValidateService.result3],
      disabled: false
    },
    punish_record: {
      prop: 'punish_record',
      label: '赛道罚时'
    },
    result: {
      prop: 'result',
      label: '成绩',
      disabled: true
    },
    result_total: {
      prop: 'result_total',
      label: '总成绩',
      disabled: true
    },
    ranking_total: {
      prop: 'ranking_total',
      label: '总排名',
      disabled: true
    },
    result_sort: {
      prop: 'result_sort',
      label: '成绩排序',
      disabled: true
    }
  })

  private setTrackTime() {
    if (this.form.sprint_time && this.form.start_time) {
      this.form.track_time = getDiffTime(
        this.form.sprint_time,
        this.form.start_time,
        RacingGroupService.currentData.ms_number
      )
    }
  }
}
