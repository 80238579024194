
import RouterService from '@/service/RouterService'
import { Component, Vue } from 'vue-property-decorator'
import RacingService from '@/biz/service/Racing/RacingService'

@Component
export default class RacingIndex extends Vue {
  private Service = RacingService

  private handleEntra (v: { id: string; title: string; racing_type: string }) {
    RouterService.push('/racing/info', { racing_id: v.id, id: v.id })
  }
}
