
import ConstService from '@/service/ConstService'
import { Component, Vue } from 'vue-property-decorator'
import AbStart from './components/AbStart.vue'
import ManyStart from './components/ManyStart.vue'
import RallyStart from './components/RallyStart.vue'
import T3Start from './components/T3Start.vue'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    AbStart,
    ManyStart,
    RallyStart,
    T3Start
  }
})
export default class ViewRacingPrintStart extends Vue {
  private current = ''

  created () {
    this.current = `${ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)}Start`
  }
}
