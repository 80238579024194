import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class Service extends BaseAbstract {
  displayName = '微信关注'
  name = 'UserOfficialAccount'
  index (params: any) {
    return IOService.get('user_official_account', params)
  }
}

export default new Service()
