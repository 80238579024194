
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DialogParams } from './DialogService'

@Component
export default class ServiceDialog extends Vue {
  @Prop()
  component!: string

  @Prop()
  params!: DialogParams

  @Prop()
  Service!: any

  private isShowModal = false
  private resolve!: Function
  private reject!: Function

  open () {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.isShowModal = true
    })
  }

  close () {
    this.isShowModal = false
    this.$destroy()
    this.reject('关闭弹窗')
  }

  handleDone (data: any) {
    this.isShowModal = false
    this.resolve(data)
    setTimeout(() => {
      this.$destroy()
    }, 300)
  }
}
