
import DialogService from '@/service/DialogService/DialogService'
import { Component, Vue } from 'vue-property-decorator'
import RacingDynamicService from '@/biz/service/Racing/RacingDynamicService'
import RacingDynamicCommentService from '@/biz/service/Comment/RacingDynamicCommentService'

@Component
export default class RacingDynamicCheckedIndex extends Vue {
  private Service = RacingDynamicService

  private handleLoad (params: any) {
    return this.Service.index({
      ...params,
      dynamic_type: RacingDynamicService.getOptionsValue('dynamic_type', 2, '赛事动态')
    })
  }

  private handleComment (id: number) {
    return DialogService.show(require('@/biz/components/components/Comments.vue').default, { id, CommentService: RacingDynamicCommentService })
  }
}
