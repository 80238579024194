
import { Component, Vue } from 'vue-property-decorator'
import UserRoleService from '@/biz/service/User/UserRoleService'

@Component
export default class ViewUserMemberRoleIndex extends Vue {
  private Service = UserRoleService

  private handlePermissions (v: { id: number }) {
    return UserRoleService.dialog(require('@/biz/components/components/Permissions.vue').default, {
      id: v.id,
      Service: UserRoleService
    })
  }
}
