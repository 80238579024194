
import MallOrderService from '@/biz/service/Mall/MallOrderService'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ServiceDialogExpressInfo extends Vue {
  @Prop()
  params!: { mall_store_order_id: number }

  private info = {
    cname: '',
    list: []
  }

  private handleLoad () {
    return MallOrderService.getExpress(this.params.mall_store_order_id)
      .then((res) => {
        Object.assign(this.info, res.data)
      })
  }
}
