
import { Component, Ref, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import RacingApplyService from '@/biz/service/Racing/RacingApplyService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import ConstService from '@/service/ConstService'

@Component
export default class ViewRacingApplyRallyForm extends Vue {
  @Ref()
  formElement!: any

  private Service = RacingApplyService
  private form = {
    id: RouterService.query('id'),
    racing_group_id: Number(RouterService.query('racing_group_id') || 0),
    // 车手
    real_name: '',
    id_card: '',
    phone: '',
    blood: 0,
    nationality: 900101,
    // 领航员
    pilot_real_name: '',
    pilot_id_card: '',
    pilot_phone: '',
    pilot_blood: 0,
    pilot_nationality: 900101,
    // 通用
    racing_number: '',
    team_name: '',
    car_type: '',
    displacement: '',
    car_color: '',
    car_weight: '',
    sort: ''
  }

  private currentRacingGroupItem = {
    id: 0,
    is_pilot_required: 1
  }

  private formFields = {} as any

  handleBeforeLoad() {
    return this.getRacingGroupItem()
      .then(() => this.initRule())
      .then(() => RacingGroupService.getList())
      .then((data) => {
        this.formFields.racing_group_id.options = data
      })
  }

  private validateRepeatIdCard(rule: any, value: string, callback: Function) {
    if (!value || this.form.id_card !== this.form.pilot_id_card) {
      callback()
    } else {
      callback(new Error('车手和领航员不能是同一个人'))
    }
  }

  private getRacingGroupItem() {
    return Promise.resolve().then(() => {
      if (this.form.racing_group_id) {
        return RacingGroupService.get(undefined, this.form.racing_group_id).then((data) => {
          Object.assign(this.currentRacingGroupItem, data)
        })
      }
    })
  }

  private handleChangeRacingGroup(val: number) {
    this.form.racing_group_id = val
    this.formElement.reload()
  }

  private initRule() {
    this.formFields = ValidateService.genRules({
      racing_group_id: {
        prop: 'racing_group_id',
        label: '组别',
        options: [],
        props: {
          value: 'id',
          label: 'display_name'
        },
        rule: [ValidateService.required({ trigger: 'change', type: 'number' })]
      },
      real_name: {
        prop: 'real_name',
        label: '姓名',
        rule: [ValidateService.required]
      },
      id_card: {
        prop: 'id_card',
        label: '身份证',
        rule: [
          ValidateService.required,
          // ValidateService.idcard,
          () => {
            return {
              repeat_id_card: { validator: this.validateRepeatIdCard, trigger: 'blur' }
            }
          }
        ]
      },
      phone: {
        prop: 'phone',
        label: '手机号',
        rule: [ValidateService.required, ValidateService.mobile]
      },
      blood: {
        prop: 'blood',
        label: '血型',
        rule: [ValidateService.requiredNumber]
      },
      nationality: {
        prop: 'nationality',
        label: '国籍',
        rule: [ValidateService.required]
      },
      pilot_real_name: {
        prop: 'pilot_real_name',
        label: '姓名',
        rule: [this.currentRacingGroupItem.is_pilot_required ? ValidateService.required : ValidateService.optional]
      },
      pilot_id_card: {
        prop: 'pilot_id_card',
        label: '身份证',
        rule: [
          this.currentRacingGroupItem.is_pilot_required ? ValidateService.required : ValidateService.optional,
          // ValidateService.idcard,
          () => {
            return {
              repeat_id_card: { validator: this.validateRepeatIdCard, trigger: 'blur' }
            }
          }
        ]
      },
      pilot_phone: {
        prop: 'pilot_phone',
        label: '手机号',
        rule: [
          this.currentRacingGroupItem.is_pilot_required ? ValidateService.required : ValidateService.optional,
          ValidateService.mobile
        ]
      },
      pilot_blood: {
        prop: 'pilot_blood',
        label: '血型',
        rule: [
          this.currentRacingGroupItem.is_pilot_required ? ValidateService.requiredNumber : ValidateService.optional
        ],
        options: ConstService.getOptions('', 'blood')
      },
      pilot_nationality: {
        prop: 'pilot_nationality',
        label: '国籍',
        rule: [this.currentRacingGroupItem.is_pilot_required ? ValidateService.required : ValidateService.optional]
      },
      racing_number: {
        prop: 'racing_number',
        label: '赛号'
      },
      team_name: {
        prop: 'team_name',
        label: '车队',
        rule: [ValidateService.required, ValidateService.max(20)]
      },
      car_type: {
        prop: 'car_type',
        label: '赛车车型',
        rule: [ValidateService.required, ValidateService.max(20)]
      },
      displacement: {
        prop: 'displacement',
        label: '赛车排量',
        rule: [ValidateService.required, ValidateService.max(10)]
      },
      car_color: {
        prop: 'car_color',
        label: '赛车颜色',
        rule: [ValidateService.required, ValidateService.max(10)]
      },
      car_weight: {
        prop: 'car_weight',
        label: '赛车重量',
        tips: '(KG)'
      },
      sort: {
        prop: 'sort',
        label: '发车顺序',
        rule: [ValidateService.requiredNumber]
      }
    })
  }

  created() {
    if (process.env.NODE_ENV === 'development') {
      this.form.real_name = '万鑫'
      this.form.id_card = '360103199105290014'
      this.form.phone = '13724352543'
      this.form.blood = 1
      this.form.nationality = 900101
      this.form.pilot_real_name = '领航员'
      this.form.pilot_id_card = '513436200004218136'
      this.form.pilot_phone = '13724352543'
      this.form.pilot_blood = 1
      this.form.pilot_nationality = 900101
      this.form.racing_number = '10'
      this.form.team_name = '万鑫的车队'
      this.form.car_type = '车型'
      this.form.displacement = '排量'
      this.form.car_color = '白色'
      this.form.car_weight = '100'
    }
  }
}
