
import FormMixins from './FormMixins'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import FormInputResult from './FormInputResult.vue'
import ValidateService from '@/service/ValidateService'

interface InnerRule {
  required?: boolean;
  validator?: Function;
  message?: string;
}

interface FormElement {
  validate: Function;
  validateField: Function;
  resetFields: Function;
  clearValidate: Function;
}

@Component({
  components: {
    FormInputResult
  }
})
export default class FormResultPunish extends Mixins(FormMixins) {
  @Prop()
  ms_number!: number

  @Ref()
  formItemElement!: any

  @Ref()
  formElement!: FormElement[]

  private innerRules: InnerRule[] = [{ validator: this.validateValue }]

  private innerField = ValidateService.genRules({
    value: {
      prop: 'value',
      label: '罚时',
      labelWidth: '60px',
      width: 160,
      rule: [ValidateService.required, this.ms_number === 2 ? ValidateService.result2 : ValidateService.result3]
    },
    desc: {
      prop: 'value',
      label: '罚时说明',
      labelWidth: '80px',
      rule: [ValidateService.required, ValidateService.max(60)]
    }
  })

  private handleAdd () {
    return this.validate()
      .then(() => {
        this.innerValue.push({
          value: '',
          desc: ''
        })
      })
  }

  public validate () {
    return (this.formElement || []).reduce((acc, form) => {
      return acc.then(() => form.validate())
    }, Promise.resolve())
  }

  private handleDelete (index: number) {
    this.$confirm('您真的要删除吗？')
      .then(() => {
        this.innerValue.splice(index, 1)
      })
  }

  private validateValue (rule: any, value: number, callback: Function) {
    if (Array.isArray(this.innerValue)) {
      this.validate()
        .then(() => {
          callback()
        })
        .catch(() => {
          callback(new Error('请输入正确的罚时与说明'))
        })
    } else {
      callback()
    }
  }

  created () {
    this.field.rule = this.innerRules
  }
}
