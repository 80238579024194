import VueRouter from 'vue-router'
import { IMenu } from '@/interface/common'
import CacheService from './CacheService'
import { getAncestorsAndSelf } from './ToolsService'
import QueryString from 'qs'

class RouterSerivce {
  router!: VueRouter

  init (router: VueRouter) {
    this.router = router

    this.router.beforeEach((to, from, next) => {
      document.title = process.env.VUE_APP_NAME
      next()
    })
  }

  push (path: string, query: object = {}) {
    if (path === this.router.app.$route.path) {
      return
    }
    if (!path) {
      console.warn('RouterService.push：path参数不存在')
      return false
    }
    if (path.substr(0, 1) !== '/') {
      path = this.getPath() + '/' + path
    }
    this.router.push({
      path,
      query: {
        ...this.getNeedParams(path),
        ...query
      }
    })
  }

  pushForm (query: { id?: string | number; marking?: number } = {}) {
    if (!query.id) {
      query.marking = (new Date()).valueOf()
    }
    this.push(this.router.app.$route.path + '/form', {
      ...this.router.app.$route.query,
      ...query
    })
  }

  replace (path: string, query?: object) {
    if (path === '') {
      path = this.getPath()
      path = path.split('/').filter((res) => res !== 'form').join('/')
    } else if (path.substr(0, 1) !== '/') {
      path = this.getPath() + '/' + path
    }
    const urlQuery = this.router.app.$route.query
    const currentQuery: { [key: string]: any } = {
      ...this.getNeedParams(path),
      ...query
    }
    if (path === this.router.app.$route.path && JSON.stringify(urlQuery) === JSON.stringify(currentQuery)) {
      return
    }
    this.router.replace({
      path,
      query: currentQuery
    })
  }

  go (step = -1) {
    this.router.go(step)
  }

  query (name: string): any {
    const hash = location.hash.split('?')
    if (hash.length > 1) {
      const obj = QueryString.parse(hash[1])
      return obj[name] || ''
    } else {
      return ''
    }
  }

  getPath () {
    return this.router.app.$route.path
  }

  getFullPath () {
    return this.router.app.$route.fullPath
  }

  getSearchQueryString (params: { [key: string]: any } = {}) {
    return QueryString.stringify(Object.assign({}, this.getSearchQuery(), params))
  }

  getSearchQuery (key?: string) {
    const search = QueryString.parse(this.query('_search'))
    if (key) {
      return search[key]
    } else {
      return search
    }
  }

  getNeedParams (path: string) {
    const list: IMenu[] = CacheService.layout.get('menus') || []
    const item = list.find((res) => res.route === path)
    let params = {}
    let defaultParams = {}
    if (item) {
      const paths: IMenu[] = getAncestorsAndSelf(item.id, list)
      params = paths
        .reduce((acc: string[], row) => {
          acc = acc.concat(row.params as string[] || [])
          return acc
        }, [])
        .reduce((acc: { [key: string]: string | number }, key) => {
          acc[key] = this.query(key)
          return acc
        }, {})

      defaultParams = paths
        .reduce((acc: { [key: string]: string | number }, row) => {
          return Object.assign({}, acc, row.default_params)
        }, {})
    }
    return Object.assign({}, params, defaultParams)
  }
}

export default new RouterSerivce()
