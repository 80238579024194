
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import RacingApplyService from '@/biz/service/Racing/RacingApplyService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'

@Component
export default class ViewRacingApplyGeneralForm extends Vue {
  private Service = RacingApplyService
  private form = {
    id: RouterService.query('id'),
    racing_group_id: Number(RouterService.query('racing_group_id') || 0),
    racing_number: '',
    team_name: '',
    real_name: '',
    id_card: '',
    phone: '',
    blood: 1,
    nationality: 1,
    car_type: '',
    displacement: '',
    car_color: '',
    car_weight: '',
    sort: ''
  }

  private formFields = ValidateService.genRules({
    racing_group_id: {
      prop: 'racing_group_id',
      label: '组别',
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      },
      rule: [ValidateService.required({ trigger: 'change', type: 'number' })]
    },
    racing_number: {
      prop: 'racing_number',
      label: '赛号'
    },
    team_name: {
      prop: 'team_name',
      label: '车队',
      rule: [ValidateService.required, ValidateService.max(16)]
    },
    real_name: {
      prop: 'real_name',
      label: '姓名',
      rule: [ValidateService.required]
    },
    id_card: {
      prop: 'id_card',
      label: '身份证',
      rule: [
        this.form.id ? ValidateService.optional : ValidateService.required
        // ValidateService.idcard
      ]
    },
    phone: {
      prop: 'phone',
      label: '手机号',
      rule: [this.form.id ? ValidateService.optional : ValidateService.required, ValidateService.mobile]
    },
    blood: {
      prop: 'blood',
      label: '血型',
      rule: [this.form.id ? ValidateService.optional : ValidateService.requiredNumber]
    },
    nationality: {
      prop: 'nationality',
      label: '国籍',
      rule: [this.form.id ? ValidateService.optional : ValidateService.required]
    },
    car_type: {
      prop: 'car_type',
      label: '赛车车型',
      rule: [ValidateService.required, ValidateService.max(20)]
    },
    displacement: {
      prop: 'displacement',
      label: '赛车排量',
      rule: [ValidateService.required, ValidateService.max(10)]
    },
    car_color: {
      prop: 'car_color',
      label: '赛车颜色',
      rule: [this.form.id ? ValidateService.optional : ValidateService.required, ValidateService.max(10)]
    },
    car_weight: {
      prop: 'car_weight',
      label: '赛车重量',
      tips: '(KG)'
    },
    sort: {
      prop: 'sort',
      label: '发车顺序',
      rule: [ValidateService.requiredNumber]
    }
  })

  handleBeforeLoad() {
    return RacingGroupService.getList().then((data) => {
      this.formFields.racing_group_id.options = data
    })
  }

  created() {
    if (process.env.NODE_ENV === 'development') {
      this.form.racing_number = '10'
      this.form.team_name = '万鑫的车队'
      this.form.real_name = '万鑫'
      this.form.id_card = '360103199105290014'
      this.form.phone = '13724352543'
      this.form.blood = 1
      this.form.nationality = 1
      this.form.car_type = '车型'
      this.form.displacement = '排量'
      this.form.car_color = '白色'
      this.form.car_weight = '100'
    }
  }
}
