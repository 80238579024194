import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class AdService extends BaseAbstract {
  name = 'Ad'
  displayName = '广告'

  index (params: { page: number }) {
    return IOService.get('ad', params)
  }

  store (params: any) {
    return IOService.post('ad', params)
  }

  show (id: number) {
    return IOService.get(`ad/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`ad/${params.id}`, params)
  }

  destroy (id: number) {
    return IOService.delete(`ad/${id}`)
  }
}

export default new AdService()
