
import { Component, Vue } from 'vue-property-decorator'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import RacingService from '@/biz/service/Racing/RacingService'
import Ab from './components/Form/Ab.vue'
import Many from './components/Form/Many.vue'
import Rally from './components/Form/Rally.vue'
import T3 from './components/Form/T3.vue'
import ConstService from '@/service/ConstService'

@Component({
  components: {
    Ab,
    Many,
    Rally,
    T3
  }
})
export default class RacingGroupForm extends Vue {
  private Service = RacingGroupService
  private current = ''
  private form = {
    id: RouterService.query('id'),
    racing_id: 0,
    display_name: '',
    amount: '',
    apply_unit: '人',
    ms_number: 0,
    max_time: 0,
    is_pilot_required: 1,
    is_open: 1,
    is_repeat_apply: 0,
    is_wechat_pay: 1,
    is_coin_pay: 1,
    is_insure: 1
  }

  private formFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '名称',
      rule: [ValidateService.required, ValidateService.max(30)],
      width: 300
    },
    amount: {
      prop: 'amount',
      label: '报名费',
      rule: [ValidateService.requiredNumber]
    },
    apply_unit: {
      prop: 'apply_unit',
      label: '报名单位',
      rule: [ValidateService.required],
      options: [
        { value: '人', display_name: '人' },
        { value: '辆', display_name: '辆' }
      ]
    },
    ms_number: {
      prop: 'ms_number',
      label: '毫秒位数',
      rule: [ValidateService.requiredNumber],
      options: [
        { value: 2, display_name: '2位' },
        { value: 3, display_name: '3位' }
      ]
    },
    max_time: {
      prop: 'max_time',
      label: '最大给时',
      rule: [ValidateService.requiredNumber]
    },
    is_open: {
      prop: 'is_open',
      label: '开启报名'
    },
    is_pilot_required: {
      prop: 'is_pilot_required',
      label: '领航员必填'
    },
    is_repeat_apply: {
      prop: 'is_repeat_apply',
      label: '可重复报名'
    },
    is_wechat_pay: {
      prop: 'is_wechat_pay',
      label: '微信支付'
    },
    is_coin_pay: {
      prop: 'is_coin_pay',
      label: '赛币支付'
    },
    is_insure: {
      prop: 'is_insure',
      label: '是否投保'
    }
  })

  created() {
    this.current = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
    if (process.env.NODE_ENV === 'development') {
      this.form.racing_id = RouterService.query('racing_id')
      this.form.display_name = '公开组'
      this.form.amount = '500'
      this.form.ms_number = 2
      this.form.max_time = 5
      this.form.is_open = 1
      this.form.is_wechat_pay = 1
      this.form.is_coin_pay = 1
    }
  }
}
