
import RouterService from '@/service/RouterService'
import { Component, Vue } from 'vue-property-decorator'
import EnterpriseService from '@/biz/service/Enterprise/EnterpriseService'

@Component
export default class ViewEnterpriseIndex extends Vue {
  private Service = EnterpriseService

  private handleEntra (v: { id: string }) {
    RouterService.push('/enterprise/info', { enterprise_id: v.id, id: v.id })
  }

  private handlePermissions (v: { id: string }) {
    return EnterpriseService.dialog(require('@/biz/components/components/Permissions.vue').default, {
      id: v.id,
      Service: EnterpriseService
    })
  }
}
