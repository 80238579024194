export default [
  // 会员
  {
    path: '/member/user',
    component: require('@/biz/admin/views/member/user/index.vue').default
  },
  {
    path: '/member/user/form',
    component: require('@/biz/admin/views/member/user/form.vue').default
  },
  // 角色
  {
    path: '/member/role',
    component: require('@/biz/admin/views/member/role/index.vue').default
  },
  {
    path: '/member/role/form',
    component: require('@/biz/admin/views/member/role/form.vue').default
  },
  // 个人认证
  {
    path: '/member/personal-auth',
    component: require('@/biz/admin/views/member/personal-auth/index.vue').default
  },
  {
    path: '/member/personal-auth/form',
    component: require('@/biz/admin/views/member/personal-auth/form.vue').default
  },
  // 微信关注
  {
    path: '/member/wechat',
    component: require('@/biz/admin/views/member/wechat/index.vue').default
  }
]
