export default [
  {
    path: 'enterprise',
    component: require('@/biz/components/Enterprise/Main.vue').default,
    children: [
      {
        path: 'info',
        component: require('@/biz/components/Enterprise/Form.vue').default
      },
      {
        path: 'position',
        component: require('@/biz/components/Enterprise/Position/Index.vue').default
      },
      {
        path: 'position/form',
        component: require('@/biz/components/Enterprise/Position/Form.vue').default
      },
      {
        path: 'employee',
        component: require('@/biz/components/Enterprise/Employee/Index.vue').default
      },
      {
        path: 'employee/form',
        component: require('@/biz/components/Enterprise/Employee/Form.vue').default
      }
    ]
  }
]
