
import { Component, Ref, Vue } from 'vue-property-decorator'
import AdminUserService from '@/biz/service/User/AdminUserService'

@Component
export default class ViewUserAdminEmployeeIndex extends Vue {
  @Ref()
  tableElement!: any

  private Service = AdminUserService
}
