import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingNoticeService extends BaseAbstract {
  displayName = '公告'
  name = 'RacingNotice'

  index () {
    return IOService.get('racing_notice', { racing_id: RouterService.query('racing_id') })
  }

  store (params: object) {
    return IOService.post('racing_notice', {
      ...params,
      racing_id: RouterService.query('racing_id'),
      marking: RouterService.query('marking')
    })
  }

  show (id: number) {
    return IOService.get(`racing_notice/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing_notice/${params.id}`, params)
  }

  destroy (id: number) {
    return IOService.delete(`racing_notice/${id}`)
  }
}

export default new RacingNoticeService()
