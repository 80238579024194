
import { Component, Vue } from 'vue-property-decorator'
import AbResult from './Ab/Form.vue'
import ManyResult from './Many/Form.vue'
import RallyResult from './Rally/Form.vue'
import T3Result from './T3/Form.vue'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'

@Component({
  components: {
    AbResult,
    ManyResult,
    RallyResult,
    T3Result
  }
})
export default class ViewRacingResultForm extends Vue {
  private current = ''

  created () {
    const name = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
    this.current = name + 'Result'
  }
}
