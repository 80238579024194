
import { Component, Vue } from 'vue-property-decorator'
import AdminLogService from '@/biz/service/System/AdminLogService'
import { ISearchFields } from '@/interface/common'

@Component
export default class ViewSystemAdminLogIndex extends Vue {
  private Service = AdminLogService

  private searchFields: ISearchFields[] = [
    {
      name: 'nickname',
      display_name: '操作人',
      valueType: 'string'
    }
  ]
}
