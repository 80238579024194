
import FormMixins from './FormMixins'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

interface InnerRule {
  required?: boolean;
  validator?: Function;
  message?: string;
}

@Component
export default class FormRangeDate extends Mixins(FormMixins) {
  @Prop({ default: 'daterange' })
  type!: string

  @Prop()
  minValue!: string

  @Prop()
  maxValue!: string

  private valueFormat = 'yyyy-MM-dd'
  private innerRules: InnerRule[] = []

  @Watch('innerValue')
  onInnerValue (val: string[]) {
    val = val || []
    const [minValue, maxValue] = val
    this.$emit('update:minValue', minValue)
    this.$emit('update:maxValue', maxValue)
  }

  private validateRequired (rule: any, value: number, callback: Function) {
    if (this.minValue && this.maxValue) {
      callback()
    } else {
      const text = `请选择${this.field.label}`
      callback(text)
    }
  }

  private initRequiredRule () {
    const requiredIndex = (this.field.rule || []).findIndex((res) => res.required)
    if (requiredIndex > -1 && this.field.rule) {
      this.field.rule.splice(requiredIndex, 1, { required: true, type: 'array', trigger: 'change', validator: this.validateRequired })
    }
  }

  created () {
    if (this.type === 'monthrange') {
      this.valueFormat = 'yyyy-MM'
    }
    this.innerValue = (this.minValue && this.maxValue) ? [this.minValue, this.maxValue] : []
    this.initRequiredRule()
  }
}
