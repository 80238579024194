import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class UserService extends BaseAbstract {
  displayName = '会员'
  name = 'User'
  index (params: { page: number; guard_name: string }) {
    return IOService.get('user', params)
  }

  show (id: number | string) {
    return IOService.get(`user/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user/${params.id}}`, params)
  }
}

export default new UserService()
