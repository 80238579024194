
import { Component, Vue } from 'vue-property-decorator'
import MallClassifySpecOptionService from '@/biz/service/Mall/MallClassifySpecOptionService'
import RouterService from '@/service/RouterService'

@Component
export default class MallClassifySpecOptionIndex extends Vue {
  private MallClassifySpecOptionService = MallClassifySpecOptionService
  private mall_classify_spec_id = RouterService.query('mall_classify_spec_id')
}
