export default [
  {
    path: '/mall/mall-classify-manage',
    component: require('@/biz/components/Mall/MallClassify/Index.vue').default
  },
  {
    path: '/mall/mall-classify-manage/form',
    component: require('@/biz/components/Mall/MallClassify/Form.vue').default
  },
  {
    path: '/mall/mall-classify',
    component: require('@/biz/components/Mall/MallClassify/Main.vue').default,
    children: [
      {
        path: 'mall-classify-spec-manage',
        component: require('@/biz/components/Mall/MallClassifySpec/Index.vue').default
      },
      {
        path: 'mall-classify-spec-manage/form',
        component: require('@/biz/components/Mall/MallClassifySpec/Form.vue').default
      },
      {
        path: 'mall-classify-spec',
        component: require('@/biz/components/Mall/MallClassifySpec/Main.vue').default,
        children: [
          {
            path: 'mall-classify-spec-option',
            component: require('@/biz/components/Mall/MallClassifySpecOption/Index.vue').default
          },
          {
            path: 'mall-classify-spec-option/form',
            component: require('@/biz/components/Mall/MallClassifySpecOption/Form.vue').default
          }
        ]
      }
    ]
  },
  {
    path: '/mall/mall-brand',
    component: require('@/biz/components/Mall/MallBrand/Index.vue').default
  },
  {
    path: '/mall/mall-brand/form',
    component: require('@/biz/components/Mall/MallBrand/Form.vue').default
  },
  {
    path: '/mall/mall-store',
    component: require('@/biz/components/Mall/MallStore/Index.vue').default
  },
  {
    path: '/mall/mall-store/form',
    component: require('@/biz/components/Mall/MallStore/Form.vue').default
  },
  {
    path: '/mall/mall-product',
    component: require('@/biz/components/Mall/MallProduct/Index.vue').default
  },
  {
    path: '/mall/mall-product/form',
    component: require('@/biz/components/Mall/MallProduct/Form.vue').default
  },
  {
    path: '/mall/mall-order',
    component: require('@/biz/components/Mall/MallOrder/Index.vue').default
  }
]
