import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class EnterprisePositionService extends BaseAbstract {
  displayName = '职位'
  name = 'EnterprisePosition'
  cacheDatas = [] as any[]

  index () {
    return IOService.get('enterprise_position', {
      enterprise_id: RouterService.query('enterprise_id')
    })
      .then((res) => {
        this.cacheDatas = res.data
        return res
      })
  }

  store (params: object) {
    return IOService.post('enterprise_position', {
      ...params,
      enterprise_id: RouterService.query('enterprise_id')
    })
  }

  show (id: number) {
    return IOService.get(`enterprise_position/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`enterprise_position/${params.id}`, params)
  }

  destroy (id: number) {
    return IOService.delete(`enterprise_position/${id}`)
  }

  getTree () {
    return Promise.resolve()
      .then(() => {
        if (this.cacheDatas.length === 0) {
          return this.index()
        }
      })
      .then(() => this.cacheDatas)
  }

  getPermissions (enterprise_position_id: number) {
    return IOService.get('enterprise_position/getPermissions', { enterprise_position_id })
  }

  updatePermissions (enterprise_position_id: number, menus: number[], permissions: number[]) {
    return IOService.post('enterprise_position/updatePermissions', { enterprise_position_id, menus, permissions })
  }
}

export default new EnterprisePositionService()
