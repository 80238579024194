
import { ISearchFields } from '@/interface/common'
import ConstService from '@/service/ConstService'
import DialogService from '@/service/DialogService/DialogService'
import { Component, Ref, Vue } from 'vue-property-decorator'
import ActionButtons from '../components/ActionButtons.vue'
import RouterService from '@/service/RouterService'
import RacingApplyService from '@/biz/service/Racing/RacingApplyService'

@Component({
  components: {
    ActionButtons
  }
})
export default class ViewRacingApplyRallyIndex extends Vue {
  @Ref()
  tableElement!: any

  private Service = RacingApplyService
  private racing_group_id = RouterService.query('racing_group_id')
  private maxHeight = 0
  private ConstService = ConstService
  private wechatAmountTotal = 0
  private coinAmountTotal = 0
  private cashAmountTotal = 0

  private searchFields: ISearchFields[] = [
    {
      name: 'real_name',
      display_name: '车手',
      valueType: 'string'
    },
    {
      name: 'id_card',
      display_name: '车手身份证',
      valueType: 'string'
    },
    {
      name: 'phone',
      display_name: '车手手机号',
      valueType: 'string'
    },
    {
      name: 'pilot_real_name',
      display_name: '领航员',
      valueType: 'string'
    },
    {
      name: 'pilot_id_card',
      display_name: '领航员身份证',
      valueType: 'string'
    },
    {
      name: 'pilot_phone',
      display_name: '领航员手机号',
      valueType: 'string'
    },
    {
      name: 'racing_number',
      display_name: '赛号',
      valueType: 'int'
    },
    {
      name: 'team_name',
      display_name: '车队',
      valueType: 'string'
    },
    {
      name: 'is_pay',
      display_name: '支付',
      valueType: 'intOptions',
      options: ConstService.getBoolOptions()
    }
  ]

  private handleLoad (params: any) {
    return this.Service.index(params)
      .then((res) => {
        this.wechatAmountTotal = this.getPaymentList(res.data, RacingApplyService.getOptionsValue('payment', 1, '微信支付'))
          .reduce((acc: any, item: any) => {
            acc += item._racing_order.wechat_amount
            return acc
          }, 0)
        this.coinAmountTotal = this.getPaymentList(res.data, RacingApplyService.getOptionsValue('payment', 2, '赛币支付'))
          .reduce((acc: any, item: any) => {
            acc += item._racing_order.coin_amount
            return acc
          }, 0)
        this.cashAmountTotal = this.getPaymentList(res.data, RacingApplyService.getOptionsValue('payment', 4, '线下支付'))
          .reduce((acc: any, item: any) => {
            acc += item._racing_order.cash_amount
            return acc
          }, 0)
        return res
      })
  }

  private handleEnterIntoRound (row: { id: number }) {
    DialogService.show(require('@/biz/components/Racing/RacingApply/components/DialogEnterIntoRound.vue').default, {
      racing_apply_id: row.id,
      width: '400px'
    })
  }

  private handleReload () {
    this.tableElement.reload()
  }

  private handleView (row: any) {
    return DialogService.show(require('../../RacingPrint/Apply.vue').default, {
      top: '7vh',
      width: '210mm',
      row
    })
  }

  private handleCashPay (row: { id: number }) {
    return this.$confirm('您确认该用户已线下支付了吗？')
      .then(() => this.Service.cashPay(row.id))
      .then((res) => {
        this.handleReload()
        return res
      })
  }

  private getPaymentList (list: any[], payment: number) {
    return list.filter((item: any) => item._racing_order && item._racing_order.payment === payment)
  }

  private handleShowCashPay (row: { is_pay: number }) {
    return row.is_pay === 0
  }

  mounted () {
    if (this.tableElement) {
      this.maxHeight = document.body.clientHeight - (this.tableElement.$el.offsetTop + 170)
    }
  }
}
