
import { Component, Vue } from 'vue-property-decorator'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import RouterService from '@/service/RouterService'

@Component
export default class ViewRacingGroupMain extends Vue {
  private racingRoundList: any[] = []
  private active = ''
  private fullPath = ''
  private RouterService = RouterService

  private handleLoad () {
    return RacingRoundService.getList()
      .then((data: any[]) => {
        this.racingRoundList = data || []
        return RacingRoundService.get()
      })
  }

  private handleTabClick (res: any) {
    const racing_round_id = this.racingRoundList[res.index].id
    if (racing_round_id !== Number(RouterService.query('racing_round_id'))) {
      RouterService.replace('', { racing_round_id })
    }
  }

  private handleAddRound () {
    RouterService.push('/racing/racing-group/racing-round-manage/form', {
      ...this.$route.query
    })
  }

  created () {
    this.fullPath = RouterService.getFullPath()
    this.active = RouterService.query('racing_round_id')
  }
}
