import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class NotifyUserService extends BaseAbstract {
  displayName = '通知用户'
  name = 'NotifyUser'
  index (params: { page: number }) {
    return IOService.get('notify_user', {
      notify_template_id: RouterService.query('notify_template_id'),
      ...params
    })
  }

  store (params: object) {
    return IOService.post('notify_user', params)
  }

  destroy (id: number | string) {
    return IOService.delete(`notify_user/${id}`)
  }
}

export default new NotifyUserService()
