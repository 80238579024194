import CacheService from './CacheService'
import IOService from './IOService'
import VersionService from './VersionService'

interface LoginParams {
  username: string;
  password: string;
}

interface UserInfo {
  id: string;
  username: string;
  nickname: string;
  phone: string;
  current_enterprise_id: number;
}

class UserService {
  info: UserInfo = {
    id: '',
    nickname: '',
    username: '',
    phone: '',
    current_enterprise_id: 0
  }

  constructor () {
    Object.assign(this.info, CacheService.user.getAll())
  }

  login (params: LoginParams) {
    return IOService.post('auth/login', params)
      .then((res) => {
        CacheService.user.setAll(res.data)
        this.updateData(res.data)
      })
      .then(() => this.getUserConfig())
  }

  logout () {
    return Promise.resolve()
      .then(() => {
        CacheService.clearAll()
        VersionService.clearAll()
        return '注销成功'
      })
  }

  update () {
    return this.getUserConfig()
  }

  private updateData (params: UserInfo) {
    Object.assign(this.info, params)
  }

  private getUserConfig () {
    return IOService.get('auth/getUserConfig')
      .then((res) => {
        CacheService.user.setAll(res.data.user)
        this.updateData(res.data.user)
        CacheService.user.set('interface', res.data.interface || [])
        CacheService.layout.set('menus', res.data.menus)
      })
  }

  hasPermission (name: string) {
    const permissions: string[] = CacheService.user.get('interface') || []
    return this.hasRoot() || permissions.includes(name)
  }

  hasRoot () {
    const role = CacheService.user.get('admin_role') || {}
    return role.name === 'root'
  }
}

export default new UserService()
