
import RacingApplyService from '@/biz/service/Racing/RacingApplyService'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ComponentRacingSupports extends Vue {
  @Prop()
  params!: { racing_apply_id: number }

  private racingApplyInfo = {
    id: 0,
    coin_reward_ratio: 0,
    support_number: 0
  }

  handleLoad () {
    return RacingApplyService.show(this.params.racing_apply_id)
      .then((res) => {
        Object.assign(this.racingApplyInfo, res.data)
      })
  }

  handleTableLoad (params: any) {
    return RacingApplyService.getRacingSupports({
      ...params,
      racing_apply_id: this.params.racing_apply_id
    })
      .then((res) => {
        res.data.data = res.data.data.map((item: any) => {
          item.coin_reward_ratio = (item.coin_reward_ratio / 100) + '%'
          return item
        })
        return res
      })
  }
}
