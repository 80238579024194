import WeChatNotifyTemplate from '@/biz/admin/views/other/wechat/notify-template/index.vue'
import WeChatNotifyTemplateForm from '@/biz/admin/views/other/wechat/notify-template/form.vue'
import WeChatNotifyUser from '@/biz/admin/views/other/wechat/notify-user/index.vue'
import WeChatNotifyUserForm from '@/biz/admin/views/other/wechat/notify-user/form.vue'
// 通知记录
import WeChatNotify from '@/biz/admin/views/other/wechat/notify/index.vue'
import Ad from '@/biz/admin/views/other/ad/index.vue'
import AdForm from '@/biz/admin/views/other/ad/form.vue'

export default [
  {
    path: '/other/wechat/notify-template',
    component: WeChatNotifyTemplate
  },
  {
    path: '/other/wechat/notify-template/form',
    component: WeChatNotifyTemplateForm
  },
  {
    path: '/other/wechat/notify-user',
    component: WeChatNotifyUser
  },
  {
    path: '/other/wechat/notify-user/form',
    component: WeChatNotifyUserForm
  },
  // 通知记录
  {
    path: '/other/wechat/notify',
    component: WeChatNotify
  },
  {
    path: '/other/ad',
    component: Ad
  },
  {
    path: '/other/ad/form',
    component: AdForm
  }
]
