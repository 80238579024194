
import { IFormFieldItem } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SearchMixins from './SearchMixins'

@Component
export default class SearchIntOptionsValue extends Mixins(SearchMixins) {
  @Prop()
  options!: any[]

  @Prop()
  optionsProps!: { label: string; value: string }

  private field: IFormFieldItem = ValidateService.genRule({
    prop: 'value',
    label: '值',
    rule: [ValidateService.required({ trigger: 'change', type: 'array' })]
  })
}
