
import { Component, Vue } from 'vue-property-decorator'
import RacingDynamicCheckService from '@/biz/service/Info/RacingDynamicCheckService'

@Component
export default class RacingDynamicCheckIndex extends Vue {
  private Service = RacingDynamicCheckService

  private handleShow (row: { status: number }) {
    return row.status !== RacingDynamicCheckService.getStatusValue(2, '正在审核')
  }
}
