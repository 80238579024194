
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import { IMenu } from '@/interface/common'
import CacheService from '@/service/CacheService'

@Component
export default class SiderContainer extends Vue {
  private list: IMenu[] = []

  @Prop()
  routePaths!: IMenu[]

  @Watch('routePaths')
  onRoutePaths () {
    this.initList()
  }

  get routeActive () {
    if (this.routePaths.length >= 2) {
      return this.routePaths[1].route
    }
    return ''
  }

  private handleSelect (path: string) {
    RouterService.push(path)
  }

  private initList () {
    const RouteList: IMenu[] = CacheService.layout.get('menus') || []
    const rootRouteItem = this.routePaths[0]
    this.list = RouteList.filter((res) => res.parent_id === rootRouteItem.id)
  }

  created () {
    this.initList()
  }
}
