
import { Component, Vue } from 'vue-property-decorator'
import EnterprisePositionService from '@/biz/service/Enterprise/EnterprisePositionService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewEnterprisePositionForm extends Vue {
  private Service = EnterprisePositionService
  private form = {
    id: RouterService.query('id'),
    parent_id: 0,
    display_name: ''
  }

  private formFields = ValidateService.genRules({
    parent_id: {
      prop: 'parent_id',
      label: '父级',
      options: [],
      props: {
        checkStrictly: true
      }
    },
    display_name: {
      prop: 'display_name',
      label: '职位名称',
      rule: [ValidateService.required, ValidateService.max(30)]
    }
  })

  private handleBeforeLoad () {
    return EnterprisePositionService.getTree()
      .then((data) => {
        this.formFields.parent_id.options = data
      })
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form.display_name = '运营总监'
    }
  }
}
