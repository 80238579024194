import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class MallClassifySpecService extends BaseAbstract {
  displayName = '规格'
  name = 'MallClassifySpec'
  cacheDatas = {} as { [key: number]: any[] }

  index () {
    const mall_classify_id = Number(RouterService.query('mall_classify_id'))
    return IOService.get('mall_classify_spec', { mall_classify_id })
      .then((res) => {
        this.cacheDatas[mall_classify_id] = res.data
        return res
      })
  }

  store (params: object) {
    return IOService.post('mall_classify_spec', params)
  }

  show (id: number) {
    return IOService.get(`mall_classify_spec/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`mall_classify_spec/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`mall_classify_spec/${id}`)
  }

  getList (mall_classify_id?: number) {
    mall_classify_id = mall_classify_id ? Number(mall_classify_id) : Number(RouterService.query('mall_classify_id'))
    return Promise.resolve()
      .then(() => {
        if (mall_classify_id) {
          if (this.cacheDatas[mall_classify_id] && this.cacheDatas[mall_classify_id].length > 0) {
            return this.cacheDatas[mall_classify_id]
          } else {
            return this.index().then((res) => res.data)
          }
        }
      })
  }
}

export default new MallClassifySpecService()
