
import { Component, Vue } from 'vue-property-decorator'
import AdminUserService from '@/biz/service/User/AdminUserService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewUserAdminEmployeeForm extends Vue {
  private Service = AdminUserService
  private form = {
    id: RouterService.query('id'),
    username: '',
    password: '',
    nickname: '',
    phone: ''
  }

  private formFields: IFormFields = {
    username: ValidateService.genRule({
      prop: 'username',
      label: '用户名',
      rule: [ValidateService.username]
    }),
    password: ValidateService.genRule({
      prop: 'password',
      label: '密码',
      rule: [ValidateService.loginPassword, this.form.id ? ValidateService.optional : ValidateService.required]
    }),
    nickname: ValidateService.genRule({
      prop: 'nickname',
      label: '昵称',
      rule: [ValidateService.nickname]
    }),
    phone: ValidateService.genRule({
      prop: 'phone',
      label: '手机号',
      rule: [ValidateService.mobile]
    })
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form.username = '13724352543'
      this.form.password = '111111'
      this.form.nickname = '13724352543'
    }
  }
}
