
import { Component, Prop, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import RacingT3ApplyMemberService from '@/biz/service/Racing/RacingT3ApplyMemberService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import ConstService from '@/service/ConstService'

@Component
export default class RacingApplyT3MemberForm extends Vue {
  @Prop()
  params!: { racing_t3_apply_id: number; id: number }

  private Service = RacingT3ApplyMemberService
  private form = {
    id: this.params.id || 0,
    racing_t3_apply_id: this.params.racing_t3_apply_id,
    racing_group_id: Number(RouterService.query('racing_group_id') || 0),
    // 车手
    driver_real_name: '',
    driver_id_card: '',
    driver_phone: '',
    driver_blood: 0,
    // 领航员
    pilot_real_name: '',
    pilot_id_card: '',
    pilot_phone: '',
    pilot_blood: 0,
    // 通用
    racing_number: '',
    car_type: '',
    car_displacement: '',
    car_color: '',
    car_plate: ''
  }

  private currentRacingGroupItem = {
    id: 0,
    is_pilot_required: 1
  }

  private formFields = {} as any

  handleBeforeLoad () {
    return this.getRacingGroupItem()
      .then(() => this.initRule())
  }

  private validateRepeatIdCard (rule: any, value: string, callback: Function) {
    if (!value || this.form.driver_id_card !== this.form.pilot_id_card) {
      callback()
    } else {
      callback(new Error('车手和领航员不能是同一个人'))
    }
  }

  private getRacingGroupItem () {
    return Promise.resolve()
      .then(() => {
        if (this.form.racing_group_id) {
          return RacingGroupService.get(undefined, this.form.racing_group_id)
            .then((data) => {
              Object.assign(this.currentRacingGroupItem, data)
            })
        }
      })
  }

  private initRule () {
    this.formFields = ValidateService.genRules({
      driver_real_name: {
        prop: 'driver_real_name',
        label: '姓名',
        rule: [ValidateService.required, ValidateService.fullname]
      },
      driver_id_card: {
        prop: 'driver_id_card',
        label: '身份证',
        rule: [ValidateService.required, ValidateService.idcard, () => {
          return {
            repeat_id_card: { validator: this.validateRepeatIdCard, trigger: 'blur' }
          }
        }]
      },
      driver_phone: {
        prop: 'driver_phone',
        label: '手机号',
        rule: [ValidateService.required, ValidateService.mobile]
      },
      driver_blood: {
        prop: 'driver_blood',
        label: '血型',
        rule: [ValidateService.requiredNumber],
        options: ConstService.getOptions('', 'blood')
      },
      pilot_real_name: {
        prop: 'pilot_real_name',
        label: '姓名',
        rule: [this.currentRacingGroupItem.is_pilot_required ? ValidateService.required : ValidateService.optional, ValidateService.fullname]
      },
      pilot_id_card: {
        prop: 'pilot_id_card',
        label: '身份证',
        rule: [this.currentRacingGroupItem.is_pilot_required ? ValidateService.required : ValidateService.optional, ValidateService.idcard, () => {
          return {
            repeat_id_card: { validator: this.validateRepeatIdCard, trigger: 'blur' }
          }
        }]
      },
      pilot_phone: {
        prop: 'pilot_phone',
        label: '手机号',
        rule: [this.currentRacingGroupItem.is_pilot_required ? ValidateService.required : ValidateService.optional, ValidateService.mobile]
      },
      pilot_blood: {
        prop: 'pilot_blood',
        label: '血型',
        rule: [this.currentRacingGroupItem.is_pilot_required ? ValidateService.requiredNumber : ValidateService.optional],
        options: ConstService.getOptions('', 'blood')
      },
      racing_number: {
        prop: 'racing_number',
        label: '赛号'
      },
      car_type: {
        prop: 'car_type',
        label: '赛车车型',
        rule: [ValidateService.required, ValidateService.max(20)]
      },
      car_displacement: {
        prop: 'car_displacement',
        label: '赛车排量',
        rule: [ValidateService.required, ValidateService.max(10)]
      },
      car_color: {
        prop: 'car_color',
        label: '赛车颜色',
        rule: [ValidateService.required, ValidateService.max(10)]
      },
      car_plate: {
        prop: 'car_plate',
        label: '车牌号',
        rule: [ValidateService.max(10)]
      }
    })
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form.driver_real_name = '万鑫'
      this.form.driver_id_card = '360103199105290014'
      this.form.driver_phone = '13724352543'
      this.form.driver_blood = 1
      this.form.pilot_real_name = '领航员'
      this.form.pilot_id_card = '513436200004218136'
      this.form.pilot_phone = '13724352543'
      this.form.pilot_blood = 1
      this.form.racing_number = '10'
      this.form.car_type = '车型'
      this.form.car_displacement = '排量'
      this.form.car_color = '白色'
      this.form.car_plate = '粤B123456'
    }
  }
}
