
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class FormTree extends Vue {
  readonly $refs!: {
    tree: any;
  }

  @Prop({ default: () => [] })
  treeList!: any[]

  @Prop({ default: () => [] })
  defaultCheckedKeys!: number[]

  @Prop({ default: 'id' })
  nodeKey!: string

  @Prop({ default: 3 })
  maxDeep!: number

  @Prop({ default: false })
  checkStrictly!: boolean

  public getCheckedNodeIds () {
    const checkedKeys = this.$refs.tree.getCheckedKeys()
    return checkedKeys
  }

  mounted () {
    this.$refs.tree.setCheckedKeys(this.defaultCheckedKeys)
  }
}
