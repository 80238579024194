
import { Component, Ref, Vue } from 'vue-property-decorator'
import MallOrderService from '@/biz/service/Mall/MallOrderService'
import DialogService from '@/service/DialogService/DialogService'
import { ISearchFields } from '@/interface/common'
import ConstService from '@/service/ConstService'
import RouterService from '@/service/RouterService'

@Component
export default class MallOrderIndex extends Vue {
  @Ref()
  tableElement!: any

  private MallOrderService = MallOrderService
  private ConstService = ConstService

  private tabs = ConstService.getOptions('MallStoreOrder', 'order_status')
  private order_status = RouterService.query('order_status') || ''

  private searchFields: ISearchFields[] = [
    {
      name: 'order_id',
      display_name: '订单号',
      valueType: 'int'
    },
    {
      name: 'user_id',
      display_name: '用户ID',
      valueType: 'int'
    },
    {
      name: 'order_status',
      display_name: '订单状态',
      valueType: 'intOptions',
      options: MallOrderService.getOptions('order_status')
    },
    {
      name: 'pay_status',
      display_name: '支付状态',
      valueType: 'intOptions',
      options: MallOrderService.getOptions('pay_status')
    },
    {
      name: 'payment',
      display_name: '支付方式',
      valueType: 'intOptions',
      options: MallOrderService.getOptions('payment')
    },
    {
      name: 'created_at',
      display_name: '下单时间',
      valueType: 'dateTime'
    },
    {
      name: 'paid_at',
      display_name: '支付时间',
      valueType: 'dateTime'
    }
  ]

  private handleLoad (params: any) {
    return MallOrderService.index({
      ...params,
      order_status: Number(this.order_status || 0)
    })
  }

  private handleExpress (row: { id: number; express_type: string; express_no: string; order_status: number }) {
    return DialogService.show(require('./components/Express.vue').default, {
      mall_store_order_id: row.id,
      express_type: row.express_type,
      express_no: row.express_no,
      order_status: row.order_status,
      width: '600px'
    })
      .then(() => {
        this.tableElement.reload()
      })
  }

  private handleExpressInfo (row: { id: number }) {
    return DialogService.show(require('./components/ExpressInfo.vue').default, {
      mall_store_order_id: row.id,
      width: '600px'
    })
  }

  private handleShowExpress (row: { order_status: number }) {
    return row.order_status !== MallOrderService.getOptionsValue('order_status', 1, '待付款')
  }

  private handleShowExpressInfo (row: { express_no: string }) {
    return !!row.express_no
  }

  private handleTabClick (res: any) {
    RouterService.replace('', { ...this.$route.query, order_status: res.name, page: 1 })
  }
}
