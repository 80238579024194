
import ConstService from '@/service/ConstService'
import { Component, Vue } from 'vue-property-decorator'
import RallyResultTotal from './components/RallyResultTotal.vue'
import T3ResultTotal from './components/T3ResultTotal.vue'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    RallyResultTotal,
    T3ResultTotal
  }
})
export default class ViewRacingPrintStart extends Vue {
  private current = ''

  created () {
    this.current = `${ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)}ResultTotal`
  }
}
