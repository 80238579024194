import RouterService from '@/service/RouterService'
import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class UserControlService extends BaseAbstract {
  name = 'UserControl'
  show (id: number | string) {
    return IOService.get(`user_control/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user_control/${RouterService.query('id')}`, params)
  }
}

export default new UserControlService()
