import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class UserBillService extends BaseAbstract {
  name = 'UserBill'
  index (params: { page: number; user_id: number }) {
    return IOService.get('user_bill', params)
  }
}

export default new UserBillService()
