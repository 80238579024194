import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class MallClassifySpecOptionService extends BaseAbstract {
  displayName = '规格参数'
  name = 'MallClassifySpecOption'
  index () {
    return IOService.get('mall_classify_spec_option', {
      mall_classify_spec_id: RouterService.query('mall_classify_spec_id')
    })
  }

  store (params: object) {
    return IOService.post('mall_classify_spec_option', params)
  }

  show (id: number) {
    return IOService.get(`mall_classify_spec_option/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`mall_classify_spec_option/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`mall_classify_spec_option/${id}`)
  }
}

export default new MallClassifySpecOptionService()
