
import ConstService from '@/service/ConstService'
import DialogService from '@/service/DialogService/DialogService'
import { Component, Ref, Vue } from 'vue-property-decorator'
import ActionButtons from '../components/ActionButtons.vue'
import RouterService from '@/service/RouterService'
import RacingT3ApplyService from '@/biz/service/Racing/RacingT3ApplyService'
import RacingT3ApplyMemberService from '@/biz/service/Racing/RacingT3ApplyMemberService'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    ActionButtons
  }
})
export default class RacingApplyT3Index extends Vue {
  @Ref()
  tableElement!: any

  private Service = RacingT3ApplyService
  private RacingT3ApplyMemberService = RacingT3ApplyMemberService
  private racing_group_id = RouterService.query('racing_group_id')
  private maxHeight = 0
  private ConstService = ConstService
  private wechatAmountTotal = 0
  private coinAmountTotal = 0
  private cashAmountTotal = 0

  private handleLoad (params: any) {
    return this.Service.index(params)
      .then((res) => {
        this.wechatAmountTotal = this.getPaymentList(res.data, RacingT3ApplyService.getOptionsValue('payment', 1, '微信支付'))
          .reduce((acc: any, item: any) => {
            acc += item._racing_order.wechat_amount
            return acc
          }, 0)
        this.coinAmountTotal = this.getPaymentList(res.data, RacingT3ApplyService.getOptionsValue('payment', 2, '赛币支付'))
          .reduce((acc: any, item: any) => {
            acc += item._racing_order.coin_amount
            return acc
          }, 0)
        this.cashAmountTotal = this.getPaymentList(res.data, RacingT3ApplyService.getOptionsValue('payment', 4, '线下支付'))
          .reduce((acc: any, item: any) => {
            acc += item._racing_order.cash_amount
            return acc
          }, 0)
        return res
      })
  }

  private handleReload () {
    this.tableElement.reload()
  }

  private handleView (row: any) {
    return DialogService.show(require('../../RacingPrint/Apply.vue').default, {
      top: '7vh',
      width: '210mm',
      row
    })
  }

  private handleCashPay (row: { id: number }) {
    return this.$confirm('您确认该用户已线下支付了吗？')
      .then(() => this.Service.cashPay(row.id))
      .then((res) => {
        this.handleReload()
        return res
      })
  }

  private getPaymentList (list: any[], payment: number) {
    return list.filter((item: any) => item._racing_order && item._racing_order.payment === payment)
  }

  private handleShowCashPay (row: { is_pay: number }) {
    return row.is_pay === 0
  }

  private handleShowAddMember (row: { racing_t3_apply_member: any[] }) {
    if (RacingService.currentData.racing_type === RacingService.getOptionsValue('racing_type', 4, 'T2')) {
      return row.racing_t3_apply_member.length < 2
    } else {
      return row.racing_t3_apply_member.length < 3
    }
  }

  private handleAddMember (row: { id: number }) {
    return DialogService.show(require('./MemberForm.vue').default, {
      top: '7vh',
      width: '210mm',
      racing_t3_apply_id: row.id
    })
      .then(() => this.handleReload())
  }

  private handleEditMember (id: number) {
    return DialogService.show(require('./MemberForm.vue').default, {
      top: '7vh',
      width: '210mm',
      id
    })
      .then(() => this.handleReload())
  }

  private handleDeleteMember (id: number) {
    return RacingT3ApplyMemberService.destroy(id)
      .then(() => this.handleReload())
  }

  mounted () {
    if (this.tableElement) {
      this.maxHeight = document.body.clientHeight - (this.tableElement.$el.offsetTop + 170)
    }
  }
}
