
import { Component, Ref, Vue } from 'vue-property-decorator'
import RacingResultService from '@/biz/service/Racing/RacingResultService'
import ActionButtons from '../components/ActionButtons.vue'

@Component({
  components: {
    ActionButtons
  }
})
export default class ViewRacingResultRallyIndex extends Vue {
  @Ref()
  tableElement!: any

  private Service = RacingResultService
  private maxHeight = 0

  private handleLoad () {
    return RacingResultService.rallyIndex()
  }

  private handleReload () {
    this.tableElement.reload()
  }

  mounted () {
    if (this.tableElement) {
      this.maxHeight = document.body.clientHeight - (this.tableElement.$el.offsetTop + 170)
    }
  }
}
