
import ConstService from '@/service/ConstService'
import { Component, Vue } from 'vue-property-decorator'
import AbEmptyResult from './components/AbEmptyResult.vue'
import ManyEmptyResult from './components/ManyEmptyResult.vue'
import RallyEmptyResult from './components/RallyEmptyResult.vue'
import T3EmptyResult from './components/T3EmptyResult.vue'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    AbEmptyResult,
    ManyEmptyResult,
    RallyEmptyResult,
    T3EmptyResult
  }
})
export default class ViewRacingPrintEmptyResult extends Vue {
  private current = ''

  created () {
    this.current = `${ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)}EmptyResult`
  }
}
