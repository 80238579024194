
import TableMixins from './TableMixins'
import { Component, Mixins } from 'vue-property-decorator'
import CarouselService from '@/service/CarouselService/CarouselService'
import { getDeepValue } from '@/service/ToolsService'

@Component
export default class TableImages extends Mixins(TableMixins) {
  private innerValue = []

  private handleOpenImages (row: any) {
    const images = this.getValue(row)
    if (images.length > 0) {
      CarouselService.open(images)
    }
  }

  private getValue (row: any) {
    return getDeepValue(this.prop, row) || []
  }
}
