
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ServiceCarousel extends Vue {
  $refs!: {
    imgs: Element[];
  }

  @Prop({ default: true })
  autoplay!: boolean

  @Prop({ default: 0 })
  index!: number

  private dialogVisible = false
  private list: string[] = []
  private height = ''

  public open (list: string[]) {
    this.list = list
    this.dialogVisible = true
  }

  close () {
    this.dialogVisible = false
    setTimeout(() => {
      this.$destroy()
    }, 300)
  }

  private handleImgLoad (index: number) {
    if (index === this.index) {
      this.handleChange(this.index)
    }
  }

  private handleChange (index: number) {
    this.height = (this.$refs.imgs[index] as any).$el.clientHeight + 'px'
  }
}
