
import ConstService from '@/service/ConstService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import GeneralApplyList from './components/GeneralApplyList.vue'
import RallyApplyList from './components/RallyApplyList.vue'
import T3ApplyList from './components/T3ApplyList.vue'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    GeneralApplyList,
    RallyApplyList,
    T3ApplyList
  }
})
export default class RacingPrintApplyList extends Vue {
  @Prop()
  params!: { row: any }

  private current = ''

  created () {
    if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'Rally') {
      this.current = 'RallyApplyList'
    } else if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'T3') {
      this.current = 'T3ApplyList'
    } else {
      this.current = 'GeneralApplyList'
    }
  }
}
