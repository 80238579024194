import Vue from 'vue'
import ServiceDialog from './Dialog.vue'
import Prompt from './Prompt.vue'

export interface DialogParams {
  hideCloseBtn?: boolean;
  width?: string;
  top?: string;
  [key: string]: any;
}

class DialogService {
  instance = null as any
  show (component: any, params: DialogParams = {}) {
    const el = document.body.appendChild(document.createElement('div'))
    const Constructor = Vue.extend(ServiceDialog)
    this.instance = new Constructor({
      propsData: {
        component,
        params,
        Service: this
      }
    }).$mount(el)
    return this.instance.open()
  }

  prompt (params: { title?: string } = {}) {
    const el = document.body.appendChild(document.createElement('div'))
    const Constructor = Vue.extend(Prompt)
    const instance = new Constructor({
      propsData: {
        params
      }
    }).$mount(el)
    return (instance as any).open()
  }

  done (res: any) {
    this.instance && this.instance.handleDone(res)
    this.instance = null
  }
}

export default new DialogService()
