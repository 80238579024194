
import { Component, Vue } from 'vue-property-decorator'
import NoticeService from '@/biz/service/System/NoticeService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import FormEditor from '@/components/Form/FormEditor.vue'

@Component({
  components: {
    FormEditor
  }
})
export default class ViewSystemVersionForm extends Vue {
  private Service = NoticeService
  private form = {
    id: RouterService.query('id'),
    title: '',
    description: ''
  }

  private formFields: IFormFields = {
    title: ValidateService.genRule({
      prop: 'title',
      label: '标题',
      rule: [ValidateService.required, ValidateService.max(120)]
    }),
    description: ValidateService.genRule({
      prop: 'description',
      label: '详情',
      rule: [ValidateService.required]
    })
  }
}
