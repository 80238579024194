
import { PromiseResult } from '@/service/IOService'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ButtonDelete extends Vue {
  @Prop({ default: () => () => Promise.resolve })
  onClick!: Function

  @Prop({ default: () => () => Promise.resolve })
  onClickAfter!: Function

  @Prop({ default: 'warning' })
  type!: string

  @Prop({ default: 'small' })
  size!: string

  @Prop({ default: '您真的要删除吗' })
  title!: string

  @Prop()
  icon!: string

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ default: false })
  plain!: boolean

  private loading = false
  private visible = false

  private handleClick () {
    if (this.loading) return
    this.loading = true
    this.onClick()
      .then((res: PromiseResult) => {
        if (res && res.message) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          })
        }
        this.handleloaded()
        return this.onClickAfter()
      })
      .catch((res: PromiseResult) => {
        if (res && res.message) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          })
        }
        this.handleloaded()
      })
  }

  private handleloaded () {
    this.visible = false
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }
}
