import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingDynamicService extends BaseAbstract {
  displayName = '动态'
  name = 'RacingDynamic'

  index (params: { page: number; dynamic_type?: number }) {
    return IOService.get('racing_dynamic', { ...params, racing_id: RouterService.query('racing_id') })
  }

  store (params: object) {
    return IOService.post('racing_dynamic', {
      ...params,
      racing_id: RouterService.query('racing_id')
    })
  }

  show (id: number) {
    return IOService.get(`racing_dynamic/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing_dynamic/${params.id}`, {
      racing_id: RouterService.query('racing_id'),
      ...params
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_dynamic/${id}`)
  }
}

export default new RacingDynamicService()
