
import FormMixins from './FormMixins'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import ConstService from '@/service/ConstService'

@Component
export default class FormCascader extends Mixins(FormMixins) {
  @Ref()
  formItemElement!: { clearValidate: Function }

  @Prop()
  type!: string

  private props = {
    expandTrigger: 'hover',
    label: 'display_name',
    value: 'id',
    emitPath: false
  }

  private validateCityRequired (rule: any, value: number, callback: Function) {
    if (this.innerValue) {
      callback()
    } else {
      const text = `请选择${this.field.label}`
      callback(text)
    }
  }

  private handleChange (value: string | number) {
    setTimeout(() => {
      this.formItemElement.clearValidate()
    }, 0)
    this.$emit('change', value)
  }

  created () {
    if (this.type === 'city' || this.type === 'nationality') {
      this.field.props = {
        label: 'name',
        value: 'id'
      }
      if (this.type === 'city') {
        this.field.options = ConstService.getAreaOptions()
      } else if (this.type === 'nationality') {
        this.field.options = ConstService.getNationalityOptions()
      }
      const isRequired = (this.field.rule || []).find((res) => res.required)
      if (isRequired) {
        isRequired.type = 'number'
        this.field.rule = (this.field.rule || []).concat([{ validator: this.validateCityRequired }])
      }
    }
    // this.innerValue = this.innerValue ? Number(this.innerValue) : 0
    Object.assign(this.props, this.field.props)
  }
}
