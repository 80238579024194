
import { IFormFieldItem } from '@/interface/common'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class FormInput extends Vue {
  @Prop()
  value!: string

  @Prop()
  field!: IFormFieldItem

  @Prop()
  labelWidth!: string
}
