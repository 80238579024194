
import { Component, Vue } from 'vue-property-decorator'
import RacingService from '@/biz/service/Racing/RacingService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintGeneralEmptyApply extends Vue {
  private list = [] as any[]
  private RacingService = RacingService

  private handleTableLoad () {
    return Promise.resolve([{
      racing_group_id: ' ',
      racing_number: ' '
    }])
  }

  private handleTableLoad2 () {
    return Promise.resolve([{
      real_name: ' ',
      phone: ' ',
      id_card: ' ',
      blood: ' ',
      car_type: ' ',
      displacement: ' ',
      car_weight: ' ',
      car_color: ' '
    }])
  }
}
