import ConstService from '@/service/ConstService'
import DialogService, { DialogParams } from '@/service/DialogService/DialogService'

export default abstract class BaseAbstract {
  abstract name: string
  displayName!: string
  controllerName!: string

  public getModelName () {
    return this.name
  }

  public getControllerName () {
    return this.controllerName || this.name
  }

  public getPermissionName (name: string) {
    return name.includes('@') ? name : this.getControllerName() + 'Controller@' + name
  }

  public getOptions (fieldName: string) {
    return ConstService.getOptions(this.name, fieldName)
  }

  // eslint-disable-next-line
  public getOptionsValue (fieldName: string, value: number, _display_name?: string) {
    const options = ConstService.getOptions(this.name, fieldName)
    const item = options.find((res) => res.value === value)
    return item ? item.value : 0
  }

  public getOptionsLabel (fieldName: string, value: number) {
    const options = ConstService.getOptions(this.name, fieldName)
    const item = options.find((res) => res.value === value)
    return item ? item.display_name : ''
  }

  // eslint-disable-next-line
  public getStatusValue (value: number, _display_name?: string) {
    return this.getOptionsValue('status', value, _display_name)
  }

  public getStatusLabel (value: number) {
    return this.getOptionsLabel('status', value)
  }

  public dialog (component: any, params: DialogParams) {
    return DialogService.show.call(this, component, params)
  }
}
