
import { Component, Vue } from 'vue-property-decorator'
import EnterprisePositionService from '@/biz/service/Enterprise/EnterprisePositionService'

@Component
export default class ViewEnterprisePositionIndex extends Vue {
  private Service = EnterprisePositionService

  private handlePermissions (v: { id: number }) {
    return EnterprisePositionService.dialog(require('@/biz/components/components/Permissions.vue').default, {
      id: v.id,
      Service: EnterprisePositionService
    })
  }
}
