
import { Component, Vue } from 'vue-property-decorator'
import GeneralApply from './General/Form.vue'
import RallyApply from './Rally/Form.vue'
import T3Apply from './T3/Form.vue'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'

@Component({
  components: {
    GeneralApply,
    RallyApply,
    T3Apply
  }
})
export default class RacingApplyForm extends Vue {
  private current = ''

  created () {
    const name = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
    if (name === 'Rally') {
      this.current = name + 'Apply'
    } else if (name === 'T3') {
      this.current = name + 'Apply'
    } else {
      this.current = 'GeneralApply'
    }
  }
}
