
import { Component, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'
import RacingT3ApplyService from '@/biz/service/Racing/RacingT3ApplyService'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintRallyInsure extends Vue {
  private list = [] as any[]

  private handleLoad () {
    return RacingT3ApplyService.insureIndex()
      .then((res) => {
        this.list = res.data.reduce((acc: any[], row: any) => {
          return acc.concat(row.racing_t3_apply_member)
        }, [])
      })
  }
}
