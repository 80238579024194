
import { Component, Vue } from 'vue-property-decorator'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'
import GeneralInsure from './components/GeneralInsure.vue'
import RallyInsure from './components/RallyInsure.vue'
import T3Insure from './components/T3Insure.vue'

@Component({
  components: {
    GeneralInsure,
    RallyInsure,
    T3Insure
  }
})
export default class ViewRacingPrintInsure extends Vue {
  private current = ''

  created () {
    if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'Rally') {
      this.current = 'RallyInsure'
    } else if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'T3') {
      this.current = 'T3Insure'
    } else {
      this.current = 'GeneralInsure'
    }
  }
}
