
import { Component, Vue } from 'vue-property-decorator'
import NotifyService from '@/biz/service/Notify/NotifyService'
import ConstService from '@/service/ConstService'
import { ISearchFields } from '@/interface/common'

@Component
export default class ViewUserMemberNotifyIndex extends Vue {
  private Service = NotifyService
  private ConstService = ConstService

  private searchFields: ISearchFields[] = [
    {
      name: 'user_id',
      display_name: '发送人ID',
      valueType: 'string'
    }
  ]
}
