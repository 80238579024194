
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import AdService from '@/biz/service/System/AdService'

@Component
export default class ViewOtherWeChatNotifyTemplateForm extends Vue {
  private Service = AdService
  private form = {
    id: RouterService.query('id'),
    image: '',
    position: '',
    url: '',
    sort: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    image: {
      prop: 'image',
      label: '图片',
      rule: [ValidateService.required]
    },
    position: {
      prop: 'position',
      label: '位置',
      rule: [ValidateService.requiredNumber]
    },
    url: {
      prop: 'url',
      label: '链接地址',
      rule: [ValidateService.required]
    },
    sort: {
      prop: 'sort',
      label: '顺序'
    }
  })
}
