import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class OptionConfigService extends BaseAbstract {
  displayName = '配置'
  name = 'Config'
  controllerName = 'OptionsConfig'
  index (params: object) {
    return IOService.get('option_config', {
      ...params,
      guard_name: 'options'
    })
  }

  store (params: object) {
    return IOService.post('option_config', params)
  }

  show (id: number | string) {
    return IOService.get(`option_config/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`option_config/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`option_config/${id}`)
  }
}

export default new OptionConfigService()
