
import { Component, Vue } from 'vue-property-decorator'
import OptionConfigService from '@/biz/service/System/OptionConfigService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewSystemOptionConfigForm extends Vue {
  private Service = OptionConfigService
  private form = {
    id: RouterService.query('id'),
    name: '',
    display_name: '',
    guard_name: 'options'
  }

  private formFields: IFormFields = {
    name: ValidateService.genRule({
      prop: 'name',
      label: '标识',
      disabled: !!this.form.id,
      rule: [ValidateService.required, ValidateService.max(60)]
    }),
    display_name: ValidateService.genRule({
      prop: 'display_name',
      label: '名称',
      rule: [ValidateService.required, ValidateService.max(60)]
    })
  }
}
