import CacheService from './CacheService'
import areaOptions from '@/assets/json/area'
import nationalityOptions from '@/assets/json/nationality'
import { codesToPaths, getCodeParents } from './ToolsService'

const RacingTypeNames = {
  1: 'ab',
  2: 'many',
  3: 'rally',
  4: 't2',
  5: 't3'
} as {
  [key: number]: string;
}

const RacingTypeNames2 = {
  1: 'Ab',
  2: 'Many',
  3: 'Rally',
  4: 'T2',
  5: 'T3'
} as {
  [key: number]: string;
}

export interface OptionBoolItem {
  display_name: string;
  value: number;
  [key: string]: any;
}

export interface OptionItem {
  id?: string;
  config_id?: number;
  display_name: string;
  value: number;
  disabled?: boolean;
  [key: string]: any;
}

export interface ConfigItem {
  id: string;
  name: string;
  display_name: string;
  guard_name: string;
  options: OptionItem[];
}

class ConstService {
  getValue (name: string) {
    const configs = CacheService.config.get('options') || []
    const item = configs.find((res: { name: string }) => res.name === name)
    return item ? item.value : ''
  }

  getOptions (className: string, field: string) {
    const configs: ConfigItem[] = CacheService.config.get('options') || []
    let config = configs.find((res) => res.name === className + ':' + field)
    if (!config) {
      config = configs.find((res) => res.name === '_global:' + field)
    }
    return config ? config.options : []
  }

  getColor (name: string) {
    switch (name) {
      case 'success':
        return '#67C23A'
      case 'warning':
        return '#E6A23C'
      case 'error':
        return '#F56C6C'
      case 'click':
        return '#409EFF'
      default:
        if (name.includes('#')) {
          return name
        } else {
          return '#303133'
        }
    }
  }

  getAreaOptions () {
    return JSON.parse(JSON.stringify(areaOptions))
  }

  getNationalityOptions () {
    return JSON.parse(JSON.stringify(nationalityOptions))
  }

  getNationalityOptionsLabel (code: number) {
    const codes = getCodeParents(code)
    const paths = codesToPaths(codes, this.getNationalityOptions())
    return paths.length ? paths[paths.length - 1].name : '--'
  }

  getBoolOptions () {
    return [
      {
        value: 0,
        display_name: '否'
      },
      {
        value: 1,
        display_name: '是'
      }
    ]
  }

  getRacingTypeName (racing_type: number) {
    racing_type = Number(racing_type)
    return RacingTypeNames[racing_type]
  }

  getToUpperCaseRacingTypeName (racing_type: number) {
    racing_type = Number(racing_type)
    if (racing_type === 4) {
      racing_type = 5
    }
    return RacingTypeNames2[racing_type]
  }
}

export default new ConstService()
