
import { Component, Vue } from 'vue-property-decorator'
import AbResult from './Ab/Index.vue'
import ManyResult from './Many/Index.vue'
import RallyResult from './Rally/Index.vue'
import T3Result from './T3/Index.vue'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'
import RouterService from '@/service/RouterService'

@Component({
  components: {
    AbResult,
    ManyResult,
    RallyResult,
    T3Result
  }
})
export default class RacingResultIndex extends Vue {
  private current = ''
  private racing_round_id = RouterService.query('racing_round_id')

  created () {
    const name = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
    this.current = name + 'Result'
  }
}
