
import { Component, Vue } from 'vue-property-decorator'
import RacingNoticeService from '@/biz/service/Racing/RacingNoticeService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import FormEditor from '@/components/Form/FormEditor.vue'

@Component({
  components: {
    FormEditor
  }
})
export default class RacingNoticeForm extends Vue {
  private Service = RacingNoticeService
  private form = {
    id: RouterService.query('id'),
    title: '',
    description: ''
  }

  private formFields = ValidateService.genRules({
    title: {
      prop: 'title',
      label: '公告标题',
      rule: [ValidateService.required, ValidateService.max(120)]
    },
    description: {
      prop: 'description',
      label: '公告详情',
      rule: [ValidateService.required]
    }
  })

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form.title = '公告标题'
      this.form.description = '公告内容'
    }
  }
}
