
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import UserService from '@/service/UserService'

@Component
export default class Login extends Vue {
  private form = {
    username: '',
    password: ''
  }

  private beian = '粤ICP备17071031号-1'

  private handleSubmit () {
    return UserService.login(this.form)
      .then(() => {
        RouterService.replace('/')
      })
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form.username = 'root'
      this.form.password = '111111'
    }
    if (location.href.includes('saichechina.com')) {
      this.beian = '粤ICP备17071031号-2'
    }
  }
}
