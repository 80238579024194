
import { Component, Vue } from 'vue-property-decorator'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingService from '@/biz/service/Racing/RacingService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'
import ConstService from '@/service/ConstService'
import RacingT3ApplyService from '@/biz/service/Racing/RacingT3ApplyService'
import RouterService from '@/service/RouterService'

@Component({
  directives: {
    print
  }
})
export default class RacingPrintT3ApplyList extends Vue {
  private list = [] as any[]
  private RacingService = RacingService
  private ConstService = ConstService
  private racingGroupList = [] as any[]
  private racingGroupItem = {
    id: 0,
    display_name: ''
  }

  private racingGroupProps = {
    label: 'display_name',
    value: 'id'
  }

  private handleLoad () {
    return RacingGroupService.getList()
      .then((racingGroupList: any[]) => {
        this.racingGroupList = racingGroupList
        this.racingGroupItem = this.racingGroupList.find((res) => res.id === Number(RouterService.query('racing_group_id')))
        return RacingT3ApplyService.printIndex()
          .then((res) => {
            this.list = res.data
          })
      })
  }
}
