
import { Component, Vue } from 'vue-property-decorator'
import { ISearchFields } from '@/interface/common'
import RouterService from '@/service/RouterService'
import AdService from '@/biz/service/System/AdService'

@Component
export default class ViewOtherWeChatNotifyTemplateIndex extends Vue {
  private Service = AdService
  private RouterService = RouterService

  private searchFields: ISearchFields[] = [
    {
      name: 'template_id',
      display_name: '微信模板ID',
      valueType: 'string'
    }
  ]
}
