import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingT3ApplyMemberService extends BaseAbstract {
  displayName = '成员'
  name = 'RacingT3ApplyMember'
  store (params: object) {
    return IOService.post('racing_t3_apply_member', {
      ...params,
      racing_id: RouterService.query('racing_id')
    })
  }

  show (id: number) {
    return IOService.get(`racing_t3_apply_member/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing_t3_apply_member/${params.id}`, {
      ...params,
      racing_id: RouterService.query('racing_id')
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_t3_apply_member/${id}`)
  }
}

export default new RacingT3ApplyMemberService()
