
import { Component, Prop, Vue } from 'vue-property-decorator'
import UserBillService from '@/biz/service/User/UserBillService'

@Component
export default class ComponentUserBill extends Vue {
  @Prop()
  params!: { user_id: number }

  private UserBillService = UserBillService

  private handleLoad (params: any) {
    return UserBillService.index({
      ...params,
      user_id: this.params.user_id
    })
  }
}
