
import { IFormFieldItem } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import { Component, Mixins } from 'vue-property-decorator'
import SearchMixins from './SearchMixins'

@Component
export default class SearchBigIntValue extends Mixins(SearchMixins) {
  private field: IFormFieldItem = ValidateService.genRule({
    prop: 'value',
    label: '值',
    rule: [ValidateService.required]
  })
}
