
import { Component, Vue } from 'vue-property-decorator'
import EnterpriseService from '@/biz/service/Enterprise/EnterpriseService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewEnterpriseForm extends Vue {
  private Service = EnterpriseService
  private form = {
    id: RouterService.query('enterprise_id'),
    logo: '',
    cover_img: '',
    display_name: '',
    company_name: '',
    business_license: '',
    city: 0,
    address: '',
    intro: ''
  }

  private formFields = ValidateService.genRules({
    logo: {
      prop: 'logo',
      label: '企业LOGO',
      rule: [ValidateService.required]
    },
    cover_img: {
      prop: 'cover_img',
      label: '封面图',
      rule: [ValidateService.required]
    },
    display_name: {
      prop: 'display_name',
      label: '企业简称',
      rule: [ValidateService.required, ValidateService.max(30)]
    },
    company_name: {
      prop: 'company_name',
      label: '企业全称',
      rule: [ValidateService.required, ValidateService.max(60)]
    },
    business_license: {
      prop: 'business_license',
      label: '营业执照',
      rule: [ValidateService.required, ValidateService.max(18)]
    },
    city: {
      prop: 'city',
      label: '所在城市',
      rule: [ValidateService.required]
    },
    address: {
      prop: 'address',
      label: '详细地址',
      rule: [ValidateService.required, ValidateService.max(60)]
    },
    intro: {
      prop: 'intro',
      label: '手机号',
      rule: [ValidateService.required, ValidateService.max(255)]
    }
  })

  created () {
    if (!RouterService.query('id') && this.form.id) {
      RouterService.replace('', { id: this.form.id, enterprise_id: this.form.id })
    }
    if (process.env.NODE_ENV === 'development') {
      this.form.display_name = '赛车视界'
      this.form.company_name = '赛车视界（深圳）科技发展有限公司'
      this.form.business_license = '91440300MA5DRKW54D'
      this.form.city = 440305
      this.form.address = '前海深港合作区前湾一路1号A栋201室'
      this.form.intro = '赛车视界成立于2016年，经营范围以汽车公园的项目规划、赛道设计、赛事服务为体系，由公司开发的《赛车视界》APP及小程序是国内最早的一款赛事管理系统，引领了国内汽车赛事由传统到智能化管理的跨时代转型，被业内公誉为最专业的汽车赛事服务机构。'
    }
  }
}
