
import { Component, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'
import RacingApplyService from '@/biz/service/Racing/RacingApplyService'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintRallyInsure extends Vue {
  private list = [] as any[]

  private handleLoad () {
    return RacingApplyService.insureIndex()
      .then((res) => {
        this.list = res.data
      })
  }
}
