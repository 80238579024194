
import { Component, Vue } from 'vue-property-decorator'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import IOService from '@/service/IOService'
import UserService from '@/service/UserService'

@Component
export default class ViewUserAdminEmployeeForm extends Vue {
  private form = {
    password: '',
    nickname: ''
  } as {
    [key: string]: any;
  }

  private formFields: IFormFields = {
    password: ValidateService.genRule({
      prop: 'password',
      label: '密码',
      rule: [ValidateService.loginPassword, ValidateService.optional]
    }),
    nickname: ValidateService.genRule({
      prop: 'nickname',
      label: '昵称',
      rule: [ValidateService.nickname]
    })
  }

  private handleLoad () {
    return IOService.get(`employee/${UserService.info.id}`)
      .then((res) => {
        Object.keys(this.form).forEach((key: string) => {
          if (!(res.data[key] === null || res.data[key] === undefined)) {
            this.form[key] = res.data[key]
          }
        })
      })
  }

  private handleSubmit () {
    return IOService.post('auth/updateMySelf', this.form)
      .then((res) => {
        this.$emit('done')
        return res
      })
  }
}
