
import { Component, Vue } from 'vue-property-decorator'
import ParamConfigService from '@/biz/service/System/ParamConfigService'
import { ISearchFields } from '@/interface/common'

@Component
export default class ViewSystemParamConfigIndex extends Vue {
  private Service = ParamConfigService

  private searchFields: ISearchFields[] = [
    {
      name: 'name',
      display_name: '标识',
      valueType: 'string'
    },
    {
      name: 'display_name',
      display_name: '名称',
      valueType: 'string'
    }
  ]
}
