
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import { IFormFields } from '@/interface/common'
import RacingDynamicCheckService from '@/biz/service/Info/RacingDynamicCheckService'

@Component
export default class RacingDynamicCheckForm extends Vue {
  private Service = RacingDynamicCheckService
  private form = {
    id: RouterService.query('id'),
    status: 0,
    refuse_reason: '',
    params: {
      description: '',
      images: []
    }
  }

  private formFields: IFormFields = {}

  private initFormFields () {
    return ValidateService.genRules({
      description: {
        prop: 'params.description',
        label: '内容',
        rule: [ValidateService.max(255)]
      },
      images: {
        prop: 'params.images',
        label: '图集',
        rule: [ValidateService.maxItem(9)]
      },
      status: {
        prop: 'status',
        label: '状态'
      },
      refuse_reason: {
        prop: 'refuse_reason',
        label: '拒绝原因',
        rule: [ValidateService.max(255), () => {
          return {
            refuse_reason: {
              required: this.form.status === this.Service.getStatusValue(4, '人工拒绝'),
              validator: (rule: any, value: string, callback: Function) => {
                if (this.form.status === this.Service.getStatusValue(4, '人工拒绝') && !value) {
                  callback(new Error('请输入拒绝原因'))
                }
                callback()
              },
              trigger: 'blur'
            }
          }
        }]
      }
    })
  }

  private handleChange () {
    return Promise.resolve()
      .then(() => {
        this.formFields = this.initFormFields()
      })
  }
}
