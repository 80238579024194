
import TableMixins from './TableMixins'
import { Component, Mixins, Prop, Inject } from 'vue-property-decorator'
import { getDeepValue } from '@/service/ToolsService'
import ConstService, { OptionItem, OptionBoolItem } from '@/service/ConstService'
import BaseAbstract from '@/abstract/BaseAbstract'

@Component
export default class TableOptions extends Mixins(TableMixins) {
  @Prop({
    default: () => {
      return {
        value: 'value',
        label: 'display_name'
      }
    }
  })
  declare props: { label: string; value: any }

  @Prop({ default: false })
  bool!: boolean

  @Prop()
  colors!: { [key: string]: string }

  @Prop()
  options!: OptionItem[]

  @Inject('tableService')
  tableService!: BaseAbstract

  private innerOptions = [] as (OptionItem | OptionBoolItem)[]

  private getValue (row: any) {
    let value = getDeepValue(this.prop, row)
    value = this.bool && !value ? 0 : value
    const item = this.innerOptions.find((res) => res[this.props.value] === value)
    return item ? item[this.props.label] : value
  }

  private getColor (row: any) {
    let value = getDeepValue(this.prop, row)
    value = this.bool && !value ? 0 : value
    const item = this.innerOptions.find((res) => res[this.props.value] === value)
    if (item && item.color) {
      return ConstService.getColor(item.color)
    }
    if (this.bool) {
      const innerColors = this.colors || { 1: 'success', 0: 'warning' }
      if (!innerColors[value]) {
        console.warn('该字段可能不是布尔型')
      }
      return ConstService.getColor(innerColors[value])
    } else {
      if (this.colors && this.colors[value]) {
        return ConstService.getColor(this.colors[value])
      }
    }
    return ConstService.getColor('')
  }

  created () {
    if (this.options) {
      this.innerOptions = JSON.parse(JSON.stringify(this.options))
    } else {
      if (this.bool) {
        this.innerOptions = ConstService.getBoolOptions()
      } else {
        if (this.tableService) {
          this.innerOptions = this.tableService.getOptions(this.prop)
        } else {
          this.innerOptions = ConstService.getOptions('', this.prop)
        }
      }
    }
  }
}
