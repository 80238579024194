
import { Component, Vue } from 'vue-property-decorator'
import RacingResultService from '@/biz/service/Racing/RacingResultService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import { arrayChunk } from '@/service/ToolsService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintManyResult extends Vue {
  private RacingGroupService = RacingGroupService
  private RacingRoundService = RacingRoundService
  private list = [] as any[][]

  private handleLoad () {
    return RacingResultService.printResultTable()
      .then((res) => {
        this.list = arrayChunk(res.data, 22)
      })
  }

  private handleTableLoad (index: number) {
    return Promise.resolve(this.list[index])
  }
}
