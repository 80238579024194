
import { Component, Vue } from 'vue-property-decorator'
import RacingResultService from '@/biz/service/Racing/RacingResultService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import FormInputResult from '@/components/Form/FormInputResult.vue'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import FormResultPunish from '@/components/Form/FormResultPunish.vue'

@Component({
  components: {
    FormInputResult,
    FormResultPunish
  }
})
export default class ViewRacingResultManyForm extends Vue {
  private Service = RacingResultService
  private RacingGroupService = RacingGroupService
  private form = {
    id: RouterService.query('id'),
    racing_number: '',
    real_name: '',
    team_name: '',
    car_type: '',
    displacement: '',
    car_color: '',
    car_weight: '',
    group_sort: '',
    sort: '',
    result: '',
    result_f: '',
    result_sort: '',
    punish_record: [],
    _ms_number: RacingGroupService.currentData.ms_number
  }

  private formFields = ValidateService.genRules({
    real_name: {
      prop: 'real_name',
      label: '姓名',
      rule: [ValidateService.required, ValidateService.fullname]
    },
    racing_number: {
      prop: 'racing_number',
      label: '赛号',
      rule: [ValidateService.required]
    },
    team_name: {
      prop: 'team_name',
      label: '车队',
      rule: [ValidateService.required, ValidateService.max(16)]
    },
    car_type: {
      prop: 'car_type',
      label: '赛车车型',
      rule: [ValidateService.required, ValidateService.max(20)]
    },
    displacement: {
      prop: 'displacement',
      label: '赛车排量',
      rule: [ValidateService.required, ValidateService.max(10)]
    },
    car_color: {
      prop: 'car_color',
      label: '赛车颜色',
      rule: [ValidateService.max(10)]
    },
    car_weight: {
      prop: 'car_weight',
      label: '赛车重量',
      precision: 1,
      tips: '(KG)'
    },
    group_sort: {
      prop: 'group_sort',
      label: '组别顺序',
      rule: [ValidateService.requiredNumber]
    },
    sort: {
      prop: 'sort',
      label: '发车顺序',
      rule: [ValidateService.requiredNumber]
    },
    result: {
      prop: 'result',
      label: '成绩',
      rule: [RacingGroupService.currentData.ms_number === 2 ? ValidateService.result2 : ValidateService.result3]
    },
    punish_record: {
      prop: 'punish_record',
      label: '罚时'
    },
    result_sort: {
      prop: 'result_sort',
      label: '成绩排序'
    }
  })
}
