
import { Component, Vue } from 'vue-property-decorator'
import PersonalForm from './personal/form.vue'
import ControlForm from './control/form.vue'

@Component({
  components: {
    PersonalForm,
    ControlForm
  }
})
export default class ViewUserMemberUserForm extends Vue {
  private activeName = 'personal'
}
