import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class MallOrderService extends BaseAbstract {
  displayName = '订单'
  name = 'MallOrder'
  index (params: { page: number }) {
    return IOService.get('mall_order', params)
  }

  show (id: number) {
    return IOService.get(`mall_order/${id}`)
  }

  express (params: { mall_store_order_id: number; express_no: string; order_status: number }) {
    return IOService.post('mall_order/express', params)
  }

  getExpress (mall_store_order_id: number) {
    return IOService.get('mall_order/getExpress', { mall_store_order_id })
  }
}

export default new MallOrderService()
