
import ValidateService from '@/service/ValidateService'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import MallOrderService from '@/biz/service/Mall/MallOrderService'

@Component
export default class ServiceDialogExpress extends Vue {
  @Ref()
  formElement!: any

  @Prop()
  params!: { mall_store_order_id: number; express_type: string; express_no: string; order_status: number }

  @Watch('form.express_no')
  onExpressNo (val: string) {
    if (val) {
      this.form.order_status = 3
    }
  }

  private form = {
    mall_store_order_id: this.params.mall_store_order_id,
    express_type: this.params.express_type || 'AUTO',
    express_no: this.params.express_no,
    order_status: this.params.order_status === 2 ? 2 : 3
  }

  private formFields = ValidateService.genRules({
    express_type: {
      prop: 'express_type',
      label: '快递公司',
      options: [
        { display_name: '其它快递', value: 'AUTO' },
        { display_name: '顺丰速运', value: 'shunfeng' }
      ]
    },
    express_no: {
      prop: 'express_no',
      label: '快递号'
    },
    order_status: {
      prop: 'order_status',
      label: '订单状态',
      options: [
        { display_name: '待发货', value: 2 },
        { display_name: '已发货', value: 3 }
      ]
    }
  })

  private handleSubmit () {
    return this.formElement.validate()
      .then(() => MallOrderService.express(this.form))
      .then((res: any) => {
        this.$emit('done')
        return res
      })
  }
}
