import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingCommentService extends BaseAbstract {
  displayName = '评论'
  name = 'UserComment'
  controllerName = 'RacingComment'

  index (params: any) {
    return IOService.get('racing_comment', {
      _model: 'Racing',
      id: RouterService.query('racing_id'),
      ...params
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_comment/${id}`)
  }
}

export default new RacingCommentService()
