import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class UserRoleService extends BaseAbstract {
  displayName = '角色'
  name = 'UserRole'
  index () {
    return IOService.get('user_role')
  }

  show (id: number | string) {
    return IOService.get(`user_role/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user_role/${params.id}`, params)
  }

  updatePermissions (user_role_id: number, menus: number[], permissions: number[]) {
    return IOService.post('user_role/updatePermissions', { user_role_id, menus, permissions })
  }

  getPermissions (user_role_id: number) {
    return IOService.get('user_role/getPermissions', { user_role_id })
  }
}

export default new UserRoleService()
