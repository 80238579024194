
import TableMixins from './TableMixins'
import { Component, Prop, Inject, Mixins } from 'vue-property-decorator'
import { ITableColumnAction } from '@/interface/common'
import RouterService from '@/service/RouterService'
import UserService from '@/service/UserService'
import DialogService from '@/service/DialogService/DialogService'
import BaseAbstract from '@/abstract/BaseAbstract'

interface Service extends BaseAbstract {
  destroy: Function;
  refresh: Function;
}

@Component
export default class TableAction extends Mixins(TableMixins) {
  @Prop()
  buttons!: ITableColumnAction[]

  @Prop({ default: 'right' })
  fixed!: string

  @Inject('tableService')
  tableService!: Service

  private innerButtons: ITableColumnAction[] = []

  private handleDestroy (scope: { row: any; $index: number }, v: { onClick: Function }) {
    return Promise.resolve()
      .then(() => {
        if (v.onClick) {
          return v.onClick(scope.row, scope.$index)
        } else {
          return this.tableService.destroy(scope.row.id)
        }
      })
      .then((res) => {
        this.tableService.refresh()
        return res
      })
  }

  private handleEdit (scope: { row: any; $index: number }, v: { onClick: Function }) {
    return Promise.resolve()
      .then(() => {
        if (v.onClick) {
          return v.onClick(scope.row, scope.$index)
        } else {
          return RouterService.pushForm({ id: scope.row.id })
        }
      })
  }

  private handleShow (scope: { row: any; $index: number }, v: { onClick: Function }) {
    return Promise.resolve()
      .then(() => {
        if (v.onClick) {
          return v.onClick(scope.row, scope.$index)
        } else {
          if (process.env.VUE_APP_PLATFORM === 'ADMIN') {
            return DialogService.show(require('@/biz/admin/views' + RouterService.getPath() + '/show.vue').default, { id: scope.row.id })
          } else {
            return DialogService.show(require('@/biz/business/views' + RouterService.getPath() + '/show.vue').default, { id: scope.row.id })
          }
        }
      })
  }

  private handleOnShow (scope: any, v: { onShow: Function }) {
    if (v.onShow) {
      return v.onShow(scope.row)
    } else {
      return true
    }
  }

  private hasPermission (res: ITableColumnAction) {
    if (res.name === '编辑') {
      res.permission = 'update'
    } else if (res.name === '删除') {
      res.permission = 'destroy'
    } else if (res.name === '查看详情') {
      res.permission = 'show'
    }
    if (res.permission) {
      return UserService.hasPermission(this.tableService.getPermissionName(res.permission))
    } else {
      return true
    }
  }

  created () {
    this.innerButtons = this.buttons
      .map((res) => {
        if (res.children) {
          res.children = res.children.filter((row) => {
            return this.hasPermission(row)
          })
        }
        return res
      })
      .filter((res) => {
        if (res.name === '更多' && res.children) {
          return res.children.length > 0
        } else {
          return this.hasPermission(res)
        }
      })
  }
}
