
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import ValidateService from '@/service/ValidateService'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class ViewsComponentsCoinRewardRule extends Vue {
  @Ref()
  formElement!: any

  @Prop()
  params!: { id: number; coin_reward_rule: string }

  private form = {
    racing_round_id: this.params.id,
    coin_reward_rule: ''
  }

  private innerForm = {} as any

  private rules = [] as any[]

  private handleSubmit () {
    this.form.coin_reward_rule = Object.values(this.innerForm).join()
    return this.formElement.validate()
      .then(() => RacingRoundService.setCoinRewardRule(this.form))
      .then((res: any) => {
        this.$emit('done')
        return res
      })
  }

  private handleAdd () {
    this.$set(this.innerForm, 'value' + this.rules.length, '')
    this.rules.push(ValidateService.genRule({
      prop: 'value' + this.rules.length,
      label: `第${this.rules.length + 1}名`,
      rule: [ValidateService.requiredNumber, ValidateService.minNum(10)],
      min: 10
    }))
  }

  private handleDelete () {
    this.$delete(this.innerForm, 'value' + (this.rules.length - 1))
    this.rules.splice(this.rules.length - 1, 1)
  }

  created () {
    const ruleValues = (this.params.coin_reward_rule || '').split(',').filter((val) => val)
    for (let i = 0; i < ruleValues.length; i++) {
      this.$set(this.innerForm, 'value' + i, ruleValues[i] || '')
      this.rules.push(ValidateService.genRule({
        prop: 'value' + i,
        label: `第${i + 1}名`,
        rule: [ValidateService.requiredNumber, ValidateService.minNum(10)],
        min: 10
      }))
    }
  }
}
