
import { Component, Ref, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import RacingT3ApplyService from '@/biz/service/Racing/RacingT3ApplyService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'

@Component
export default class RacingApplyT3Form extends Vue {
  @Ref()
  formElement!: any

  private Service = RacingT3ApplyService
  private form = {
    id: RouterService.query('id'),
    racing_group_id: Number(RouterService.query('racing_group_id') || 0),
    team_name: ''
  }

  private currentRacingGroupItem = {
    id: 0,
    is_pilot_required: 1
  }

  private formFields = {} as any

  handleBeforeLoad () {
    return this.getRacingGroupItem()
      .then(() => this.initRule())
      .then(() => RacingGroupService.getList())
      .then((data) => {
        this.formFields.racing_group_id.options = data
      })
  }

  private getRacingGroupItem () {
    return Promise.resolve()
      .then(() => {
        if (this.form.racing_group_id) {
          return RacingGroupService.get(undefined, this.form.racing_group_id)
            .then((data) => {
              Object.assign(this.currentRacingGroupItem, data)
            })
        }
      })
  }

  private handleChangeRacingGroup (val: number) {
    this.form.racing_group_id = val
    this.formElement.reload()
  }

  private initRule () {
    this.formFields = ValidateService.genRules({
      racing_group_id: {
        prop: 'racing_group_id',
        label: '组别',
        options: [],
        props: {
          value: 'id',
          label: 'display_name'
        },
        rule: [ValidateService.required({ trigger: 'change', type: 'number' })]
      },
      team_name: {
        prop: 'team_name',
        label: '车队',
        rule: [ValidateService.required, ValidateService.max(16)]
      }
    })
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      this.form.team_name = '万鑫的车队'
    }
  }
}
