
import { Component, Ref, Vue } from 'vue-property-decorator'
import UserService from '@/biz/service/User/UserService'
import { ISearchFields } from '@/interface/common'
import RouterService from '@/service/RouterService'
import ConstService from '@/service/ConstService'

@Component
export default class ViewUserMemberUserIndex extends Vue {
  @Ref()
  tableElement!: any

  private Service = UserService
  private RouterService = RouterService

  private searchFields: ISearchFields[] = [
    {
      name: 'id',
      display_name: 'ID',
      valueType: 'int'
    },
    {
      name: 'nickname',
      display_name: '昵称',
      valueType: 'string'
    },
    {
      name: 'phone',
      display_name: '手机号',
      valueType: 'string'
    },
    {
      name: 'is_follow_official_account',
      display_name: '是否关注公众号',
      valueType: 'intOptions',
      options: ConstService.getBoolOptions()
    },
    {
      name: 'created_at',
      display_name: '授权时间',
      valueType: 'dateTime'
    },
    {
      name: 'last_login_at',
      display_name: '最后登录时间',
      valueType: 'dateTime'
    }
  ]

  private handleUserBill (row: { id: number }) {
    return UserService.dialog(require('@/biz/components/components/UserBill.vue').default, {
      user_id: row.id
    })
  }
}
