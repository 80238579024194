import store from 'store2'

/**
 * 调用方式参考store2插件(例如:)config.get('menuEnum')
 * config: {menuEnum}
 */
class CacheService {
  private prefix = process.env.VUE_APP_BASEURL + '/'
  public user = store.namespace(this.prefix + 'user')
  public layout = store.namespace(this.prefix + 'layout')
  public config = store.namespace(this.prefix + 'config')
  public version = store.namespace(this.prefix + 'version')
  public racing_group = store.namespace(this.prefix + 'racing_group')
  public clearAll () {
    store.clearAll()
  }
}

export default new CacheService()
