import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class NoticeCommentService extends BaseAbstract {
  displayName = '评论'
  name = 'UserComment'
  controllerName = 'NoticeComment'

  index (params: any) {
    return IOService.get('notice_comment', {
      _model: 'Notice',
      ...params
    })
  }

  destroy (id: number) {
    return IOService.delete(`notice_comment/${id}`)
  }
}

export default new NoticeCommentService()
