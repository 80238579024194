import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingRoundService from './RacingRoundService'
import RacingService from './RacingService'

class RacingResultService extends BaseAbstract {
  displayName = '成绩'
  name = 'RacingResult'
  index () {
    return IOService.get('racing_result', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  abIndex () {
    return this.index()
      .then((res) => {
        let currentGroupSort = 1
        res.data = res.data.map((item: any) => {
          if (item.group_sort === currentGroupSort) {
            const mergeNumber = res.data.filter((row: any) => row.group_sort === currentGroupSort).length
            item.merge = mergeNumber
            currentGroupSort += 1
          } else {
            item.merge = 0
          }
          if (RacingRoundService.currentData.racing_track === RacingRoundService.getOptionsValue('racing_track', 3, 'AB道')) {
            item.racing_track = item.sort === 2 ? 'B道/A道' : 'A道/B道'
          } else {
            item.racing_track = RacingRoundService.getOptionsLabel('racing_track', RacingRoundService.currentData.racing_track)
          }
          item.group_sort_display_name = `第${item.group_sort}组`
          item.remarks = ' '
          return item
        })
        return res
      })
  }

  manyIndex () {
    return this.index()
      .then((res) => {
        let currentGroupSort = 1
        res.data = res.data.map((item: any) => {
          if (item.group_sort === currentGroupSort) {
            const mergeNumber = res.data.filter((row: any) => row.group_sort === currentGroupSort).length
            item.merge = mergeNumber
            currentGroupSort += 1
          } else {
            item.merge = 0
          }
          item.group_sort_display_name = `第${item.group_sort}组`
          item.racing_track = `第${item.sort}道`
          item.remarks = ' '
          return item
        })
        return res
      })
  }

  rallyIndex () {
    return this.index()
  }

  t3Index () {
    return this.index()
  }

  show (id: number) {
    return IOService.get(`racing_result/${id}`, {
      racing_id: RouterService.query('racing_id')
    })
  }

  update (params: { id: number }) {
    return IOService.put(`racing_result/${params.id}`, {
      racing_id: RouterService.query('racing_id'),
      ...params
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_result/${id}`, {
      racing_id: RouterService.query('racing_id')
    })
  }

  createStartTable () {
    return IOService.post('racing_result/createStartTable', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  createResultTable () {
    return IOService.post('racing_result/createResultTable', {
      racing_round_id: RouterService.query('racing_round_id'),
      ms_number: RacingGroupService.currentData.ms_number,
      max_time: RacingGroupService.currentData.max_time || RacingRoundService.currentData.max_time,
      racing_id: RouterService.query('racing_id')
    })
  }

  togglePublishStartTable (is_publish_start: number) {
    return IOService.post('racing_result/togglePublishStartTable', {
      racing_round_id: RouterService.query('racing_round_id'),
      is_publish_start,
      racing_id: RouterService.query('racing_id')
    })
      .then((res) => {
        RacingService.currentData.status = res.data.status
        RacingService.updateCacheListItem()
        return res
      })
  }

  togglePublishResultTable (is_publish_result: number) {
    return IOService.post('racing_result/togglePublishResultTable', {
      racing_round_id: RouterService.query('racing_round_id'),
      is_publish_result,
      racing_id: RouterService.query('racing_id')
    })
      .then((res) => {
        RacingService.currentData.status = res.data.status
        RacingService.updateCacheListItem()
        return res
      })
  }

  printResultTable ({ isPrize = 0 } = {}) {
    return IOService.get('racing_result/printResultTable', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_id: RouterService.query('racing_id'),
      isPrize
    })
  }

  printTotalResultTable ({ isPrize = 0 } = {}) {
    return IOService.get('racing_result/printTotalResultTable', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_id: RouterService.query('racing_id'),
      isPrize
    })
  }

  batchGenerateResult () {
    return IOService.post('racing_result/batchGenerateResult', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_group_id: RouterService.query('racing_group_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  batchClearResult () {
    return IOService.post('racing_result/batchClearResult', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  batchClearMember () {
    return IOService.post('racing_result/batchClearMember', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  rewardCoin () {
    return IOService.post('racing_result/rewardCoin', {
      racing_round_id: RouterService.query('racing_round_id'),
      racing_group_id: RouterService.query('racing_group_id'),
      racing_id: RouterService.query('racing_id')
    })
  }
}

export default new RacingResultService()
