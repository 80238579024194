import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class NotifyTemplateService extends BaseAbstract {
  displayName = '通知模板'
  name = 'NotifyTemplate'
  index (params: { page: number }) {
    return IOService.get('notify_template', params)
  }

  store (params: object) {
    return IOService.post('notify_template', params)
  }

  show (id: number | string) {
    return IOService.get(`notify_template/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`notify_template/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`notify_template/${id}`)
  }
}

export default new NotifyTemplateService()
