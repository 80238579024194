
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import MallClassifySpecService from '@/biz/service/Mall/MallClassifySpecService'

@Component
export default class RacingGroupMain extends Vue {
  private menuList: any[] = []
  private active = RouterService.query('mall_classify_spec_id')
  private fullPath = ''
  private mall_classify_id = RouterService.query('mall_classify_id')

  private handleLoad () {
    return MallClassifySpecService.getList()
      .then((data) => {
        this.menuList = data
      })
  }

  private handleTabClick (res: any) {
    const mall_classify_spec_id = this.menuList[res.index].id
    if (mall_classify_spec_id !== Number(RouterService.query('mall_classify_spec_id'))) {
      RouterService.replace('', {
        ...this.$route.query,
        mall_classify_spec_id
      })
    }
  }

  private handleAddGroup () {
    RouterService.push('/mall/mall-classify/mall-classify-spec-manage/form', {
      ...this.$route.query,
      mall_classify_spec_id: ''
    })
  }

  created () {
    this.fullPath = RouterService.getFullPath()
    this.active = RouterService.query('mall_classify_spec_id')
  }
}
