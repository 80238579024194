import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingT3ApplyService extends BaseAbstract {
  displayName = '报名'
  name = 'RacingT3Apply'
  index (params?: any) {
    return IOService.get('racing_t3_apply', {
      ...params,
      racing_group_id: RouterService.query('racing_group_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  store (params: object) {
    return IOService.post('racing_t3_apply', {
      ...params,
      racing_id: RouterService.query('racing_id')
    })
  }

  show (id: number) {
    return IOService.get(`racing_t3_apply/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing_t3_apply/${params.id}`, {
      ...params,
      racing_id: RouterService.query('racing_id')
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_t3_apply/${id}`)
  }

  batchCreate (number = 1) {
    return IOService.post('racing_t3_apply/batchCreate', {
      racing_id: RouterService.query('racing_id'),
      racing_group_id: RouterService.query('racing_group_id'),
      number
    })
  }

  batchDestroy () {
    return IOService.post('racing_t3_apply/batchDestroy', {
      racing_group_id: RouterService.query('racing_group_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  enterIntoRound (params: { racing_apply_id: number; racing_round_id: number }) {
    return IOService.post('racing_t3_apply/enterIntoRound', {
      racing_id: RouterService.query('racing_id'),
      ...params
    })
  }

  printIndex () {
    return IOService.get('racing_t3_apply/printIndex', {
      racing_group_id: RouterService.query('racing_group_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  insureIndex () {
    return IOService.get('racing_t3_apply/printIndex', {
      racing_id: RouterService.query('racing_id')
    })
  }

  cashPay (racing_apply_id: number) {
    return IOService.post('racing_t3_apply/cashPay', {
      racing_id: RouterService.query('racing_id'),
      racing_apply_id
    })
  }

  getRacingSupports (params: { page: number; racing_apply_id: number }) {
    return IOService.get('racing_t3_apply/getRacingSupports', {
      racing_id: RouterService.query('racing_id'),
      ...params
    })
  }
}

export default new RacingT3ApplyService()
