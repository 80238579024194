
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RacingGroupManyForm extends Vue {
  @Prop()
  form!: any

  @Prop()
  formFields!: any
}
