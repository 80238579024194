
import ConstService from '@/service/ConstService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import GeneralEmptyApply from './components/GeneralEmptyApply.vue'
import RallyEmptyApply from './components/RallyEmptyApply.vue'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    GeneralEmptyApply,
    RallyEmptyApply
  }
})
export default class RacingPrintEmptyApply extends Vue {
  @Prop()
  params!: { row: any }

  private current = ''

  created () {
    if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'Rally') {
      this.current = 'RallyEmptyApply'
    } else {
      this.current = 'GeneralEmptyApply'
    }
  }
}
