import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class EnterpriseService extends BaseAbstract {
  displayName = '企业'
  name = 'Enterprise'
  limit = 12
  cacheDatas = [] as any[]

  index (params: { page: number }) {
    return IOService.get('enterprise', params)
  }

  store (params: object) {
    return IOService.post('enterprise', {
      ...params,
      marking: RouterService.query('marking')
    })
  }

  show (id: number) {
    return IOService.get(`enterprise/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`enterprise/${params.id}`, params)
  }

  destroy (id: number) {
    return IOService.delete(`enterprise/${id}`)
  }

  get (enterprise_id?: number) {
    enterprise_id = enterprise_id ? Number(enterprise_id) : Number(RouterService.query('enterprise_id'))
    return Promise.resolve()
      .then(() => {
        if (!enterprise_id) {
          return Promise.reject(new Error('enterprise_id不存在'))
        }
        if (!this.cacheDatas.some((res: any) => res.id === enterprise_id)) {
          return this.show(enterprise_id)
            .then((res) => {
              this.cacheDatas.push(res.data)
            })
        }
      })
      .then(() => this.cacheDatas.find((res: any) => res.id === enterprise_id))
  }

  indexAll () {
    return IOService.get('enterprise/indexAll')
      .then((res) => {
        this.cacheDatas = res.data
        return res
      })
  }

  getList () {
    return Promise.resolve()
      .then(() => {
        if (this.cacheDatas.length === 0) {
          return this.indexAll()
        }
      })
      .then(() => this.cacheDatas)
  }

  getPermissions (enterprise_id: number) {
    return IOService.get('enterprise/getPermissions', { enterprise_id })
  }

  updatePermissions (enterprise_id: number, menus: number[], permissions: number[]) {
    return IOService.post('enterprise/updatePermissions', { enterprise_id, menus, permissions })
  }
}

export default new EnterpriseService()
