import { Component, Vue, Prop, Inject } from 'vue-property-decorator'

@Component
export default class FormMixins extends Vue {
  @Prop()
  prop!: string

  @Prop()
  label!: string

  @Prop()
  width!: number

  @Prop()
  minWidth!: number

  @Prop()
  align!: string

  @Prop()
  className!: string

  @Prop({
    default: () => {
      return {}
    }
  })
  props!: { label: string; value: any }

  @Inject('tableRender')
  tableRender!: { align: string }
}
