
import FormMixins from './FormMixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class FormDate extends Mixins(FormMixins) {
  @Prop({ default: 'datetime' })
  type!: string

  private valueFormat = 'yyyy-MM-dd HH:mm:ss'

  created () {
    if (this.type === 'date') {
      this.valueFormat = 'yyyy-MM-dd'
    }
  }
}
