
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CarouselService from '@/service/CarouselService/CarouselService'

@Component
export default class ImageMaskContainer extends Vue {
  @Prop()
  value!: string | string[]

  @Prop()
  index!: number

  @Prop({ default: 150 })
  size!: number | string

  @Watch('value')
  onValue (val: string) {
    this.innerValue = val
  }

  @Watch('innerValue')
  onInnerValue (val: string) {
    this.$emit('input', val)
  }

  private innerValue = this.value

  private handleRemove () {
    if (Array.isArray(this.innerValue)) {
      this.innerValue.splice(this.index, 1)
    } else {
      this.innerValue = ''
    }
  }

  private handleViewImages () {
    CarouselService.open(Array.isArray(this.innerValue) ? this.innerValue : [this.innerValue], {
      autoplay: false,
      index: this.index || 0
    })
  }
}
