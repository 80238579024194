
import RouterService from '@/service/RouterService'
import { Component, Vue } from 'vue-property-decorator'
import Base from './components/Base.vue'
import Spec from './components/Spec.vue'

@Component({
  components: {
    Base,
    Spec
  }
})
export default class MallProductForm extends Vue {
  private active = Number(RouterService.query('active') || 0)

  private handlePrev () {
    RouterService.replace('/mall/mall-product/form', { ...this.$route.query, active: 0 })
  }
}
