
import RouterService from '@/service/RouterService'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { IMenu } from '@/interface/common'

@Component
export default class BreadcrumbContainer extends Vue {
  private isBack = false
  private list: string[] = []

  @Prop()
  routePaths!: IMenu[]

  @Watch('routePaths')
  onRoutePaths () {
    this.isBack = false
    this.initList()
    this.optimizeData()
  }

  private RouterService = RouterService

  private optimizeData () {
    if (this.$route.path.split('/').includes('form')) {
      this.isBack = true
    } else if (this.list.length > 2) {
      this.isBack = true
    }
  }

  private initList () {
    this.list = this.routePaths.map((res) => res.display_name)
  }

  created () {
    this.initList()
    this.optimizeData()
  }
}
