import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class RacingDynamicCommentService extends BaseAbstract {
  displayName = '评论'
  name = 'UserComment'
  controllerName = 'RacingDynamicComment'

  index (params: any) {
    return IOService.get('racing_dynamic_comment', {
      _model: 'RacingDynamic',
      ...params
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_dynamic_comment/${id}`)
  }
}

export default new RacingDynamicCommentService()
