import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import racing from './racing'
import mall from './mall'
import enterprise from './enterprise'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: require('@/biz/components/Main/Main.vue').default,
    children: [
      ...racing,
      ...mall,
      ...enterprise
    ]
  },
  {
    path: '/login',
    component: require('@/biz/business/views/login.vue').default
  },
  {
    path: '*',
    component: require('@/biz/components/Main/Main.vue').default
  }
]

const router = new VueRouter({
  routes
})

export default router
