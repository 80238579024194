
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormTree from '@/components/Form/FormTree.vue'

@Component({
  components: {
    FormTree
  }
})
export default class ComponentPermission extends Vue {
  $refs!: {
    menuTreeElement: any;
    interfaceTreeElement: any;
  }

  @Prop()
  params!: { Service: any; id: number }

  private user_interfaces: any[] = []
  private all_interfaces: any[] = []
  private user_menus: any[] = []
  private all_menus: number[] = []

  private handleLoad () {
    return this.params.Service.getPermissions(this.params.id)
      .then((res: any) => {
        this.user_interfaces = res.data.user_interfaces || []
        this.all_interfaces = res.data.all_interfaces || []
        this.user_menus = res.data.user_menus || []
        this.all_menus = res.data.all_menus || []
      })
  }

  private handleSubmit () {
    let menus = []
    let permissions = []
    if (this.all_menus.length > 0) {
      menus = this.$refs.menuTreeElement.getCheckedNodeIds()
    }
    permissions = this.$refs.interfaceTreeElement.getCheckedNodeIds()
    return this.params.Service.updatePermissions(this.params.id, menus, permissions)
      .then((res: any) => {
        this.$emit('done')
        return res
      })
  }
}
