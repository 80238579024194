
import { Component, Vue } from 'vue-property-decorator'
import GeneralApply from './General/Index.vue'
import RallyApply from './Rally/Index.vue'
import T3Apply from './T3/Index.vue'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'
import RouterService from '@/service/RouterService'

@Component({
  components: {
    GeneralApply,
    RallyApply,
    T3Apply
  }
})
export default class ViewRacingApplyIndex extends Vue {
  private current = ''
  private racing_group_id = RouterService.query('racing_group_id')

  created () {
    const name = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
    if (name === 'Rally') {
      this.current = name + 'Apply'
    } else if (name === 'T3') {
      this.current = name + 'Apply'
    } else {
      this.current = 'GeneralApply'
    }
  }
}
