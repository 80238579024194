
import FormMixins from './FormMixins'
import { Component, Mixins, Inject, Prop, Ref } from 'vue-property-decorator'
import CarouselService from '@/service/CarouselService/CarouselService'
import ImageMaskContainer from '@/components/ImageMaskContainer.vue'
import BaseAbstract from '@/abstract/BaseAbstract'
import ImageManage from '@/service/ImageManageService/ImageManage.vue'

@Component({
  components: {
    ImageMaskContainer,
    ImageManage
  }
})
export default class FormImages extends Mixins(FormMixins) {
  @Ref()
  ImageManageElement!: any

  @Inject('formService')
  formService!: BaseAbstract

  @Prop()
  fieldService!: BaseAbstract

  private handleChangeModal () {
    return this.ImageManageElement.open()
      .then((data: { full_url: string }[]) => {
        const imgs = data.map((res) => res.full_url)
        this.innerValue = this.innerValue.concat(imgs)
      })
      .catch((err: object) => console.log(err))
  }

  private handleRemove (index: number) {
    this.innerValue.splice(index, 1)
  }

  private handleViewImages (index: number) {
    CarouselService.open(this.innerValue, {
      autoplay: false,
      index
    })
  }

  private handleDestroy (urls: string[]) {
    this.innerValue = this.innerValue.filter((url: string) => !urls.includes(url))
  }

  created () {
    this.innerValue = this.innerValue || []
  }
}
