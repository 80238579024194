
import DialogService from '@/service/DialogService/DialogService'
import { Component, Vue } from 'vue-property-decorator'
import NoticeService from '@/biz/service/System/NoticeService'
import NoticeCommentService from '@/biz/service/Comment/NoticeCommentService'

@Component
export default class ViewSystemNoticeIndex extends Vue {
  private Service = NoticeService

  private handleComment (id: number) {
    return DialogService.show(require('@/biz/components/components/Comments.vue').default, { id, CommentService: NoticeCommentService })
  }
}
