
import { Component, Vue } from 'vue-property-decorator'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingService from '@/biz/service/Racing/RacingService'
import Ab from './components/Index/Ab.vue'
import Many from './components/Index/Many.vue'
import Rally from './components/Index/Rally.vue'
import T3 from './components/Index/T3.vue'
import ConstService from '@/service/ConstService'

@Component({
  components: {
    Ab,
    Many,
    Rally,
    T3
  }
})
export default class RacingGroupIndex extends Vue {
  private Service = RacingGroupService
  private current = ''

  created () {
    this.current = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
  }
}
