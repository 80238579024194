
import RouterService from '@/service/RouterService'
import { Component, Vue, Prop } from 'vue-property-decorator'
import UserService from '@/service/UserService'
import { IMenu } from '@/interface/common'
import CacheService from '@/service/CacheService'
import DialogService from '@/service/DialogService/DialogService'

@Component
export default class HeaderContainer extends Vue {
  private UserService = UserService
  private list: IMenu[] = []

  @Prop()
  routePaths!: IMenu[]

  get routeActive () {
    if (this.routePaths.length > 0) {
      return this.routePaths[0].route
    }
    return ''
  }

  private handleChangeMenu (path: string) {
    RouterService.push(path)
  }

  private handleClickDropdown (name: string) {
    if (name === 'setup') {
      this.handleSetup()
    } else if (name === 'updateCache') {
      this.handleUpdateCache()
    } else if (name === 'logout') {
      this.handleLogout()
    }
  }

  private handleLogout () {
    this.$confirm('您真的要注销吗?', '提示', {
      type: 'warning'
    })
      .then(() => UserService.logout())
      .then((message: string) => {
        this.$message({
          type: 'success',
          message
        })
        RouterService.push('/login')
      })
  }

  private handleUpdateCache () {
    const loading = this.$loading({
      lock: true,
      text: '更新中...'
    })
    UserService.update()
      .then(() => {
        loading.close()
        this.init()
        this.$emit('update')
      })
  }

  private handleSetup () {
    DialogService.show(require('@/biz/admin/views/setup.vue').default, {
      width: '400px'
    })
  }

  private init () {
    const RouteList: IMenu[] = CacheService.layout.get('menus') || []
    this.list = RouteList.filter((res: IMenu) => !res.parent_id)
  }

  created () {
    this.init()
  }
}
