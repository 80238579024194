import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class NotifyService extends BaseAbstract {
  name = 'Notify'
  index (params: { page: number }) {
    return IOService.get('notify', params)
  }

  show (id: number | string) {
    return IOService.get(`notify/${id}`)
  }
}

export default new NotifyService()
