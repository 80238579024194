
import RouterService from '@/service/RouterService'
import { Component, Vue, Watch } from 'vue-property-decorator'
import RacingService from '@/biz/service/Racing/RacingService'
import ValidateService from '@/service/ValidateService'

@Component
export default class RacingMain extends Vue {
  private fullPath = ''
  private RacingService = RacingService
  private form = {
    id: RouterService.query('racing_id'),
    status: 0
  }

  @Watch('RacingService.currentData.status')
  onStatus (val: number) {
    this.form.status = val
  }

  private formFields = ValidateService.genRules({
    status: {
      prop: 'status',
      label: '赛事状态',
      size: 'mini',
      options: RacingService.getOptions('status')
    }
  })

  private handleLoad () {
    return RacingService.get()
      .then(() => {
        this.form.status = RacingService.currentData.status
      })
  }

  private handleSubmit () {
    return RacingService.updateStatus(this.form)
  }

  private handleUpdateDraw () {
    return this.$confirm('您真的要重置抽签结果吗？')
      .then(() => RacingService.updateDraw())
  }

  created () {
    this.fullPath = RouterService.getFullPath()
  }
}
