
import { Component, Vue } from 'vue-property-decorator'
import NotifyTemplateService from '@/biz/service/Notify/NotifyTemplateService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import ConstService from '@/service/ConstService'

@Component
export default class ViewOtherWeChatNotifyTemplateForm extends Vue {
  private Service = NotifyTemplateService
  private form = {
    id: RouterService.query('id'),
    template_id: '',
    title: '',
    content: '',
    remark: '',
    push_platform: 'oa',
    queue: 5,
    url_platform: 'mp',
    host: '',
    url: '',
    url_params: '',
    keyword_names: '',
    is_push_wechat: 1,
    is_push_message: 1
  }

  private formFields: IFormFields = ValidateService.genRules({
    template_id: {
      prop: 'template_id',
      label: '微信模板ID',
      rule: [ValidateService.required, ValidateService.max(80)]
    },
    title: {
      prop: 'title',
      label: '通知标题',
      rule: [ValidateService.required, ValidateService.max(60)]
    },
    content: {
      prop: 'content',
      label: '通知内容',
      rule: [ValidateService.required, ValidateService.max(120)]
    },
    remark: {
      prop: 'remark',
      label: '通知备注',
      rule: [ValidateService.required, ValidateService.max(120)]
    },
    push_platform: {
      prop: 'push_platform',
      label: '推送平台',
      rule: [ValidateService.required({ trigger: 'change' })],
      options: [
        { value: 'oa', display_name: '公众号' },
        { value: 'mp', display_name: '小程序', disabled: true }
      ]
    },
    host: {
      prop: 'host',
      label: '主域名',
      rule: [ValidateService.required, ValidateService.max(120)],
      placeholder: '格式如：https://www.chinasaiche.com'
    },
    url: {
      prop: 'url',
      label: '跳转地址',
      rule: [ValidateService.required, ValidateService.max(120)],
      placeholder: '格式如：/list'
    },
    url_params: {
      prop: 'url_params',
      label: '地址参数',
      rule: [ValidateService.max(120)],
      placeholder: '格式如：id,name'
    },
    url_platform: {
      prop: 'url_platform',
      label: '跳转平台',
      rule: [ValidateService.required({ trigger: 'change' })],
      options: [
        { value: 'mp', display_name: '小程序' },
        { value: 'oa', display_name: '公众号', disabled: true }
      ]
    },
    keyword_names: {
      prop: 'keyword_names',
      label: '字段名称',
      rule: [ValidateService.required, ValidateService.max(120)],
      placeholder: '格式如：name,姓名|date,日期'
    },
    queue: {
      prop: 'queue',
      label: '优先级',
      rule: [ValidateService.required({ type: 'number' }), ValidateService.minNum(1), ValidateService.maxNum(9)]
    },
    is_push_wechat: {
      prop: 'is_push_wechat',
      label: '推送公众号',
      options: ConstService.getBoolOptions()
    },
    is_push_message: {
      prop: 'is_push_message',
      label: '推送站内信',
      options: ConstService.getBoolOptions()
    }
  })
}
