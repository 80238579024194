
import FormMixins from './FormMixins'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'

@Component
export default class FormInputResult extends Mixins(FormMixins) {
  @Ref()
  itemElement!: any

  @Prop({ default: 2 })
  ms_number!: number

  private maxLength = this.ms_number === 2 ? 11 : 12
  private timer = 0

  @Watch('innerValue')
  onInnerValue2 (val: any) {
    this.setTime(val)
  }

  private setTime (val: any) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const arr = val.split('')
      if (this.ms_number === 2) {
        if (/\d{8}$/.test(val)) {
          this.$emit('input', `${arr[0]}${arr[1]}:${arr[2]}${arr[3]}:${arr[4]}${arr[5]}.${arr[6]}${arr[7]}`)
        } else if (/\d{6}$/.test(val)) {
          this.$emit('input', `${arr[0]}${arr[1]}:${arr[2]}${arr[3]}.${arr[4]}${arr[5]}`)
        }
      } else {
        if (/\d{9}$/.test(val)) {
          this.$emit('input', `${arr[0]}${arr[1]}:${arr[2]}${arr[3]}:${arr[4]}${arr[5]}.${arr[6]}${arr[7]}${arr[8]}`)
        } else if (/\d{7}$/.test(val)) {
          this.$emit('input', `${arr[0]}${arr[1]}:${arr[2]}${arr[3]}.${arr[4]}${arr[5]}${arr[6]}`)
        }
      }
      this.itemElement.clearValidate()
    }, 600)
  }
}
