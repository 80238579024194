
import { Component, Vue } from 'vue-property-decorator'
import MallStoreService from '@/biz/service/Mall/MallStoreService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'
import EnterpriseService from '@/biz/service/Enterprise/EnterpriseService'

@Component
export default class MallBrandForm extends Vue {
  private MallStoreService = MallStoreService
  private form = {
    id: RouterService.query('id'),
    avatar: '',
    cover_img: '',
    display_name: '',
    intro: '',
    enterprise_id: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '店铺名',
      rule: [ValidateService.required, ValidateService.max(10)]
    },
    avatar: {
      prop: 'avatar',
      label: 'LOGO'
    },
    cover_img: {
      prop: 'cover_img',
      label: '封面图'
    },
    intro: {
      prop: 'intro',
      label: '店铺简介',
      rule: [ValidateService.max(255)]
    },
    enterprise_id: {
      prop: 'enterprise_id',
      label: '所属企业',
      options: [],
      rule: [ValidateService.requiredNumber],
      props: {
        value: 'id',
        label: 'display_name'
      }
    }
  })

  private handleBeforeLoad () {
    return EnterpriseService.getList()
      .then((data) => {
        this.formFields.enterprise_id.options = data
      })
  }
}
