
import { Component, Vue } from 'vue-property-decorator'
import UserService from '@/biz/service/User/UserService'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'
import UserRoleService from '@/biz/service/User/UserRoleService'

@Component
export default class ViewUserMemberUserPersonalForm extends Vue {
  private Service = UserService
  private form = {
    id: RouterService.query('id'),
    username: '',
    phone: '',
    password: '',
    nickname: '',
    user_phone: '',
    head_url: '',
    real_name: '',
    id_card: '',
    blood: '',
    nationality: '',
    intro: '',
    _user_role_ids: []
  }

  private formFields = ValidateService.genRules({
    _user_role_ids: {
      prop: '_user_role_ids',
      label: '角色',
      rule: [ValidateService.requiredArray],
      options: [],
      multiple: true,
      props: {
        value: 'id',
        label: 'display_name'
      }
    },
    username: {
      prop: 'username',
      label: '用户名',
      rule: [ValidateService.username]
    },
    phone: {
      prop: 'phone',
      label: '登录手机号',
      disabled: true
    },
    password: {
      prop: 'password',
      label: '密码',
      rule: [ValidateService.loginPassword, this.form.id ? ValidateService.optional : ValidateService.required]
    },
    nickname: {
      prop: 'nickname',
      label: '昵称',
      rule: [ValidateService.nickname]
    },
    user_phone: {
      prop: 'user_phone',
      label: '联系电话',
      rule: [ValidateService.mobile]
    },
    head_url: {
      prop: 'head_url',
      label: '头像'
    },
    real_name: {
      prop: 'real_name',
      label: '姓名',
      rule: [ValidateService.fullname, ValidateService.optional]
    },
    id_card: {
      prop: 'id_card',
      label: '身份证',
      rule: [ValidateService.idcard, ValidateService.optional]
    },
    intro: {
      prop: 'intro',
      label: '个人介绍',
      rule: [ValidateService.max(255)]
    },
    blood: {
      prop: 'blood',
      label: '血型'
    },
    nationality: {
      prop: 'nationality',
      label: '国籍'
    }
  })

  private handleBeforeLoad () {
    return UserRoleService.index()
      .then((res) => {
        this.formFields._user_role_ids.options = res.data
      })
  }

  private handleAfterLoad (res: any) {
    this.form._user_role_ids = res.data.user_role.map((item: any) => item.id)
  }
}
