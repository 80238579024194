
import { Component, Ref, Vue } from 'vue-property-decorator'
import RacingResultService from '@/biz/service/Racing/RacingResultService'
import ActionButtons from '../components/ActionButtons.vue'
import DialogService from '@/service/DialogService/DialogService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'

@Component({
  components: {
    ActionButtons
  }
})
export default class ViewRacingResultAbIndex extends Vue {
  @Ref()
  tableElement!: any

  private Service = RacingResultService
  private maxHeight = 0

  private handleLoad () {
    return RacingResultService.abIndex()
  }

  private handleSpanMethod ({ row, columnIndex } = {} as { row: any; column: any; rowIndex: number; columnIndex: number }) {
    if (columnIndex === 0) {
      if (row.merge > 0) {
        return {
          rowspan: row.merge,
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }

  private handleReload () {
    this.tableElement.reload()
  }

  private handleSupports (row: { racing_apply_id: number }) {
    return DialogService.show(require('@/biz/components/Racing/components/RacingSupports.vue').default, {
      racing_apply_id: row.racing_apply_id,
      width: '600px'
    })
  }

  private handleShowSupports () {
    return !!RacingRoundService.currentData.coin_reward_rule
  }

  mounted () {
    if (this.tableElement) {
      this.maxHeight = document.body.clientHeight - (this.tableElement.$el.offsetTop + 170)
    }
  }
}
