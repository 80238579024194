import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingRoundService extends BaseAbstract {
  displayName = '回合'
  name = 'RacingRound'
  cacheDatas = {} as { [key: number]: any[] }
  currentData = {
    display_name: '',
    volley_number: 0,
    promoted_number: 0,
    is_publish_start: 0,
    is_publish_result: 0,
    racing_track: 0,
    max_time: 0, // 仅拉力赛，不使用组别的max_time
    coin_reward_rule: ''
  }

  index (racing_group_id?: number) {
    racing_group_id = typeof racing_group_id === 'number' ? racing_group_id : Number(RouterService.query('racing_group_id'))
    return IOService.get('racing_round', { racing_group_id })
      .then((res) => {
        this.cacheDatas[racing_group_id as number] = res.data
        return res
      })
  }

  getAll (racing_id?: number) {
    racing_id = typeof racing_id === 'number' ? racing_id : Number(RouterService.query('racing_id'))
    return IOService.get('racing_round/getAll', { racing_id })
  }

  store (params: object) {
    return IOService.post('racing_round', {
      ...params,
      racing_group_id: RouterService.query('racing_group_id'),
      racing_id: RouterService.query('racing_id')
    })
  }

  show (id: number) {
    return IOService.get(`racing_round/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing_round/${params.id}`, {
      racing_id: RouterService.query('racing_id'),
      ...params
    })
  }

  destroy (id: number) {
    return IOService.delete(`racing_round/${id}`)
  }

  get () {
    return this.getList()
      .then((racingRoundList) => {
        const racing_round_id = Number(RouterService.query('racing_round_id'))
        if (racingRoundList && racing_round_id) {
          const data = racingRoundList.find((res: any) => res.id === racing_round_id)
          if (data) {
            Object.assign(this.currentData, data)
          } else {
            return this.show(racing_round_id)
              .then((res) => {
                Object.assign(this.currentData, res.data)
              })
          }
        }
      })
  }

  getList (racing_group_id?: number) {
    return Promise.resolve()
      .then(() => {
        racing_group_id = racing_group_id || Number(RouterService.query('racing_group_id'))
        if (racing_group_id) {
          if (this.cacheDatas[racing_group_id] && this.cacheDatas[racing_group_id].length) {
            return this.cacheDatas[racing_group_id]
          } else {
            return this.index(racing_group_id).then((res) => res.data)
          }
        } else {
          return []
        }
      })
  }

  updateCacheData (params: object) {
    return Promise.resolve()
      .then(() => {
        const racing_group_id = Number(RouterService.query('racing_group_id'))
        const racing_round_id = Number(RouterService.query('racing_round_id'))
        if (racing_group_id && racing_round_id) {
          const racingRoundList = this.cacheDatas[racing_group_id]
          if (racingRoundList) {
            const data = racingRoundList.find((res: any) => res.id === racing_round_id)
            if (data) {
              Object.assign(data, params)
              Object.assign(this.currentData, data)
            }
          }
        }
      })
  }

  setCoinRewardRule (params: object) {
    return IOService.post('racing_round/setCoinRewardRule', params)
  }
}

export default new RacingRoundService()
