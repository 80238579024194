
import { IFormFields, ISearchFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import getOperatorConfig from './config'
import bigIntValue from './bigIntValue.vue'
import dateTimeValue from './dateTimeValue.vue'
import dateValue from './dateValue.vue'
import intOptionsValue from './intOptionsValue.vue'
import intValue from './intValue.vue'
import stringOptionsValue from './stringOptionsValue.vue'
import stringValue from './stringValue.vue'
import RouterService from '@/service/RouterService'
import QueryString from 'qs'
import SqlService, { ListItem } from '@/service/SqlService'

interface SearchOptions {
  display_name: string;
  id: number | string;
  children?: SearchOptions[];
  [key: string]: any;
}

interface WhereItem { label: string; value: string }

@Component({
  components: {
    bigIntValue,
    dateTimeValue,
    dateValue,
    intOptionsValue,
    intValue,
    stringOptionsValue,
    stringValue
  }
})
export default class SearchToolEntra extends Vue {
  @Ref()
  FormElement!: any

  @Prop()
  fields!: ISearchFields[]

  private valueComponent = ''
  private innerFields: ISearchFields[] = []
  private innerOptions: SearchOptions[] = []
  private innerOptionsProps: { label: string; value: string } = { label: 'display_name', value: 'value' }
  private innerWheres: WhereItem[] = []
  private SqlService = new SqlService()
  private RouterService = RouterService
  private form: ListItem = {
    field: '',
    operator: '',
    value: '',
    valueType: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    field: {
      prop: 'field',
      label: '字段',
      options: this.getFields(),
      rule: [ValidateService.required({ trigger: 'change' })],
      props: {
        value: 'name'
      }
    }
  })

  private createForm (field: string, row?: ListItem) {
    return Promise.resolve()
      .then(() => {
        const fieldItem = this.innerFields.find((res: ISearchFields) => res.name === field) as ISearchFields
        this.innerWheres = getOperatorConfig(fieldItem.valueType)
        this.innerOptions = JSON.parse(JSON.stringify(fieldItem.options || []))
        this.form.field = field
        this.form.operator = (row && row.operator) || this.innerWheres[0].value
        if (fieldItem.valueType === 'intOptions' && row && row.value) {
          this.form.value = (row.value as string[]).map((num) => {
            if (num.length >= 16) {
              return num
            } else {
              return Number(num)
            }
          })
        } else {
          this.form.value = (row && row.value as string) || ''
        }
        this.form.valueType = fieldItem.valueType
        this.valueComponent = this.form.valueType + 'Value'
        Object.assign(this.innerOptionsProps, fieldItem.props)
      })
  }

  private addWhere () {
    return this.FormElement.validate()
      .then(() => {
        const fieldItem = this.innerFields.find((res: ISearchFields) => res.name === this.form.field) as ISearchFields
        const operatorItem = getOperatorConfig(fieldItem.valueType).find((res) => res.value === this.form.operator) as WhereItem
        let valueDisplayName = ''
        if (fieldItem.options && (fieldItem.options as SearchOptions[]).length > 0) {
          valueDisplayName = (fieldItem.options as SearchOptions[])
            .filter((res) => (this.form.value as string[]).includes(res[this.innerOptionsProps.value] as string))
            .map((res) => res[this.innerOptionsProps.label])
            .join()
        }
        this.SqlService.where({
          field: this.form.field,
          operator: this.form.operator,
          value: this.form.value,
          fieldDisplayName: fieldItem.display_name,
          operatorDisplayName: operatorItem.label,
          valueDisplayName,
          valueType: fieldItem.valueType
        })
      })
      .then(() => this.initForm())
      .then(() => this.resetFields())
  }

  private initForm () {
    this.form.field = ''
    this.form.operator = ''
    this.form.value = ''
    this.form.valueType = ''
    this.valueComponent = ''
    this.innerWheres = []
    this.innerOptions = []
    this.innerOptionsProps = { label: 'display_name', value: 'value' }
    return this.$nextTick(() => {
      this.FormElement.clearValidate()
    })
  }

  private resetFields () {
    return Promise.resolve()
      .then(() => {
        const fields: string[] = this.SqlService.getFields()
        this.innerFields = this.getFields().filter((res: ISearchFields) => !fields.includes(res.name))
      })
  }

  private handleSubmit () {
    RouterService.replace(RouterService.getPath(), { ...this.$route.query, page: 1, _search: QueryString.stringify(this.SqlService.get()) || undefined })
  }

  private getFields (): ISearchFields[] {
    return this.fields ? JSON.parse(JSON.stringify(this.fields)) : []
  }

  private handleEditTag (index: number) {
    const item = this.SqlService.getItem(index) as ListItem
    const fieldItem = this.getFields().find((res: ISearchFields) => res.name === item.field) as ISearchFields
    Object.assign(this.form, {
      field: item.field,
      operator: item.operator,
      value: item.value,
      type: fieldItem.valueType
    })
    this.handleRemoveTag(index)
    this.innerWheres = getOperatorConfig(fieldItem.valueType)
    this.innerOptions = JSON.parse(JSON.stringify(fieldItem.options || []))
    this.valueComponent = fieldItem.valueType + 'Value'
  }

  private handleRemoveTag (index: number) {
    this.SqlService.removeItem(index)
    this.resetFields()
  }

  private handleChangeWhere () {
    if (this.form.valueType === 'date' || this.form.valueType === 'datetime') {
      this.form.value = ''
    }
  }

  private initSearchList () {
    const searchList = Object.values(QueryString.parse(RouterService.query('_search') as string)) as unknown as ListItem[]
    searchList.reduce((acc, row) => {
      return acc.then(() => this.createForm(row.field, row)).then(() => this.addWhere())
    }, Promise.resolve())
  }

  mounted () {
    this.innerFields = this.getFields()
    this.initSearchList()
  }
}
