
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IPagination, IUploadParams } from '@/interface/common'
import Pagination from '@/components/Table/TablePagination.vue'
import RouterService from '@/service/RouterService'
import MaskContainer from '@/components/MaskContainer.vue'
import CacheService from '@/service/CacheService'
import IOService from '@/service/IOService'
import BaseAbstract from '@/abstract/BaseAbstract'
import { humpToLine } from '../ToolsService'

@Component({
  components: {
    Pagination,
    MaskContainer
  }
})
export default class ImageManage extends Vue {
  readonly $refs!: {
    upload: any;
  }

  @Prop()
  type!: string

  @Prop()
  formService!: BaseAbstract

  @Prop({ default: true })
  isServiceOpen!: boolean

  private isDisableDeleteBtn = true
  private isShowModal = false
  private isLoading = false
  private pagination: IPagination = {
    page: 1,
    limit: 12,
    total: 0
  }

  private uploadParams: IUploadParams = {
    info_id: RouterService.query('id'),
    marking: RouterService.query('marking')
  }

  private list: any[] = []
  private selection: any[] = []

  private action = process.env.VUE_APP_BASEURL + '/' + humpToLine(this.formService.name) + '/fileStore'
  private headers = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: 'Bearer ' + CacheService.user.get('api_token')
  }

  // 已上传的数量(不论成功与失败)
  private uploaded = 0
  private resolve!: Function
  private reject!: Function

  open () {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.isShowModal = true
      this.fetchIndex()
    })
  }

  close () {
    this.isShowModal = false
    this.list = []
    this.selection = []
    this.pagination.page = 1
    if (this.isServiceOpen) {
      this.$destroy()
      this.$el.remove()
    }
    this.reject()
  }

  getSingleImage () {
    return this.selection[0] || {}
  }

  getMultipleImage () {
    return this.selection
  }

  private handleSubmit () {
    this.isShowModal = false
    if (this.type === 'single') {
      this.resolve(this.getSingleImage())
    } else {
      this.resolve(this.getMultipleImage())
    }
  }

  private onUploaded (res: any, file: any, fileList: any[]) {
    this.uploaded++
    if (this.uploaded === fileList.length) {
      this.fetchIndex()
      this.$refs.upload.clearFiles()
    }
  }

  private fetchIndex () {
    if (this.isLoading) return
    this.isLoading = true
    return IOService.get(humpToLine(this.formService.name) + '/fileIndex', {
      ...this.uploadParams,
      ...this.pagination
    })
      .then((res) => {
        this.list = res.data.data.map((item: any) => {
          item.active = this.isSelection(item.id)
          return item
        })
        this.pagination.total = res.data.total
        this.changeDeleteBtn()
      })
      .then(() => {
        this.isLoading = false
        this.uploaded = 0
      })
      .catch(() => {
        this.isLoading = false
      })
  }

  private handleChangePage () {
    return this.fetchIndex()
  }

  private handleDestroyMore () {
    const ids = this.list.filter((res: any) => res.active).map((res: any) => res.id)
    return IOService.post(`${humpToLine(this.formService.name)}/fileDestroy`, { ids })
      .then(() => {
        const full_urls = this.getDestroyImageFullUrl(ids)
        this.$emit('destroy', full_urls)
        this.removeSelection(ids)
        if (ids.length === this.list.length) {
          this.pagination.page = this.pagination.page > 1 ? --this.pagination.page : 1
        }
      })
  }

  private handleUnSelected () {
    this.list.forEach((res: any) => {
      res.active = false
    })
    this.removeSelection(this.list.map((res) => res.id))
  }

  private handleSelectAll () {
    this.list.forEach((res: any) => {
      res.active = true
    })
    this.addSelection(this.list.map((res) => res.id))
  }

  private handleSelectImage (v: any) {
    if (this.type === 'single') {
      this.clearSelection()
    }
    v.active = !v.active
    v.active ? this.addSelection([v.id]) : this.removeSelection([v.id])
  }

  private addSelection (ids: number[]) {
    this.removeSelection(ids)
    const list = this.list.filter((res) => ids.includes(res.id))
    this.selection = this.selection.concat(list)
    this.changeDeleteBtn()
  }

  private removeSelection (ids: number[]) {
    this.selection = this.selection.filter((res) => !ids.includes(res.id))
    this.changeDeleteBtn()
  }

  private clearSelection () {
    this.selection = []
    this.list.forEach((res: any) => {
      res.active = false
    })
  }

  private isSelection (id: number) {
    return this.selection.some((res) => res.id === id)
  }

  private changeDeleteBtn () {
    this.isDisableDeleteBtn = !this.list.some((res: any) => res.active)
  }

  private getDestroyImageFullUrl (ids: number[]) {
    return this.selection.filter((res) => ids.includes(res.id)).map((res) => res.full_url)
  }
}
