import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class ParamConfigService extends BaseAbstract {
  displayName = '配置'
  name = 'Config'
  controllerName = 'ParamsConfig'
  index (params: object) {
    return IOService.get('param_config', {
      ...params,
      guard_name: 'config'
    })
  }

  store (params: object) {
    return IOService.post('param_config', params)
  }

  show (id: number | string) {
    return IOService.get(`param_config/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`param_config/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`param_config/${id}`)
  }
}

export default new ParamConfigService()
