
import { Component, Vue } from 'vue-property-decorator'
import RacingDynamicService from '@/biz/service/Racing/RacingDynamicService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'

@Component
export default class RacingDynamicCheckedForm extends Vue {
  private RacingDynamicService = RacingDynamicService
  private form = {
    id: RouterService.query('id'),
    description: '',
    images: [],
    sort: '',
    dynamic_type: 3
  }

  private formFields = ValidateService.genRules({
    description: {
      prop: 'description',
      label: '内容',
      rule: [ValidateService.max(255)]
    },
    images: {
      prop: 'images',
      label: '图集',
      rule: [ValidateService.maxItem(9)]
    },
    sort: {
      prop: 'sort',
      label: '推荐'
    }
  })
}
