
import { Component, Vue } from 'vue-property-decorator'
import UserPersonalAuthService from '@/biz/service/User/UserPersonalAuthService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewUserMemberPersonalAuthForm extends Vue {
  private Service = UserPersonalAuthService
  private form = {
    id: RouterService.query('id'),
    name: '',
    id_card: '',
    certificates: [],
    status: UserPersonalAuthService.getStatusValue(1, '待审核'),
    refuse_reason: ''
  }

  private formFields: IFormFields = {}

  private initFormFields () {
    return ValidateService.genRules({
      name: {
        prop: 'name',
        label: '姓名',
        rule: [ValidateService.required, ValidateService.fullname]
      },
      id_card: {
        prop: 'id_card',
        label: '身份证',
        rule: [ValidateService.required, ValidateService.idcard]
      },
      certificates: {
        prop: 'certificates',
        label: '证件照',
        rule: [ValidateService.requiredArray, ValidateService.maxItem(2)]
      },
      status: {
        prop: 'status',
        label: '状态',
        rule: [ValidateService.required({ type: 'number' })]
      },
      refuse_reason: {
        prop: 'refuse_reason',
        label: '拒绝原因',
        rule: [ValidateService.max(255), () => {
          return {
            refuse_reason: {
              required: this.form.status === UserPersonalAuthService.getStatusValue(3, '已拒绝'),
              validator: (rule: any, value: string, callback: Function) => {
                if (this.form.status === UserPersonalAuthService.getStatusValue(3, '已拒绝') && !value) {
                  callback(new Error('请输入拒绝原因'))
                }
                callback()
              },
              trigger: 'blur'
            }
          }
        }]
      }
    })
  }

  private handleChange () {
    return Promise.resolve()
      .then(() => {
        this.formFields = this.initFormFields()
      })
  }
}
