
import { Component, Vue, Prop, Provide, Ref } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import DataRender from '@/components/DataRender.vue'
import BaseAbstract from '@/abstract/BaseAbstract'
import { PromiseResult } from '@/service/IOService'
import DialogService from '@/service/DialogService/DialogService'

interface Service extends BaseAbstract {
  show: Function;
  update: Function;
  store: Function;
}

@Component({
  components: {
    DataRender
  }
})
export default class FormRender extends Vue {
  @Ref()
  FormElement: any

  @Ref()
  drElement: any

  @Prop()
  form!: any

  @Prop()
  onSubmit!: Function

  @Prop()
  afterSubmit!: Function

  @Prop()
  onLoad!: Function

  @Prop()
  afterLoad!: Function

  @Prop()
  beforeLoad!: Function

  @Prop()
  Service!: Service

  @Prop({ default: '115px' })
  labelWidth!: string

  @Provide()
  formService = this.Service

  @Prop({ default: false })
  disableSubmitAndBackBtn!: boolean

  @Prop({ default: false })
  disableFooter!: boolean

  @Prop({ default: false })
  inline!: boolean

  private isShowSubmitValidateError = false
  private inDialog = false

  private handleLoad () {
    return Promise.resolve()
      .then(() => {
        if (this.beforeLoad) {
          return this.beforeLoad()
        }
      })
      .then(() => {
        if (this.onLoad) {
          return this.onLoad()
        } else {
          if (this.form.id && this.Service && this.Service.show) {
            return this.Service.show(this.form.id)
              .then((res: PromiseResult) => {
                Object.keys(this.form).forEach((key: string) => {
                  if (!(res.data[key] === null || res.data[key] === undefined)) {
                    this.form[key] = res.data[key]
                  }
                })
                return res
              })
          }
        }
      })
      .then((res) => {
        if (this.afterLoad) {
          return this.afterLoad(res)
        }
      })
  }

  handleSubmit () {
    return this.validate()
      .then(() => {
        if (this.onSubmit) {
          return this.onSubmit()
        } else {
          return Promise.resolve()
            .then(() => {
              if (this.form.id && this.Service) {
                return this.Service.update(this.form)
              } else {
                return this.Service.store({
                  ...this.form,
                  marking: RouterService.query('marking')
                })
              }
            })
        }
      })
      .then((res: any) => {
        if (this.afterSubmit) {
          return this.afterSubmit(res)
        }
      })
  }

  validate () {
    return this.FormElement.validate()
      .catch((err: any) => {
        this.isShowSubmitValidateError = true
        throw err
      })
  }

  private handleSubmitAndBack () {
    return this.handleSubmit()
      .then((res: PromiseResult) => {
        if (this.inDialog) {
          DialogService.done(res)
        } else {
          RouterService.go()
        }
        return res
      })
  }

  private handleReset () {
    this.FormElement.resetFields()
    this.$emit('formReset')
  }

  public validateField (props: string | string[]) {
    this.FormElement.validateField(props)
  }

  private handleSuccess () {
    this.$emit('success')
  }

  reload () {
    this.drElement.reload()
  }

  private mounted () {
    this.inDialog = this.drElement.$root.$el.className.indexOf('el-dialog__wrapper') > -1
  }
}
