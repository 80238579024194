
import { Component, Vue } from 'vue-property-decorator'
import RacingService from '@/biz/service/Racing/RacingService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintRallyEmptyApply extends Vue {
  private RacingService = RacingService

  private handleTableLoad () {
    return Promise.resolve([{
      racing_group_id: '　',
      racing_number: ' ',
      real_name: '　',
      phone: ' ',
      id_card: ' ',
      blood: ' ',
      car_type: ' ',
      displacement: ' ',
      car_weight: ' ',
      car_color: ' ',
      pilot_real_name: '　',
      pilot_id_card: ' ',
      pilot_phone: ' ',
      pilot_blood: ' ',
      pilot_nationality: ' ',
      nationality: ' '
    }])
  }
}
