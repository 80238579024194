import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class VersionService extends BaseAbstract {
  name = 'Version'
  index (params: { page: number }) {
    return IOService.get('version', params)
  }

  show (id: number | string) {
    return IOService.get(`version/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`version/${params.id}`, params)
  }
}

export default new VersionService()
