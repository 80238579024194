export default [
  {
    path: 'racing-manage',
    component: require('@/biz/components/Racing/Info/Index.vue').default,
    meta: {
      layoutSimple: true
    }
  },
  {
    path: 'racing-manage/form',
    component: require('@/biz/components/Racing/Info/Form.vue').default,
    meta: {
      layoutSimple: true
    }
  },
  {
    path: 'racing',
    component: require('@/biz/components/Racing/Main.vue').default,
    children: [
      {
        path: 'info',
        component: require('@/biz/components/Racing/Info/Form.vue').default
      },
      {
        path: 'racing-group-manage',
        component: require('@/biz/components/Racing/RacingGroup/Index.vue').default
      },
      {
        path: 'racing-group-manage/form',
        component: require('@/biz/components/Racing/RacingGroup/Form.vue').default
      },
      {
        path: 'racing-group',
        component: require('@/biz/components/Racing/RacingGroup/Main.vue').default,
        children: [
          {
            path: 'racing-round-manage',
            component: require('@/biz/components/Racing/RacingRound/Index.vue').default
          },
          {
            path: 'racing-round-manage/form',
            component: require('@/biz/components/Racing/RacingRound/Form.vue').default
          },
          {
            path: 'racing-apply',
            component: require('@/biz/components/Racing/RacingApply/Index.vue').default
          },
          {
            path: 'racing-apply/form',
            component: require('@/biz/components/Racing/RacingApply/Form.vue').default
          },
          {
            path: 'racing-round',
            component: require('@/biz/components/Racing/RacingRound/Main.vue').default,
            children: [
              {
                path: 'racing-result',
                component: require('@/biz/components/Racing/RacingResult/Index.vue').default
              },
              {
                path: 'racing-result/form',
                component: require('@/biz/components/Racing/RacingResult/Form.vue').default
              }
            ]
          }
        ]
      },
      {
        path: 'racing-notice',
        component: require('@/biz/components/Racing/RacingNotice/Index.vue').default
      },
      {
        path: 'racing-notice/form',
        component: require('@/biz/components/Racing/RacingNotice/Form.vue').default
      },
      {
        path: 'racing-comment',
        component: require('@/biz/components/Racing/RacingResult/Index.vue').default
      },
      {
        path: 'racing-dynamic',
        component: require('@/biz/components/Racing/RacingDynamic/Index.vue').default
      },
      {
        path: 'racing-dynamic/form',
        component: require('@/biz/components/Racing/RacingDynamic/Form.vue').default
      }
    ]
  }
]
