
import { Component, Vue } from 'vue-property-decorator'
import AdService from '@/biz/service/System/AdService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewSystemAdForm extends Vue {
  private Service = AdService
  private form = {
    id: RouterService.query('id'),
    position: '',
    is_active: 1,
    adable_type: '',
    adable_id: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    position: {
      prop: 'position',
      label: '位置',
      rule: [ValidateService.requiredNumber]
    },
    adable_type: {
      prop: 'adable_type',
      label: '信息类型',
      rule: [ValidateService.required({ trigger: 'change' })],
      options: [
        { display_name: '赛事', value: 'Racing' },
        { display_name: '商品', value: 'MallProduct' }
      ]
    },
    adable_id: {
      prop: 'adable_id',
      label: '信息ID',
      rule: [ValidateService.required]
    },
    is_active: {
      prop: 'is_active',
      label: '状态',
      options: [
        { display_name: '上架', value: 1 },
        { display_name: '下架', value: 0 }
      ]
    }
  })
}
