import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class RacingService extends BaseAbstract {
  displayName = '赛事'
  name = 'Racing'
  limit = 12
  cacheDatas = [] as any[]
  currentData = {
    id: 0,
    title: '',
    racing_type: 0,
    status: 0
  }

  index (params: { page: number }) {
    return IOService.get('racing', params)
      .then((res) => {
        if (res.data && res.data.data) {
          res.data.data = res.data.data.map((item: any) => {
            item.racing_type_text = this.getOptionsLabel('racing_type', item.racing_type)
            return item
          })
        }
        this.cacheDatas = res.data.data
        return res
      })
  }

  store (params: object) {
    return IOService.post('racing', {
      ...params,
      marking: RouterService.query('marking')
    })
  }

  show (id: number) {
    return IOService.get(`racing/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing/${params.id}`, params)
  }

  destroy (id: number) {
    return IOService.delete(`racing/${id}`)
  }

  updateStatus (params: { id: number; status: number }) {
    return IOService.post('racing/updateStatus', params)
      .then((res) => {
        this.currentData.status = params.status
        this.updateCacheListItem()
        return res
      })
  }

  updateDraw () {
    return IOService.post('racing/updateDraw', {
      racing_id: RouterService.query('racing_id')
    })
  }

  updateCacheListItem () {
    const item = this.cacheDatas.find((res) => res.id === this.currentData.id)
    if (item) {
      Object.assign(item, this.currentData)
    }
  }

  get (racing_id?: number) {
    racing_id = racing_id ? Number(racing_id) : Number(RouterService.query('racing_id'))
    return Promise.resolve()
      .then(() => {
        if (racing_id) {
          const data = this.cacheDatas.find((res: any) => res.id === racing_id)
          if (data) {
            Object.assign(this.currentData, data)
          } else {
            return IOService.get('racing/info', { id: racing_id })
              .then((res) => {
                this.cacheDatas.push(res.data)
                Object.assign(this.currentData, res.data)
              })
          }
        }
      })
  }
}

export default new RacingService()
