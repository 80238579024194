
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import MallProductService from '@/biz/service/Mall/MallProductService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'
import MallClassifyService from '@/biz/service/Mall/MallClassifyService'
import MallBrandService from '@/biz/service/Mall/MallBrandService'
import MallStoreService from '@/biz/service/Mall/MallStoreService'
import FormEditor from '@/components/Form/FormEditor.vue'
import EnterpriseService from '@/biz/service/Enterprise/EnterpriseService'

@Component({
  components: {
    FormEditor
  }
})
export default class MallProductForm extends Vue {
  @Ref()
  mallBrandElement!: any

  @Watch('form.mall_classify_id')
  onMallClassify (value: number) {
    this.formFields.mall_brand_id.options = this.mallBrandOptions.filter((res: { mall_classify_id: number }) => res.mall_classify_id === value)
    if (!this.formFields.mall_brand_id.options.some((res) => res.id === this.form.mall_brand_id)) {
      this.form.mall_brand_id = 0
    }
    this.mallBrandElement && this.mallBrandElement.reload()
  }

  private MallProductService = MallProductService
  private mallBrandOptions = [] as any[]
  private form = {
    id: RouterService.query('id'),
    mall_classify_id: 0,
    mall_brand_id: 0,
    mall_store_id: 0,
    title: '',
    cover_img: '',
    images: [],
    description: '',
    enterprise_id: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    mall_classify_id: {
      prop: 'mall_classify_id',
      label: '分类',
      rule: [ValidateService.requiredNumber],
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      }
    },
    mall_brand_id: {
      prop: 'mall_brand_id',
      label: '品牌',
      rule: [ValidateService.requiredNumber],
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      },
      placeholder: '请先选择分类'
    },
    mall_store_id: {
      prop: 'mall_store_id',
      label: '店铺',
      rule: [ValidateService.requiredNumber],
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      }
    },
    title: {
      prop: 'title',
      label: '标题',
      rule: [ValidateService.required, ValidateService.max(60)]
    },
    cover_img: {
      prop: 'cover_img',
      label: '封面图',
      rule: [ValidateService.required({ trigger: 'change' })]
    },
    images: {
      prop: 'images',
      label: '轮播图',
      rule: [ValidateService.maxItem(9)]
    },
    description: {
      prop: 'description',
      label: '商品描述',
      rule: [ValidateService.required]
    },
    enterprise_id: {
      prop: 'enterprise_id',
      label: '所属企业',
      options: [],
      rule: [ValidateService.requiredNumber],
      props: {
        value: 'id',
        label: 'display_name'
      }
    }
  })

  private handleBeforeLoad () {
    return Promise.all([MallClassifyService.getList(), MallBrandService.getList(), MallStoreService.getList(), EnterpriseService.getList()])
      .then((res) => {
        this.formFields.mall_classify_id.options = res[0]
        this.mallBrandOptions = res[1]
        this.formFields.mall_store_id.options = res[2]
        this.formFields.enterprise_id.options = res[3]
      })
  }

  private handleAfterSubmit (res: any) {
    RouterService.replace('/mall/mall-product/form', {
      ...this.$route.query,
      mall_product_id: res.data.id || this.form.id,
      active: 1
    })
  }

  created () {
    if (process.env.NODE_ENV === 'development' && !this.form.id) {
      this.form.mall_classify_id = 1
      this.form.mall_brand_id = 1
      this.form.mall_store_id = 1
      this.form.title = '标题'
      this.form.cover_img = '//p1.img.cctvpic.com/photoAlbum/page/performance/img/2021/6/29/1624936836064_231.jpg'
      this.form.description = '商品描述'
    }
  }
}
