import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class UserPersonalAuthService extends BaseAbstract {
  displayName = '个人认证'
  name = 'UserPersonalAuth'
  index (params: any) {
    return IOService.get('user_personal_auth', params)
  }

  show (id: string) {
    return IOService.get(`user_personal_auth/${id}`)
  }

  update (params: { id: string }) {
    return IOService.put(`user_personal_auth/${params.id}`, params)
  }

  destroy (id: number) {
    return IOService.delete(`user_personal_auth/${id}`)
  }
}

export default new UserPersonalAuthService()
