
import { Component, Vue } from 'vue-property-decorator'
import RacingResultService from '@/biz/service/Racing/RacingResultService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import { arrayChunk } from '@/service/ToolsService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'
import ConstService from '@/service/ConstService'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintRallyResult extends Vue {
  private RacingGroupService = RacingGroupService
  private RacingRoundService = RacingRoundService
  private list = [] as any[][]

  private handleLoad () {
    return RacingResultService.printResultTable()
      .then((res) => {
        res.data = res.data.map((item: any) => {
          item._display_name = `${item.real_name}(${ConstService.getNationalityOptionsLabel(item.nationality)})<br/>${item.pilot_real_name}(${ConstService.getNationalityOptionsLabel(item.pilot_nationality)})`
          return item
        })
        this.list = arrayChunk(res.data, 10)
      })
  }

  private handleTableLoad (index: number) {
    return Promise.resolve(this.list[index])
  }
}
