
import FormMixins from './FormMixins'
import { Component, Mixins, Inject, Prop } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VueNeditorWrap from 'vue-neditor-wrap-wx'
import ImageManageService from '@/service/ImageManageService/ImageManageService'
import BaseAbstract from '@/abstract/BaseAbstract'
import { resolveImageUrl } from '@/service/ToolsService'

@Component({
  components: {
    VueNeditorWrap
  }
})
export default class FormEditor extends Mixins(FormMixins) {
  readonly $refs!: {
    NEditor: any;
  }

  @Inject('formService')
  formService!: BaseAbstract

  @Prop()
  fieldService!: BaseAbstract

  @Prop({ default: 240 })
  height!: number

  private ImageManageService!: ImageManageService

  private editor!: any

  private editorConfig = {
    // 你的UEditor资源存放的路径,相对于打包后的index.html
    UEDITOR_HOME_URL: '/NEditor/',
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: this.height,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 关闭自动保存
    enableAutoSave: false,
    toolbars: [
      [
        'fullscreen',
        'source',
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'fontborder',
        'strikethrough',
        'removeformat',
        'searchreplace',
        'autotypeset',
        'blockquote',
        'pasteplain',
        'forecolor',
        'backcolor',
        'insertorderedlist',
        'insertunorderedlist',
        'lineheight',
        'fontsize',
        'indent',
        'justifyleft',
        'justifycenter',
        'justifyright',
        'justifyjustify',
        'insertimage',
        'insertimages',
        'horizontal'
      ]
    ]
  }

  private handleChangeModal () {
    return this.ImageManageService.open('multiple', this.formService || this.fieldService)
      .then((data: object[]) => {
        const imgs = data.map((res: any) => res.full_url)
          .map((img: string) => '<p><img src="' + resolveImageUrl(img, '1080') + '" /></p>')
          .join('')
        this.editor.execCommand('inserthtml', imgs)
      })
  }

  private editorReady (editor: any) {
    this.editor = editor
  }

  private editorBeforeInit () {
    this.$refs.NEditor.registerButton({
      name: 'insertimages',
      icon: require('@/assets/images/editor-images.png'),
      tip: '图片上传',
      handler: () => this.handleChangeModal()
    })
  }

  created () {
    this.innerValue = this.innerValue || ''
    this.ImageManageService = new ImageManageService()
  }
}
