const nationalityOptions = [
  {
    name: '亚洲',
    disabled: false,
    id: 900100,
    children: [
      {
        name: '中国',
        disabled: false,
        id: 900101
      },
      {
        name: '日本',
        disabled: false,
        id: 900102
      },
      {
        name: '韩国',
        disabled: false,
        id: 900103
      },
      {
        name: '朝鲜',
        disabled: false,
        id: 900104
      },
      {
        name: '蒙古',
        disabled: false,
        id: 900105
      },
      {
        name: '越南',
        disabled: false,
        id: 900106
      },
      {
        name: '柬埔寨',
        disabled: false,
        id: 900107
      },
      {
        name: '老挝',
        disabled: false,
        id: 900108
      },
      {
        name: '泰国',
        disabled: false,
        id: 900109
      },
      {
        name: '缅甸',
        disabled: false,
        id: 900110
      },
      {
        name: '菲律宾',
        disabled: false,
        id: 900111
      },
      {
        name: '文莱',
        disabled: false,
        id: 900112
      },
      {
        name: '马来西亚',
        disabled: false,
        id: 900113
      },
      {
        name: '新加坡',
        disabled: false,
        id: 900114
      },
      {
        name: '印度尼西亚',
        disabled: false,
        id: 900115
      },
      {
        name: '东帝汶',
        disabled: false,
        id: 900116
      },
      {
        name: '尼泊尔',
        disabled: false,
        id: 900117
      },
      {
        name: '不丹',
        disabled: false,
        id: 900118
      },
      {
        name: '巴基斯坦',
        disabled: false,
        id: 900119
      },
      {
        name: '印度',
        disabled: false,
        id: 900120
      },
      {
        name: '孟加拉国',
        disabled: false,
        id: 900121
      },
      {
        name: '马尔代夫',
        disabled: false,
        id: 900122
      },
      {
        name: '斯里兰卡',
        disabled: false,
        id: 900123
      },
      {
        name: '哈萨克斯坦',
        disabled: false,
        id: 900124
      },
      {
        name: '吉尔吉斯斯坦',
        disabled: false,
        id: 900125
      },
      {
        name: '塔吉克斯坦',
        disabled: false,
        id: 900126
      },
      {
        name: '乌兹别克斯坦',
        disabled: false,
        id: 900127
      },
      {
        name: '土库曼斯坦',
        disabled: false,
        id: 900128
      },
      {
        name: '阿富汗',
        disabled: false,
        id: 900129
      },
      {
        name: '伊朗',
        disabled: false,
        id: 900130
      },
      {
        name: '伊拉克',
        disabled: false,
        id: 900131
      },
      {
        name: '叙利亚',
        disabled: false,
        id: 900132
      },
      {
        name: '黎巴嫩',
        disabled: false,
        id: 900133
      },
      {
        name: '以色列',
        disabled: false,
        id: 900134
      },
      {
        name: '巴勒斯坦',
        disabled: false,
        id: 900135
      },
      {
        name: '约旦',
        disabled: false,
        id: 900136
      },
      {
        name: '沙特阿拉伯',
        disabled: false,
        id: 900137
      },
      {
        name: '巴林',
        disabled: false,
        id: 900138
      },
      {
        name: '卡塔尔',
        disabled: false,
        id: 900139
      },
      {
        name: '科威特',
        disabled: false,
        id: 900140
      },
      {
        name: '阿拉伯联合酋长国',
        disabled: false,
        id: 900141
      },
      {
        name: '阿曼',
        disabled: false,
        id: 900142
      },
      {
        name: '也门',
        disabled: false,
        id: 900143
      },
      {
        name: '格鲁吉亚',
        disabled: false,
        id: 900144
      },
      {
        name: '亚美尼亚',
        disabled: false,
        id: 900145
      },
      {
        name: '阿塞拜疆',
        disabled: false,
        id: 900146
      },
      {
        name: '土耳其',
        disabled: false,
        id: 900147
      },
      {
        name: '塞浦路斯',
        disabled: false,
        id: 900148
      }
    ]
  },
  {
    name: '欧洲',
    disabled: false,
    id: 900200,
    children: [
      {
        name: '冰岛',
        disabled: false,
        id: 900201
      },
      {
        name: '丹麦',
        disabled: false,
        id: 900202
      },
      {
        name: '挪威',
        disabled: false,
        id: 900203
      },
      {
        name: '瑞典',
        disabled: false,
        id: 900204
      },
      {
        name: '芬兰',
        disabled: false,
        id: 900205
      },
      {
        name: '英国',
        disabled: false,
        id: 900206
      },
      {
        name: '爱尔兰',
        disabled: false,
        id: 900207
      },
      {
        name: '法国',
        disabled: false,
        id: 900208
      },
      {
        name: '摩纳哥',
        disabled: false,
        id: 900209
      },
      {
        name: '荷兰',
        disabled: false,
        id: 900210
      },
      {
        name: '比利时',
        disabled: false,
        id: 900211
      },
      {
        name: '卢森堡',
        disabled: false,
        id: 900212
      },
      {
        name: '德国',
        disabled: false,
        id: 900213
      },
      {
        name: '瑞士',
        disabled: false,
        id: 900214
      },
      {
        name: '列支敦士登',
        disabled: false,
        id: 900215
      },
      {
        name: '波兰',
        disabled: false,
        id: 900216
      },
      {
        name: '捷克',
        disabled: false,
        id: 900217
      },
      {
        name: '斯洛伐克',
        disabled: false,
        id: 900218
      },
      {
        name: '奥地利',
        disabled: false,
        id: 900219
      },
      {
        name: '匈牙利',
        disabled: false,
        id: 900220
      },
      {
        name: '爱沙尼亚',
        disabled: false,
        id: 900221
      },
      {
        name: '拉脱维亚',
        disabled: false,
        id: 900222
      },
      {
        name: '立陶宛',
        disabled: false,
        id: 900223
      },
      {
        name: '白俄罗斯',
        disabled: false,
        id: 900224
      },
      {
        name: '乌克兰',
        disabled: false,
        id: 900225
      },
      {
        name: '摩尔多瓦',
        disabled: false,
        id: 900226
      },
      {
        name: '俄罗斯',
        disabled: false,
        id: 900227
      },
      {
        name: '葡萄牙',
        disabled: false,
        id: 900228
      },
      {
        name: '西班牙',
        disabled: false,
        id: 900229
      },
      {
        name: '安道尔',
        disabled: false,
        id: 900230
      },
      {
        name: '意大利',
        disabled: false,
        id: 900231
      },
      {
        name: '圣马力诺',
        disabled: false,
        id: 900232
      },
      {
        name: '梵蒂冈',
        disabled: false,
        id: 900233
      },
      {
        name: '马耳他',
        disabled: false,
        id: 900234
      },
      {
        name: '斯洛文尼亚',
        disabled: false,
        id: 900235
      },
      {
        name: '克罗地亚',
        disabled: false,
        id: 900236
      },
      {
        name: '波斯尼亚和黑塞哥维那',
        disabled: false,
        id: 900237
      },
      {
        name: '黑山',
        disabled: false,
        id: 900238
      },
      {
        name: '塞尔维亚',
        disabled: false,
        id: 900239
      },
      {
        name: '阿尔巴尼亚',
        disabled: false,
        id: 900240
      },
      {
        name: '北马其顿',
        disabled: false,
        id: 900241
      },
      {
        name: '保加利亚',
        disabled: false,
        id: 900242
      },
      {
        name: '希腊',
        disabled: false,
        id: 900243
      },
      {
        name: '罗马尼亚',
        disabled: false,
        id: 900244
      }
    ]
  },
  {
    name: '非洲',
    disabled: false,
    id: 900300,
    children: [
      {
        name: '埃及',
        disabled: false,
        id: 900301
      },
      {
        name: '利比亚',
        disabled: false,
        id: 900302
      },
      {
        name: '突尼斯',
        disabled: false,
        id: 900303
      },
      {
        name: '阿尔及利亚',
        disabled: false,
        id: 900304
      },
      {
        name: '摩洛哥',
        disabled: false,
        id: 900305
      },
      {
        name: '尼日尔',
        disabled: false,
        id: 900306
      },
      {
        name: '布基纳法索',
        disabled: false,
        id: 900307
      },
      {
        name: '马里',
        disabled: false,
        id: 900308
      },
      {
        name: '毛里塔尼亚',
        disabled: false,
        id: 900309
      },
      {
        name: '尼日利亚',
        disabled: false,
        id: 900310
      },
      {
        name: '贝宁',
        disabled: false,
        id: 900311
      },
      {
        name: '多哥',
        disabled: false,
        id: 900312
      },
      {
        name: '加纳',
        disabled: false,
        id: 900313
      },
      {
        name: '科特迪瓦',
        disabled: false,
        id: 900314
      },
      {
        name: '利比里亚',
        disabled: false,
        id: 900315
      },
      {
        name: '塞拉利昂',
        disabled: false,
        id: 900316
      },
      {
        name: '几内亚',
        disabled: false,
        id: 900317
      },
      {
        name: '几内亚比绍',
        disabled: false,
        id: 900318
      },
      {
        name: '塞内加尔',
        disabled: false,
        id: 900319
      },
      {
        name: '冈比亚',
        disabled: false,
        id: 900320
      },
      {
        name: '佛得角',
        disabled: false,
        id: 900321
      },
      {
        name: '乍得',
        disabled: false,
        id: 900322
      },
      {
        name: '中非',
        disabled: false,
        id: 900323
      },
      {
        name: '喀麦隆',
        disabled: false,
        id: 900324
      },
      {
        name: '刚果民主共和国',
        disabled: false,
        id: 900325
      },
      {
        name: '刚果共和国',
        disabled: false,
        id: 900326
      },
      {
        name: '加蓬',
        disabled: false,
        id: 900327
      },
      {
        name: '赤道几内亚',
        disabled: false,
        id: 900328
      },
      {
        name: '圣多美和普林西比',
        disabled: false,
        id: 900329
      },
      {
        name: '吉布提',
        disabled: false,
        id: 900330
      },
      {
        name: '索马里',
        disabled: false,
        id: 900331
      },
      {
        name: '厄立特里亚',
        disabled: false,
        id: 900332
      },
      {
        name: '埃塞俄比亚',
        disabled: false,
        id: 900333
      },
      {
        name: '苏丹',
        disabled: false,
        id: 900334
      },
      {
        name: '南苏丹',
        disabled: false,
        id: 900335
      },
      {
        name: '肯尼亚',
        disabled: false,
        id: 900336
      },
      {
        name: '坦桑尼亚',
        disabled: false,
        id: 900337
      },
      {
        name: '乌干达',
        disabled: false,
        id: 900338
      },
      {
        name: '卢旺达',
        disabled: false,
        id: 900339
      },
      {
        name: '布隆迪',
        disabled: false,
        id: 900340
      },
      {
        name: '塞舌尔',
        disabled: false,
        id: 900341
      },
      {
        name: '安哥拉',
        disabled: false,
        id: 900342
      },
      {
        name: '赞比亚',
        disabled: false,
        id: 900343
      },
      {
        name: '马拉维',
        disabled: false,
        id: 900344
      },
      {
        name: '莫桑比克',
        disabled: false,
        id: 900345
      },
      {
        name: '纳米比亚',
        disabled: false,
        id: 900346
      },
      {
        name: '博茨瓦纳',
        disabled: false,
        id: 900347
      },
      {
        name: '津巴布韦',
        disabled: false,
        id: 900348
      },
      {
        name: '南非',
        disabled: false,
        id: 900349
      },
      {
        name: '斯威士兰',
        disabled: false,
        id: 900350
      },
      {
        name: '莱索托',
        disabled: false,
        id: 900351
      },
      {
        name: '马达加斯加',
        disabled: false,
        id: 900352
      },
      {
        name: '毛里求斯',
        disabled: false,
        id: 900353
      },
      {
        name: '科摩罗',
        disabled: false,
        id: 900354
      }
    ]
  },
  {
    name: '大洋洲',
    disabled: false,
    id: 900400,
    children: [
      {
        name: '澳大利亚',
        disabled: false,
        id: 900401
      },
      {
        name: '新西兰',
        disabled: false,
        id: 900402
      },
      {
        name: '帕劳',
        disabled: false,
        id: 900403
      },
      {
        name: '密克罗尼西亚联邦',
        disabled: false,
        id: 900404
      },
      {
        name: '马绍尔群岛',
        disabled: false,
        id: 900405
      },
      {
        name: '瑙鲁',
        disabled: false,
        id: 900406
      },
      {
        name: '基里巴斯',
        disabled: false,
        id: 900407
      },
      {
        name: '巴布亚新几内亚',
        disabled: false,
        id: 900408
      },
      {
        name: '所罗门群岛',
        disabled: false,
        id: 900409
      },
      {
        name: '瓦努阿图',
        disabled: false,
        id: 900410
      },
      {
        name: '斐济',
        disabled: false,
        id: 900411
      },
      {
        name: '图瓦卢',
        disabled: false,
        id: 900412
      },
      {
        name: '萨摩亚',
        disabled: false,
        id: 900413
      },
      {
        name: '汤加',
        disabled: false,
        id: 900414
      },
      {
        name: '纽埃',
        disabled: false,
        id: 900415
      },
      {
        name: '库克群岛',
        disabled: false,
        id: 900416
      }
    ]
  },
  {
    name: '北美洲',
    disabled: false,
    id: 900500,
    children: [
      {
        name: '加拿大',
        disabled: false,
        id: 900501
      },
      {
        name: '美国',
        disabled: false,
        id: 900502
      },
      {
        name: '墨西哥',
        disabled: false,
        id: 900503
      },
      {
        name: '危地马拉',
        disabled: false,
        id: 900504
      },
      {
        name: '伯利兹',
        disabled: false,
        id: 900505
      },
      {
        name: '萨尔瓦多',
        disabled: false,
        id: 900506
      },
      {
        name: '洪都拉斯',
        disabled: false,
        id: 900507
      },
      {
        name: '尼加拉瓜',
        disabled: false,
        id: 900508
      },
      {
        name: '哥斯达黎加',
        disabled: false,
        id: 900509
      },
      {
        name: '巴拿马',
        disabled: false,
        id: 900510
      },
      {
        name: '巴哈马',
        disabled: false,
        id: 900511
      },
      {
        name: '古巴',
        disabled: false,
        id: 900512
      },
      {
        name: '牙买加',
        disabled: false,
        id: 900513
      },
      {
        name: '海地',
        disabled: false,
        id: 900514
      },
      {
        name: '多米尼加共和国',
        disabled: false,
        id: 900515
      },
      {
        name: '圣基茨和尼维斯',
        disabled: false,
        id: 900516
      },
      {
        name: '安提瓜和巴布达',
        disabled: false,
        id: 900517
      },
      {
        name: '多米尼克',
        disabled: false,
        id: 900518
      },
      {
        name: '圣卢西亚',
        disabled: false,
        id: 900519
      },
      {
        name: '巴巴多斯',
        disabled: false,
        id: 900520
      },
      {
        name: '圣文森特和格林纳丁斯',
        disabled: false,
        id: 900521
      },
      {
        name: '格林纳达',
        disabled: false,
        id: 900522
      },
      {
        name: '特立尼达和多巴哥',
        disabled: false,
        id: 900523
      }
    ]
  },
  {
    name: '南美洲',
    disabled: false,
    id: 900600,
    children: [
      {
        name: '哥伦比亚',
        disabled: false,
        id: 900601
      },
      {
        name: '委内瑞拉',
        disabled: false,
        id: 900602
      },
      {
        name: '圭亚那',
        disabled: false,
        id: 900603
      },
      {
        name: '苏里南',
        disabled: false,
        id: 900604
      },
      {
        name: '厄瓜多尔',
        disabled: false,
        id: 900605
      },
      {
        name: '秘鲁',
        disabled: false,
        id: 900606
      },
      {
        name: '玻利维亚',
        disabled: false,
        id: 900607
      },
      {
        name: '巴西',
        disabled: false,
        id: 900608
      },
      {
        name: '智利',
        disabled: false,
        id: 900609
      },
      {
        name: '阿根廷',
        disabled: false,
        id: 900610
      },
      {
        name: '乌拉圭',
        disabled: false,
        id: 900611
      },
      {
        name: '巴拉圭',
        disabled: false,
        id: 900612
      }
    ]
  }
]

export default nationalityOptions
