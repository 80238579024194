
import DialogService from '@/service/DialogService/DialogService'
import RouterService from '@/service/RouterService'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ActionButtons extends Vue {
  @Prop()
  Service!: any

  private RouterService = RouterService
  private isShowTestBtns = process.env.VUE_APP_ENV !== 'prod'

  private handlePrintApplyTable () {
    return DialogService.show(require('../../RacingPrint/ApplyList.vue').default, {
      top: '7vh',
      width: '210mm'
    })
  }

  private handlePrintEmptyApplyTable () {
    return DialogService.show(require('../../RacingPrint/EmptyApply.vue').default, {
      top: '7vh',
      width: '210mm'
    })
  }

  private handlePrintInsureTable () {
    return DialogService.show(require('../../RacingPrint/Insure.vue').default, {
      top: '7vh',
      width: '210mm'
    })
  }

  private handleBatchCreate () {
    return DialogService.prompt({
      title: '添加数量'
    })
      .then((data: number) => this.Service.batchCreate(data))
      .then((res: any) => {
        this.$emit('reload', res)
        return res
      })
  }

  private handleBatchDestroy () {
    return this.Service.batchDestroy()
      .then((res: any) => {
        this.$emit('reload', res)
        return res
      })
  }
}
