
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import MallClassifyService from '@/biz/service/Mall/MallClassifyService'

@Component
export default class MallClassifyMain extends Vue {
  private menuList: any[] = []
  private active = RouterService.query('mall_classify_id')
  private fullPath = ''

  private handleLoad () {
    return MallClassifyService.getList()
      .then((data) => {
        this.menuList = data
      })
  }

  private handleTabClick (res: any) {
    const mall_classify_id = this.menuList[res.index].id
    RouterService.replace('', { mall_classify_id, mall_classify_spec_id: '' })
  }

  private handleAddGroup () {
    RouterService.push('/mall/mall-classify-manage/form', {
      ...this.$route.query
    })
  }

  created () {
    this.fullPath = RouterService.getFullPath()
    this.active = RouterService.query('mall_classify_id')
  }
}
