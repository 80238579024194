
import ValidateService from '@/service/ValidateService'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import RacingApplyService from '@/biz/service/Racing/RacingApplyService'

@Component
export default class ServiceDialogPrompt extends Vue {
  @Ref()
  formElement!: any

  @Prop()
  params!: { racing_apply_id: number }

  private form = {
    racing_round_id: 0
  }

  private formFields = ValidateService.genRules({
    racing_round_id: {
      prop: 'racing_round_id',
      label: '回合',
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      },
      rule: [ValidateService.requiredNumber]
    }
  })

  private handleLoad () {
    return RacingRoundService.getList()
      .then((data) => {
        this.formFields.racing_round_id.options = data
      })
  }

  private handleSubmit () {
    return this.formElement.validate()
      .then(() => RacingApplyService.enterIntoRound({
        ...this.form,
        racing_apply_id: this.params.racing_apply_id
      }))
      .then((res: any) => {
        this.$emit('done')
        return res
      })
  }
}
