
import ConstService from '@/service/ConstService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import GeneralApply from './components/GeneralApply.vue'
import RallyApply from './components/RallyApply.vue'
import T3Apply from './components/T3Apply.vue'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    GeneralApply,
    RallyApply,
    T3Apply
  }
})
export default class RacingPrintApply extends Vue {
  @Prop()
  params!: { row: any }

  private current = ''

  created () {
    if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'Rally') {
      this.current = 'RallyApply'
    } else if (ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type) === 'T3') {
      this.current = 'T3Apply'
    } else {
      this.current = 'GeneralApply'
    }
  }
}
