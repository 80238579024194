
import DialogService from '@/service/DialogService/DialogService'
import { Component, Vue } from 'vue-property-decorator'
import RacingDynamicService from '@/biz/service/Racing/RacingDynamicService'
import RacingDynamicCommentService from '@/biz/service/Comment/RacingDynamicCommentService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'

@Component
export default class RacingDynamicVideoIndex extends Vue {
  private Service = RacingDynamicService
  private racingGroupList = []
  private racingRoundList = []
  private props = {
    value: 'id',
    label: 'display_name'
  }

  private handleLoad (params: any) {
    return this.Service.index({
      ...params,
      dynamic_type: RacingDynamicService.getOptionsValue('dynamic_type', 3, '赛事视频')
    })
  }

  private handleComment (id: number) {
    return DialogService.show(require('@/biz/components/components/Comments.vue').default, { id, CommentService: RacingDynamicCommentService })
  }

  private handleBeforeLoad () {
    return RacingGroupService.getList()
      .then((data) => {
        this.racingGroupList = data
      })
      .then(() => RacingRoundService.getAll())
      .then((res) => {
        this.racingRoundList = res.data
      })
  }
}
