
import { ISearchFields } from '@/interface/common'
import ConstService from '@/service/ConstService'
import { Component, Vue } from 'vue-property-decorator'
import Service from './Service'

@Component
export default class ViewUserMemberWechatIndex extends Vue {
  private Service = Service

  private searchFields: ISearchFields[] = [
    {
      name: 'nickname',
      display_name: '昵称',
      valueType: 'string'
    },
    {
      name: 'follow_official_account_scene',
      display_name: '关注来源',
      valueType: 'string'
    },
    {
      name: 'is_follow',
      display_name: '是否关注公众号',
      valueType: 'intOptions',
      options: ConstService.getBoolOptions()
    }
  ]
}
