
import DialogService from '@/service/DialogService/DialogService'
import RouterService from '@/service/RouterService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import RacingService from '@/biz/service/Racing/RacingService'

@Component
export default class ActionButtons extends Vue {
  @Prop()
  Service!: any

  private RouterService = RouterService
  private RacingRoundService = RacingRoundService
  private RacingService = RacingService
  private isShowTestBtns = process.env.VUE_APP_ENV !== 'prod'

  private handleCreateStartTable () {
    return this.Service.createStartTable()
      .then((res: any) => {
        this.$emit('reload')
        return res
      })
  }

  private handleCreateResultTable () {
    return this.Service.createResultTable()
      .then((res: any) => {
        this.$emit('reload')
        return res
      })
  }

  private handleTogglePublishStartTable () {
    return Promise.resolve()
      .then(() => {
        const changeValue = RacingRoundService.currentData.is_publish_start === 0 ? 1 : 0
        return this.$confirm(`您真的要${changeValue ? '公布' : '关闭'}吗？`, '提示', {
          type: 'warning'
        })
          .then(() => this.Service.togglePublishStartTable(changeValue))
          .then((res) => {
            return RacingRoundService.updateCacheData({ is_publish_start: changeValue })
              .then(() => res)
          })
      })
  }

  private handleTogglePublishResultTable () {
    return Promise.resolve()
      .then(() => {
        const changeValue = RacingRoundService.currentData.is_publish_result === 0 ? 1 : 0
        return this.$confirm(`您真的要${changeValue ? '公布' : '关闭'}吗？`, '提示', {
          type: 'warning'
        })
          .then(() => this.Service.togglePublishResultTable(changeValue))
          .then((res) => {
            return RacingRoundService.updateCacheData({ is_publish_result: changeValue })
              .then(() => res)
          })
      })
  }

  private handlePrintStartTable () {
    return DialogService.show(require('../../RacingPrint/Start.vue').default, {
      top: '7vh',
      width: RacingService.currentData.racing_type === RacingService.getOptionsValue('racing_type', 3, '拉力赛') ? '297mm' : '210mm'
    })
  }

  private handlePrintResultTable () {
    return DialogService.show(require('../../RacingPrint/Result.vue').default, {
      top: '7vh',
      width: RacingService.currentData.racing_type === RacingService.getOptionsValue('racing_type', 2, '多车齐发') ? '210mm' : '297mm'
    })
  }

  private handlePrintEmptyResultTable () {
    return DialogService.show(require('../../RacingPrint/EmptyResult.vue').default, {
      top: '7vh',
      width: RacingService.currentData.racing_type === RacingService.getOptionsValue('racing_type', 2, '多车齐发') ? '210mm' : '297mm'
    })
  }

  private handlePrintPrizeTable () {
    return DialogService.show(require('../../RacingPrint/Prize.vue').default, {
      top: '7vh',
      width: '297mm'
    })
  }

  private handlePrintTotalResultTable () {
    return DialogService.show(require('../../RacingPrint/TotalResult.vue').default, {
      top: '7vh',
      width: '210mm'
    })
  }

  private handleBatchGenerateResult () {
    return this.Service.batchGenerateResult()
      .then((res: any) => {
        this.$emit('reload')
        return res
      })
  }

  private handleBatchClearResult () {
    return this.Service.batchClearResult()
      .then((res: any) => {
        this.$emit('reload')
        return res
      })
  }

  private handleBatchClearMember () {
    return this.Service.batchClearMember()
      .then((res: any) => {
        this.$emit('reload')
        return res
      })
  }

  private handleRewardCoin () {
    return Promise.resolve()
      .then(() => {
        if (RacingRoundService.currentData.coin_reward_rule) {
          return this.$confirm('您真的要发放奖励吗？', '提示', {
            type: 'warning'
          })
            .then(() => {
              return this.Service.rewardCoin()
            })
        }
      })
  }
}
