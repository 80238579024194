
import FormMixins from './FormMixins'
import { Component, Mixins, Inject, Prop } from 'vue-property-decorator'
import { OptionItem } from '@/service/ConstService'
import BaseAbstract from '@/abstract/BaseAbstract'

@Component
export default class FormSelect extends Mixins(FormMixins) {
  @Inject('formService')
  formService!: BaseAbstract

  @Prop()
  fieldService!: BaseAbstract

  @Prop()
  onLoad!: Function

  private props = {
    value: 'value',
    label: 'display_name'
  }

  private onChange (value: number) {
    this.$emit('change', value)
  }

  private options = [] as OptionItem[]
  private loading = false

  private init () {
    Object.assign(this.props, this.field.props)
    this.innerValue = this.innerValue || ''
    if (this.field.options) {
      this.options = this.field.options
    } else {
      this.options = this.fieldService ? this.fieldService.getOptions(this.field.prop) : this.formService.getOptions(this.field.prop)
    }
  }

  reload () {
    this.loading = true
    return Promise.resolve()
      .then(() => {
        if (this.onLoad) {
          return this.onLoad()
        }
      })
      .then(() => {
        this.loading = false
        this.init()
      })
      .catch(() => {
        this.loading = false
      })
  }

  created () {
    this.init()
  }
}
