
import { Component, Vue } from 'vue-property-decorator'
import ConfigOptionService from '@/biz/service/System/ConfigOptionService'
import RouterService from '@/service/RouterService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'

@Component
export default class PlatformConfigBaseForm extends Vue {
  private Service = ConfigOptionService
  private form = {
    id: RouterService.query('id'),
    display_name: '',
    color: '',
    config_id: RouterService.query('config_id'),
    sort: '',
    value: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '名称',
      rule: [ValidateService.required, ValidateService.max(60)]
    },
    value: {
      prop: 'value',
      label: '值',
      rule: [ValidateService.required({ type: 'number' })]
    },
    color: {
      prop: 'color',
      label: '颜色值'
    },
    sort: {
      prop: 'sort',
      label: '排序'
    }
  })
}
