
import { Component, Vue } from 'vue-property-decorator'
import EnterprisePositionService from '@/biz/service/Enterprise/EnterprisePositionService'
import EnterpriseEmployeeService from '@/biz/service/Enterprise/EnterpriseEmployeeService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewEnterprisePositionForm extends Vue {
  private Service = EnterpriseEmployeeService
  private form = {
    id: RouterService.query('id'),
    enterprise_position_id: '',
    phone: ''
  }

  private formFields = ValidateService.genRules({
    enterprise_position_id: {
      prop: 'enterprise_position_id',
      label: '职位',
      options: [],
      props: {
        checkStrictly: true
      },
      rule: [ValidateService.requiredNumber]
    },
    phone: {
      prop: 'phone',
      label: '手机号',
      rule: [RouterService.query('id') ? ValidateService.optional : ValidateService.required, ValidateService.mobile],
      disabled: !!RouterService.query('id')
    }
  })

  private handleBeforeLoad () {
    return EnterprisePositionService.getTree()
      .then((data) => {
        this.formFields.enterprise_position_id.options = data
      })
  }
}
