
import { Component, Vue } from 'vue-property-decorator'
import RouterService from '@/service/RouterService'
import CheckIndex from './Check/Index.vue'
import CheckedIndex from './Checked/Index.vue'
import VideoIndex from './Video/Index.vue'

@Component({
  components: {
    CheckIndex,
    CheckedIndex,
    VideoIndex
  }
})
export default class RacingDynamicIndex extends Vue {
  private active = RouterService.getSearchQuery('active') || 'Check'
  private current = this.active + 'Index'

  private handleTabClick (res: any) {
    RouterService.replace('', { _search: RouterService.getSearchQueryString({ active: res.name }) })
  }
}
