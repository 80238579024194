
import FormMixins from './FormMixins'
import { Component, Mixins, Inject, Prop } from 'vue-property-decorator'
import { OptionItem } from '@/service/ConstService'
import BaseAbstract from '@/abstract/BaseAbstract'

@Component
export default class FormRadio extends Mixins(FormMixins) {
  @Inject('formService')
  formService!: BaseAbstract

  @Prop()
  fieldService!: BaseAbstract

  private props = {
    value: 'value',
    label: 'display_name'
  }

  private onChange (value: number) {
    this.$emit('change', value)
  }

  private options = [] as OptionItem[]

  created () {
    Object.assign(this.props, this.field.props)
    if (this.field.options) {
      this.options = this.field.options
    } else {
      this.options = this.fieldService ? this.fieldService.getOptions(this.field.prop) : this.formService.getOptions(this.field.prop)
    }
  }
}
