import { render, staticRenderFns } from "./GeneralInsure.vue?vue&type=template&id=689fb7e2"
import script from "./GeneralInsure.vue?vue&type=script&lang=ts"
export * from "./GeneralInsure.vue?vue&type=script&lang=ts"
import style0 from "./GeneralInsure.vue?vue&type=style&index=0&id=689fb7e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports