
import { resolveImageUrl } from '@/service/ToolsService'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CoverImage extends Vue {
  @Prop()
  src!: string

  @Prop()
  zip!: string

  @Prop({ default: 'cover' })
  fit!: string

  @Prop()
  width!: string

  @Prop()
  height!: string

  private innerSrc = resolveImageUrl(this.src, this.zip)

  private get styles () {
    return {
      width: this.width,
      height: this.height
    }
  }

  private handleClick () {
    this.$emit('click')
  }

  private handleLoad (e: Event) {
    this.$emit('load', e)
  }
}
