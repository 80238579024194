
import { Component, Vue } from 'vue-property-decorator'
import HeaderContainer from './components/HeaderContainer.vue'
import SiderContainer from './components/SiderContainer.vue'
import BreadcrumbContainer from './components/BreadcrumbContainer.vue'
import { IMenu } from '@/interface/common'
import { getAncestorsAndSelf } from '@/service/ToolsService'
import CacheService from '@/service/CacheService'

@Component({
  components: {
    HeaderContainer,
    SiderContainer,
    BreadcrumbContainer
  }
})
export default class Main extends Vue {
  private fullPath = ''
  private routePaths: IMenu[] = []
  private isShowPage = false
  private isSimple = false

  beforeRouteEnter (to: any, from: object, next: Function) {
    if (!CacheService.user.get('api_token')) {
      next({
        path: '/login'
      })
    } else {
      next((vm: any) => {
        vm.fullPath = to.fullPath
        vm.initRoutePaths(to.path)
        vm.isShowPage = true
        vm.isSimple = !!to.meta && !!to.meta.layoutSimple
      })
    }
  }

  beforeRouteUpdate (to: any, from: any, next: Function) {
    this.fullPath = to.fullPath
    this.isSimple = !!to.meta && !!to.meta.layoutSimple
    this.initRoutePaths(to.path)
    next()
  }

  private handleUpdate () {
    this.initRoutePaths(this.$route.path)
  }

  private initRoutePaths (path: string) {
    const RouteList: IMenu[] = CacheService.layout.get('menus') || []
    if (RouteList.length === 0) {
      console.error('可能没分配栏目权限')
    }
    path = path === '/' ? RouteList[0].route : path
    path = this.routeFilter(path)
    const routeItem = RouteList.find((res) => res.route === path)
    if (routeItem) {
      this.routePaths = getAncestorsAndSelf(routeItem.id, RouteList)
    }
  }

  private routeFilter (path: string) {
    const paths = path.split('/').filter((str) => str && str !== 'form')
    path = paths.join('/')
    return '/' + path
  }
}
