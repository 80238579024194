
import { Component, Vue } from 'vue-property-decorator'
import AdminRoleService from '@/biz/service/User/AdminRoleService'

@Component
export default class ViewUserAdminPositionIndex extends Vue {
  private Service = AdminRoleService

  private handlePermissions (v: { id: number }) {
    return AdminRoleService.dialog(require('@/biz/components/components/Permissions.vue').default, {
      id: v.id,
      Service: AdminRoleService
    })
  }
}
