import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class AdminUserService extends BaseAbstract {
  displayName = '后台用户'
  name = 'User'
  controllerName = 'AdminUser'
  index (params: { page: number; guard_name: string }) {
    return IOService.get('admin_user', params)
  }

  store (params: object) {
    return IOService.post('admin_user', params)
  }

  show (id: number | string) {
    return IOService.get(`admin_user/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`admin_user/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`admin_user/${id}`)
  }
}

export default new AdminUserService()
