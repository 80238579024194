
import { Component, Vue } from 'vue-property-decorator'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'
import MallClassifyService from '@/biz/service/Mall/MallClassifyService'

@Component
export default class MallClassifyForm extends Vue {
  private MallClassifyService = MallClassifyService
  private form = {
    id: RouterService.query('id'),
    display_name: '',
    icon: '',
    cover_img: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '分类名',
      rule: [ValidateService.required, ValidateService.max(10)]
    },
    icon: {
      prop: 'icon',
      label: '图标'
    },
    cover_img: {
      prop: 'cover_img',
      label: '封面图'
    }
  })
}
