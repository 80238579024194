
import RouterService from '@/service/RouterService'
import { Component, Vue } from 'vue-property-decorator'
import EnterpriseService from '@/biz/service/Enterprise/EnterpriseService'

@Component
export default class EnterpriseMain extends Vue {
  private fullPath = ''
  private info = {
    id: 0,
    company_name: ''
  }

  private handleLoad () {
    return EnterpriseService.get()
      .then((data) => {
        Object.assign(this.info, data)
      })
  }

  created () {
    this.fullPath = RouterService.getFullPath()
  }
}
