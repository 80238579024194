
import { Component, Vue, Prop, Ref, Provide } from 'vue-property-decorator'
import TablePagination from '@/components/Table/TablePagination.vue'
import { IPagination, ISearchFields } from '@/interface/common'
import RouterService from '@/service/RouterService'
import BaseAbstract from '@/abstract/BaseAbstract'
import QueryString from 'qs'
import { PromiseResult } from '@/service/IOService'
import SearchTool from './SearchTool/Search.vue'

interface Service extends BaseAbstract {
  index: Function;
  refresh: Function;
  limit: number;
}

@Component({
  components: {
    TablePagination,
    SearchTool
  }
})
export default class ListContainer extends Vue {
  @Ref()
  drElement!: any

  @Prop()
  onLoad!: Function

  @Prop()
  Service!: Service

  @Prop()
  searchFields!: ISearchFields[]

  @Provide()
  listService = this.Service

  private pagination: IPagination = {
    page: Number(RouterService.query('page')) || 1,
    limit: Number(RouterService.query('limit')) || (this.Service && this.Service.limit) || 10,
    total: 0
  }

  private searchParams = { _search: Object.values(QueryString.parse(RouterService.query('_search') as string)) }
  private list: any[] = []
  private isShowPagination = false
  private RouterService = RouterService

  private pageLoad () {
    RouterService.replace(RouterService.getPath(), { page: this.pagination.page, limit: this.pagination.limit })
  }

  private handleLoad () {
    return Promise.resolve()
      .then(() => {
        if (this.onLoad) {
          return this.onLoad({
            ...this.pagination,
            ...this.searchParams
          })
        } else {
          return this.Service.index({
            ...this.pagination,
            ...this.searchParams
          })
        }
      })
      .then((res: PromiseResult) => {
        if (Array.isArray(res.data)) {
          this.list = res.data
        } else if (res.data && res.data.data) {
          this.isShowPagination = true
          this.pagination.total = res.data.total
          this.list = res.data.data
        }
      })
  }

  private removeReload () {
    if (this.list.length === 1) {
      const page = this.pagination.page > 1 ? --this.pagination.page : 1
      if (this.pagination.page === page) {
        this.reload()
      } else {
        this.pagination.page = page
        RouterService.replace(RouterService.getPath(), { page: this.pagination.page })
      }
    } else {
      this.reload()
    }
  }

  reload () {
    this.list = []
    this.drElement.reload()
  }

  created () {
    if (this.Service) {
      this.listService.refresh = this.removeReload
    }
  }
}
