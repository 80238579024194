
import { IFormFieldItem } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import { Component, Mixins } from 'vue-property-decorator'
import SearchMixins from './SearchMixins'

@Component
export default class SearchDateValue extends Mixins(SearchMixins) {
  private field: IFormFieldItem = ValidateService.genRule({
    prop: 'value',
    label: '值',
    rule: [ValidateService.required({ trigger: 'change', type: 'array' })]
  })

  private handleChangeDateRange (date: string[]) {
    if (date) {
      this.innerValue = [date[0] + ' 00:00:00', date[1] + ' 23:59:59']
    } else {
      this.innerValue = ''
    }
  }

  private handleChangeDate (date: string) {
    if (this.operator === '>') {
      this.innerValue = date + ' 23:59:59'
    } else if (this.operator === '<') {
      this.innerValue = date + ' 00:00:00'
    }
  }
}
