
import { Component, Vue } from 'vue-property-decorator'
import Ab from './components/Form/Ab.vue'
import Many from './components/Form/Many.vue'
import Rally from './components/Form/Rally.vue'
import T3 from './components/Form/T3.vue'
import RacingService from '@/biz/service/Racing/RacingService'
import ConstService from '@/service/ConstService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'

@Component({
  components: {
    Ab,
    Many,
    Rally,
    T3
  }
})
export default class ViewRacingRoundForm extends Vue {
  private current = ''
  private Service = RacingRoundService
  private form = {
    id: RouterService.query('id'),
    display_name: '',
    volley_number: 0,
    promoted_number: 0,
    racing_track: 1,
    start_rule: 0,
    max_time: 0,
    is_write_result: 1
  }

  private formFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '名称',
      rule: [ValidateService.required, ValidateService.max(30)],
      width: 300
    },
    max_time: {
      prop: 'max_time',
      label: '最大给时',
      rule: [ValidateService.requiredNumber, ValidateService.minNum(1)]
    },
    promoted_number: {
      prop: 'promoted_number',
      label: '晋级数',
      rule: [ValidateService.requiredNumber, ValidateService.minNum(1)]
    },
    is_write_result: {
      prop: 'is_write_result',
      label: '计入总成绩'
    },
    volley_number: {
      prop: 'volley_number',
      label: '齐发数',
      rule: [ValidateService.requiredNumber, ValidateService.minNum(1)]
    },
    racing_track: {
      prop: 'racing_track',
      label: '赛道',
      rule: [ValidateService.requiredNumber]
    },
    start_rule: {
      prop: 'start_rule',
      label: '发车规则',
      rule: [ValidateService.requiredNumber]
    }
  })

  created () {
    this.current = ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)
  }
}
