
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'

@Component
export default class ServiceDialogPrompt extends Vue {
  @Ref()
  inputElement!: any

  @Prop()
  params!: { title?: string }

  private isShowModal = false
  private resolve!: Function
  private reject!: Function
  private input = ''

  open () {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.isShowModal = true
      this.$nextTick(() => {
        this.inputElement.focus()
        document.onkeydown = (e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            this.handleSubmit()
          }
        }
      })
    })
  }

  hide () {
    document.onkeydown = null
    this.isShowModal = false
    setTimeout(() => {
      this.$destroy()
    }, 300)
  }

  close () {
    this.hide()
    this.reject('关闭弹窗')
  }

  private handleSubmit () {
    this.hide()
    this.resolve(this.input)
  }
}
