
import { Component, Vue, Prop, Provide, Ref } from 'vue-property-decorator'
import { IPagination, ISearchFields } from '@/interface/common'
import TablePagination from '@/components/Table/TablePagination.vue'
import RouterService from '@/service/RouterService'
import QueryString from 'qs'
import SearchTool from '../SearchTool/Search.vue'
import BaseAbstract from '@/abstract/BaseAbstract'
import { PromiseResult } from '@/service/IOService'

interface Service extends BaseAbstract {
  index: Function;
  refresh: Function;
  limit: number;
}

@Component({
  components: {
    TablePagination,
    SearchTool
  }
})
export default class TableRender extends Vue {
  @Ref()
  table!: any

  @Ref()
  drElement!: any

  @Prop()
  onLoad!: Function

  @Prop()
  onBeforeLoad!: Function

  @Prop()
  searchFields!: ISearchFields[]

  @Prop()
  Service!: Service

  @Prop()
  spanMethod!: Function

  @Prop()
  maxHeight!: number

  @Prop()
  align!: string

  @Prop({ default: false })
  print!: boolean

  @Prop()
  defaultExpandAll!: boolean

  @Provide()
  tableService = this.Service

  @Provide()
  tableRender = {
    align: this.align
  }

  private innerMaxHeight = 0
  private isLoading = false
  private pagination: IPagination = {
    page: Number(RouterService.query('page')) || 1,
    limit: Number(RouterService.query('limit')) || (this.Service && this.Service.limit) || 10,
    total: 0
  }

  private isShowPagination = false
  private list: any[] = []
  private emptyText = '暂无数据'
  private searchParams = { _search: Object.values(QueryString.parse(RouterService.query('_search') as string)) }
  private RouterService = RouterService

  private initLoad () {
    return Promise.resolve()
      .then(() => {
        if (!this.isLoading) {
          this.isLoading = true
          return Promise.resolve()
            .then(() => {
              if (this.onBeforeLoad) {
                return this.onBeforeLoad()
              }
            })
            .then(() => {
              if (this.onLoad) {
                return this.onLoad({
                  ...this.pagination,
                  ...this.searchParams
                })
              } else {
                return this.Service.index({
                  ...this.pagination,
                  ...this.searchParams
                })
              }
            })
            .then((res: PromiseResult) => {
              if (Array.isArray(res)) {
                this.list = res
                this.pagination.total = this.list.length
              } else if (res && Array.isArray(res.data)) {
                this.list = res.data
                this.pagination.total = this.list.length
              } else if (res.data && res.data.data) {
                this.isShowPagination = true
                this.pagination.total = res.data.total
                this.list = res.data.data
              }
              this.isLoading = false
              return res
            })
            .catch((err: any) => {
              this.isLoading = false
              if (err && err.message) {
                this.emptyText = err.message
              }
              return Promise.reject(err)
            })
        }
      })
  }

  private pageLoad () {
    RouterService.replace(RouterService.getPath(), { ...this.$route.query, page: this.pagination.page, limit: this.pagination.limit })
  }

  private removeReload () {
    if (this.list.length === 1) {
      const page = this.pagination.page > 1 ? --this.pagination.page : 1
      if (this.pagination.page === page) {
        this.reload()
      } else {
        this.pagination.page = page
        RouterService.replace(RouterService.getPath(), { page: this.pagination.page })
      }
    } else {
      this.reload()
    }
  }

  reload () {
    this.list = []
    return this.initLoad()
  }

  private handleLoad () {
    return Promise.resolve()
      .then(() => {
        if (this.drElement) {
          this.innerMaxHeight = this.maxHeight ? this.maxHeight : (this.print ? 3000 : document.body.clientHeight - (this.drElement.$el.offsetTop + 180))
        }
        if (this.Service) {
          this.tableService.refresh = this.removeReload
        }
        return this.initLoad()
      })
  }
}
