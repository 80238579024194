
import { Component, Vue } from 'vue-property-decorator'
import UserControlService from '@/biz/service/User/UserControlService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'

@Component
export default class ViewUserMemberUserControlForm extends Vue {
  private Service = UserControlService
  private form = {
    id: RouterService.query('id'),
    is_disable_all_push: 0
  }

  private formFields: IFormFields = ValidateService.genRules({
    is_disable_all_push: {
      prop: 'is_disable_all_push',
      label: '关闭所有推送'
    }
  })
}
