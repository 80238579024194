
import { Component, Vue } from 'vue-property-decorator'
import MallClassifySpecService from '@/biz/service/Mall/MallClassifySpecService'
import RouterService from '@/service/RouterService'

@Component
export default class MallClassifySpecIndex extends Vue {
  private MallClassifySpecService = MallClassifySpecService

  private handleOption (row: { id: number }) {
    RouterService.push('/mall/mall-classify/mall-classify-spec/mall-classify-spec-option', { ...this.$route.query, mall_classify_spec_id: row.id })
  }
}
