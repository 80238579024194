import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'
import RouterService from '@/service/RouterService'

class NoticeService extends BaseAbstract {
  name = 'Notice'
  index (params: { page: number }) {
    return IOService.get('notice', params)
  }

  show (id: number | string) {
    return IOService.get(`notice/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`notice/${params.id}`, params)
  }

  store (params: object) {
    return IOService.post('notice', {
      ...params,
      marking: RouterService.query('marking')
    })
  }

  destroy (id: number | string) {
    return IOService.delete(`notice/${id}`)
  }
}

export default new NoticeService()
