
import { Component, Ref, Vue } from 'vue-property-decorator'
import RacingDynamicService from '@/biz/service/Racing/RacingDynamicService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'

@Component
export default class RacingDynamicVideoForm extends Vue {
  @Ref()
  racingRoundElement!: any

  private RacingDynamicService = RacingDynamicService
  private form = {
    id: RouterService.query('id'),
    description: '',
    images: [],
    video: '',
    sort: '',
    racing_group_id: '',
    racing_round_id: '',
    group_sort: '',
    cover_img: '',
    dynamic_type: 3
  }

  private formFields = ValidateService.genRules({
    description: {
      prop: 'description',
      label: '内容',
      rule: [ValidateService.max(255)]
    },
    images: {
      prop: 'images',
      label: '图集',
      rule: [ValidateService.maxItem(9)]
    },
    video: {
      prop: 'video',
      label: '视频',
      rule: [ValidateService.required]
    },
    sort: {
      prop: 'sort',
      label: '推荐'
    },
    racing_group_id: {
      prop: 'racing_group_id',
      label: '组别',
      options: [],
      rule: [ValidateService.requiredNumber],
      props: {
        label: 'display_name',
        value: 'id'
      }
    },
    racing_round_id: {
      prop: 'racing_round_id',
      label: '回合',
      options: [],
      rule: [ValidateService.requiredNumber],
      props: {
        label: 'display_name',
        value: 'id'
      }
    },
    group_sort: {
      prop: 'group_sort',
      label: '发车顺序',
      options: [],
      rule: [ValidateService.requiredNumber]
    },
    cover_img: {
      prop: 'cover_img',
      label: '视频封面图'
    }
  })

  private handleBeforeLoad () {
    return RacingGroupService.getList()
      .then((data) => {
        this.formFields.racing_group_id.options = data
      })
  }

  private handleAfterLoad () {
    return RacingRoundService.getList(Number(this.form.racing_group_id))
      .then((data) => {
        this.formFields.racing_round_id.options = data
      })
  }

  private handleRacingGroup (value: number) {
    return RacingRoundService.getList(value as number)
      .then((data) => {
        this.formFields.racing_round_id.options = data
        this.form.racing_round_id = ''
        this.racingRoundElement.reload()
      })
  }
}
