
import { Component, Vue } from 'vue-property-decorator'
import MallBrandService from '@/biz/service/Mall/MallBrandService'
import { IFormFields } from '@/interface/common'
import ValidateService from '@/service/ValidateService'
import RouterService from '@/service/RouterService'
import MallClassifyService from '@/biz/service/Mall/MallClassifyService'
import EnterpriseService from '@/biz/service/Enterprise/EnterpriseService'

@Component
export default class MallBrandForm extends Vue {
  private MallBrandService = MallBrandService
  private form = {
    id: RouterService.query('id'),
    mall_classify_id: '',
    display_name: '',
    logo: '',
    cover_img: '',
    description: '',
    sort: ''
  }

  private formFields: IFormFields = ValidateService.genRules({
    mall_classify_id: {
      prop: 'mall_classify_id',
      label: '分类',
      rule: [ValidateService.requiredNumber],
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      }
    },
    enterprise_id: {
      prop: 'enterprise_id',
      label: '所属企业',
      options: [],
      props: {
        value: 'id',
        label: 'display_name'
      },
      tips: '为空则应用于所有企业'
    },
    display_name: {
      prop: 'display_name',
      label: '品牌名',
      rule: [ValidateService.required, ValidateService.max(10)]
    },
    logo: {
      prop: 'logo',
      label: 'LOGO'
    },
    cover_img: {
      prop: 'cover_img',
      label: '封面图'
    },
    description: {
      prop: 'description',
      label: '品牌描述',
      rule: [ValidateService.max(255)]
    },
    sort: {
      prop: 'sort',
      label: '排序'
    }
  })

  private handleBeforeLoad () {
    return Promise.all([MallClassifyService.getList(), EnterpriseService.getList()])
      .then((res) => {
        this.formFields.mall_classify_id.options = res[0]
        this.formFields.enterprise_id.options = res[1]
      })
  }
}
