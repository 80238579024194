
import FormMixins from './FormMixins'
import { Component, Mixins, Inject, Prop, Ref } from 'vue-property-decorator'
import ImageMaskContainer from '@/components/ImageMaskContainer.vue'
import BaseAbstract from '@/abstract/BaseAbstract'
import ImageManage from '@/service/ImageManageService/ImageManage.vue'

@Component({
  components: {
    ImageMaskContainer,
    ImageManage
  }
})
export default class FormImage extends Mixins(FormMixins) {
  @Ref()
  ImageManageElement!: any

  @Ref()
  formItemElement!: any

  @Inject('formService')
  formService!: BaseAbstract

  @Prop()
  fieldService!: BaseAbstract

  private handleChangeModal () {
    return this.ImageManageElement.open()
      .then((data: { full_url: string }) => {
        this.innerValue = data.full_url
        this.formItemElement.clearValidate()
      })
      .catch((err: object) => console.log(err))
  }

  private handleDestroy (urls: string[]) {
    if (urls.includes(this.innerValue)) {
      this.innerValue = ''
    }
  }
}
