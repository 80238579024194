import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class AdminLogService extends BaseAbstract {
  name = 'AdminLog'
  index (params: { page: number }) {
    return IOService.get('admin_log', params)
  }
}

export default new AdminLogService()
