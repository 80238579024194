import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class AdminRoleService extends BaseAbstract {
  displayName = '后台角色'
  name = 'AdminRole'
  index () {
    return IOService.get('admin_role')
  }

  show (id: number | string) {
    return IOService.get(`admin_role/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`admin_role/${params.id}`, params)
  }

  updatePermissions (admin_role_id: number, menus: number[], permissions: number[]) {
    return IOService.post('admin_role/updatePermissions', { admin_role_id, menus, permissions })
  }

  getPermissions (admin_role_id: number) {
    return IOService.get('admin_role/getPermissions', { admin_role_id })
  }
}

export default new AdminRoleService()
