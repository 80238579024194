
import { Component, Vue } from 'vue-property-decorator'
import UserRoleService from '@/biz/service/User/UserRoleService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'

@Component
export default class ViewUserMemberRoleForm extends Vue {
  private Service = UserRoleService
  private form = {
    id: RouterService.query('id'),
    display_name: ''
  }

  private formFields = ValidateService.genRules({
    display_name: {
      prop: 'display_name',
      label: '名称',
      rule: [ValidateService.required, ValidateService.max(30)]
    }
  })
}
