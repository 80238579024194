
import { Component, Vue } from 'vue-property-decorator'
import RacingService from '@/biz/service/Racing/RacingService'
import RouterService from '@/service/RouterService'
import ValidateService from '@/service/ValidateService'
import FormEditor from '@/components/Form/FormEditor.vue'

@Component({
  components: {
    FormEditor
  }
})
export default class RacingForm extends Vue {
  private Service = RacingService
  private form = {
    id: RouterService.query('racing_id'),
    racing_type: 0,
    title: '',
    cover_img: '',
    city: 0,
    start_time: '',
    end_time: '',
    apply_end_time: '',
    contacts: '',
    phone: '',
    wechat: '',
    address: '',
    description: '',
    coin_sponsor: ''
  }

  private formFields = ValidateService.genRules({
    racing_type: {
      prop: 'racing_type',
      label: '赛事类型',
      rule: [ValidateService.requiredNumber]
    },
    title: {
      prop: 'title',
      label: '赛事标题',
      rule: [ValidateService.required, ValidateService.max(120)]
    },
    cover_img: {
      prop: 'cover_img',
      label: '赛事封面',
      rule: [ValidateService.required]
    },
    city: {
      prop: 'city',
      label: '举办城市',
      rule: [ValidateService.required]
    },
    racing_time: {
      prop: 'racing_time',
      label: '比赛时间',
      rule: [ValidateService.required({ trigger: 'change' })]
    },
    apply_end_time: {
      prop: 'apply_end_time',
      label: '报名截止时间',
      rule: [ValidateService.required({ trigger: 'change' })]
    },
    contacts: {
      prop: 'contacts',
      label: '联系人',
      rule: [ValidateService.required, ValidateService.max(10)]
    },
    phone: {
      prop: 'phone',
      label: '手机号',
      rule: [ValidateService.required, ValidateService.mobile]
    },
    wechat: {
      prop: 'wechat',
      label: '微信号',
      rule: [ValidateService.required, ValidateService.max(20)]
    },
    coin_sponsor: {
      prop: 'coin_sponsor',
      label: '赛币冠名商',
      rule: [ValidateService.max(60)]
    },
    address: {
      prop: 'address',
      label: '报名/集合地址',
      rule: [ValidateService.required, ValidateService.max(30)]
    },
    description: {
      prop: 'description',
      label: '赛事详情',
      rule: [ValidateService.required]
    }
  })

  created () {
    if (!RouterService.query('id') && this.form.id) {
      RouterService.replace('', { id: this.form.id, racing_id: this.form.id })
    }
    if (process.env.NODE_ENV === 'development') {
      this.form.racing_type = 1
      this.form.title = '赛事标题'
      this.form.cover_img = ''
      this.form.city = 440103
      this.form.start_time = '2021-04-01'
      this.form.end_time = '2021-04-30'
      this.form.apply_end_time = '2021-03-29'
      this.form.contacts = '万鑫'
      this.form.phone = '13724352543'
      this.form.wechat = 'wanxin'
      this.form.address = '报名地址'
      this.form.description = '赛事内容'
    }
  }
}
