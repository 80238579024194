
import { Component, Vue } from 'vue-property-decorator'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingService from '@/biz/service/Racing/RacingService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'
import RacingApplyService from '@/biz/service/Racing/RacingApplyService'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintGeneralApplyList extends Vue {
  private list = [] as any[]
  private RacingService = RacingService
  private racingGroupList = [] as any[]
  private racingGroupProps = {
    label: 'display_name',
    value: 'id'
  }

  private handleLoad () {
    return RacingGroupService.getList()
      .then((racingGroupList: any[]) => {
        this.racingGroupList = racingGroupList
        return RacingApplyService.printIndex()
          .then((res) => {
            this.list = res.data
          })
      })
  }

  private handleTableLoad (index: number) {
    return Promise.resolve([this.list[index]])
  }
}
