import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class Service extends BaseAbstract {
  displayName = '商品'
  name = 'MallProduct'
  index (params: { page: number }) {
    return IOService.get('mall_product', params)
  }

  store (params: object) {
    return IOService.post('mall_product', params)
  }

  show (id: number) {
    return IOService.get(`mall_product/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`mall_product/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`mall_product/${id}`)
  }

  getSku (params: { mall_product_id: number }) {
    return IOService.get('mall_product/getSku', params)
  }

  getSpec (params: { mall_classify_id: number; enterprise_id: number; mall_product_id: number }) {
    return IOService.get('mall_product/getSpec', params)
  }

  storeSku (params: { mall_product_id: number; mall_classify_id: number; skus: any[] }) {
    return IOService.post('mall_product/storeSku', params)
  }

  destroySku (id: number) {
    return IOService.post('mall_product/destroySku', { id })
  }
}

export default new Service()
