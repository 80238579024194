
import ConstService from '@/service/ConstService'
import { Component, Vue } from 'vue-property-decorator'
import AbResult from './components/AbResult.vue'
import ManyResult from './components/ManyResult.vue'
import RallyResult from './components/RallyResult.vue'
import T3Result from './components/T3Result.vue'
import RacingService from '@/biz/service/Racing/RacingService'

@Component({
  components: {
    AbResult,
    ManyResult,
    RallyResult,
    T3Result
  }
})
export default class ViewRacingPrintResult extends Vue {
  private current = ''

  created () {
    this.current = `${ConstService.getToUpperCaseRacingTypeName(RacingService.currentData.racing_type)}Result`
  }
}
