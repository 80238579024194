
import FormMixins from './FormMixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class FormSwitch extends Mixins(FormMixins) {
  @Prop({ default: '开启' })
  activeText!: string

  @Prop({ default: '关闭' })
  inactiveText!: string
}
