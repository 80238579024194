import ParamConfig from '@/biz/admin/views/system/param-config/index.vue'
import ParamConfigForm from '@/biz/admin/views/system/param-config/form.vue'

import OptionConfig from '@/biz/admin/views/system/option-config/index.vue'
import OptionConfigForm from '@/biz/admin/views/system/option-config/form.vue'

import OptionConfigOptions from '@/biz/admin/views/system/option-config/options/index.vue'
import OptionConfigOptionsForm from '@/biz/admin/views/system/option-config/options/form.vue'

import AdminLog from '@/biz/admin/views/system/admin-log/index.vue'

import Version from '@/biz/admin/views/system/version/index.vue'
import VersionForm from '@/biz/admin/views/system/version/form.vue'

import Notice from '@/biz/admin/views/system/notice/index.vue'
import NoticeForm from '@/biz/admin/views/system/notice/form.vue'

import Ad from '@/biz/admin/views/system/ad/index.vue'
import AdForm from '@/biz/admin/views/system/ad/form.vue'

export default [
  {
    path: '/system/notice',
    component: Notice
  },
  {
    path: '/system/notice/form',
    component: NoticeForm
  },
  {
    path: '/system/param-config',
    component: ParamConfig
  },
  {
    path: '/system/param-config/form',
    component: ParamConfigForm
  },
  {
    path: '/system/option-config',
    component: OptionConfig
  },
  {
    path: '/system/option-config/form',
    component: OptionConfigForm
  },
  {
    path: '/system/option-config/options',
    component: OptionConfigOptions
  },
  {
    path: '/system/option-config/options/form',
    component: OptionConfigOptionsForm
  },
  {
    path: '/system/admin-log',
    component: AdminLog
  },
  {
    path: '/system/version',
    component: Version
  },
  {
    path: '/system/version/form',
    component: VersionForm
  },
  {
    path: '/system/ad',
    component: Ad
  },
  {
    path: '/system/ad/form',
    component: AdForm
  }
]
