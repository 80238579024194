
import DataRender from './DataRender.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    DataRender
  }
})
export default class PageContainer extends Vue {
  @Prop({ default: () => () => Promise.resolve() })
  readonly onLoad!: Function

  private handleSuccess () {
    this.$emit('success')
  }
}
