
import { Component, Vue } from 'vue-property-decorator'
import RacingResultService from '@/biz/service/Racing/RacingResultService'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RacingRoundService from '@/biz/service/Racing/RacingRoundService'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import print from 'vue-print-nb'

@Component({
  directives: {
    print
  }
})
export default class ViewRacingPrintManyStart extends Vue {
  private RacingGroupService = RacingGroupService
  private RacingRoundService = RacingRoundService
  private list = [] as any[][]

  private handleLoad () {
    return RacingResultService.manyIndex()
      .then((res) => {
        let pageNumber = 1
        let pageGroupNumber = 1
        const totalGroupNumber = Math.ceil(res.data.length / RacingRoundService.currentData.volley_number)
        if (res.data.length > 22) {
          pageGroupNumber = Math.floor(22 / RacingRoundService.currentData.volley_number)
          pageNumber = Math.ceil(totalGroupNumber / pageGroupNumber)
        } else {
          pageGroupNumber = totalGroupNumber
        }
        for (let i = 1; i <= pageNumber; i++) {
          const minGroupNumber = (i - 1) * pageGroupNumber
          const maxGroupNumber = i * pageGroupNumber
          const list = res.data.filter((item: any) => item.group_sort > minGroupNumber && item.group_sort <= maxGroupNumber)
          this.list.push(list)
        }
      })
  }

  private handleTableLoad (index: number) {
    return Promise.resolve(this.list[index])
  }

  private handleSpanMethod ({ row, columnIndex } = {} as { row: any; column: any; rowIndex: number; columnIndex: number }) {
    if (columnIndex === 0) {
      if (row.merge > 0) {
        return {
          rowspan: row.merge,
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
