
import { Component, Vue } from 'vue-property-decorator'
import RacingGroupService from '@/biz/service/Racing/RacingGroupService'
import RouterService from '@/service/RouterService'

@Component
export default class RacingGroupMain extends Vue {
  private racingGroupList: any[] = []
  private active = RouterService.query('racing_group_id')
  private fullPath = ''
  private RouterService = RouterService

  private handleLoad () {
    return RacingGroupService.getList()
      .then((data) => {
        this.racingGroupList = data || []
        return RacingGroupService.get()
      })
  }

  private handleTabClick (res: any) {
    const racing_group_id = this.racingGroupList[res.index].id
    if (racing_group_id !== Number(RouterService.query('racing_group_id'))) {
      RouterService.replace('', { racing_group_id, racing_round_id: '' })
    }
  }

  private handleAddGroup () {
    RouterService.push('/racing/racing-group-manage/form', {
      ...this.$route.query
    })
  }

  created () {
    this.fullPath = RouterService.getFullPath()
    this.active = RouterService.query('racing_group_id')
  }
}
