import BaseAbstract from '@/abstract/BaseAbstract'
import IOService from '@/service/IOService'

class RacingDynamicCheckService extends BaseAbstract {
  displayName = '动态审核'
  name = 'InfoCheck'
  controllerName = 'RacingDynamicCheck'

  index (params: { page: number }) {
    return IOService.get('racing_dynamic_check', params)
  }

  show (id: number) {
    return IOService.get(`racing_dynamic_check/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`racing_dynamic_check/${params.id}`, params)
  }
}

export default new RacingDynamicCheckService()
