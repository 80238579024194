
import { Component, Vue, Prop } from 'vue-property-decorator'
import NotifyService from '@/biz/service/Notify/NotifyService'
import { IFormFields } from '@/interface/common'
import ConstService from '@/service/ConstService'

@Component
export default class ViewUserMemberNotifyShow extends Vue {
  @Prop()
  params!: { id: string }

  private Service = NotifyService
  private info = {
    id: this.params.id,
    title: '',
    user_id: '',
    push_platform: '',
    url: '',
    url_params: {} as { [key: string]: string },
    url_platform: '',
    content: '',
    remark: '',
    keywords: {} as { [key: string]: string },
    keyword_names: {} as { [key: string]: string },
    is_read: 0,
    is_push_wechat: 1,
    is_push_message: 1
  }

  private formFields: IFormFields = {
    title: {
      prop: 'title',
      label: '标题',
      disabled: true
    },
    content: {
      prop: 'content',
      label: '通知内容',
      disabled: true
    },
    remark: {
      prop: 'remark',
      label: '通知备注',
      disabled: true
    },
    push_platform: {
      prop: 'push_platform',
      label: '推送平台',
      disabled: true
    },
    url: {
      prop: 'url',
      label: '链接地址',
      disabled: true
    },
    url_params: {
      prop: 'url_params',
      label: '链接参数',
      disabled: true
    },
    url_platform: {
      prop: 'url_platform',
      label: '跳转平台',
      disabled: true
    },
    keywords: {
      prop: 'keywords',
      label: '参数值',
      disabled: true
    },
    keyword_names: {
      prop: 'keyword_names',
      label: '参数名称',
      disabled: true
    },
    is_read: {
      prop: 'is_read',
      label: '是否查看',
      options: ConstService.getBoolOptions(),
      disabled: true
    },
    is_push_wechat: {
      prop: 'is_push_wechat',
      label: '推送公众号',
      options: ConstService.getBoolOptions(),
      disabled: true
    },
    is_push_message: {
      prop: 'is_push_message',
      label: '推送站内信',
      options: ConstService.getBoolOptions(),
      disabled: true
    }
  }
}
